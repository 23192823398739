import { useState } from 'react'
// thanks to SENG-LIVE-042522-PHASE-2 - 9 - useForm.js

//import { useState, useCallback } from 'react'
//import debounce from "lodash/debounce";

// use other hooks inside of this custom hook such as useState, useEffect
// the function needs to return an object: this object is going to contain is key value pairs.
// the keys are arbitrary, the values are the methods that have been defined inside of this hook.
// Typically, lets keep the key names the same as the method names
export const useForm = (initialState, callback) => {

  const [formData, setFormData] = useState(initialState);

  const handleSubmitt = (e) => {  // for now, handleSubmitt is not a typo. Prevents mixing.
    if (e) e.preventDefault();
    callback();
  };

  // TODO: consider delaying processing of state keystrokes
  // per https://www.upbeatcode.com/react/how-to-use-lodash-in-react/
  // Delay search by 600ms
  // const delayedSearch = useCallback(
  //   debounce((q) => sendQuery(q), 600),
  //   []
  // );

  const handleChange = (e) => {
    //console.log("e_target", e.target)
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleChangeWithWhitespace = (e) => {
    //console.log("wse_target", e.target)
    const { name, value } = e.target;
    handleChange({
      target: {
        name: name,
        value: value.replace(/\s+/g, ' ')
      }
    });
  };

  const reset = () => {
      setFormData(initialState)
  }

  const editFormValues = (formData) => {
      setFormData(formData)
  }

  return {
      // key: value
      // key= we can name anything we want
      // value= this is the method we are making accesible from inside this custom hook
      // (Huh?)
      formData, 
      handleChangeWithWhitespace,
      handleSubmitt, 
      handleChange, 
      reset, 
      editFormValues // editFormValues: editFormValues
  }

};
