import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ShopperContext } from '../context/shopper';
import { useUtils } from "../hooks/useUtils";
import { useStyles } from "../hooks/useStyles";
import { HeadingBuilder } from "./HeadingBuilder";
import 'tippy.js/dist/tippy.css';
import { useTranslation } from "react-i18next";

export const TripLink = ( {
    trip,
    if_shopper_is_circle_admin,
    showCircleZipCodes,
    showRemoveTripColumn,
  } ) => {

  const { circles } = useContext(ShopperContext);
  console.log('triplink circles', circles)

  const { toMmmDdYyyy, toHmmA } = useUtils();
  const { t } = useTranslation();
  const { textAlignLeft } = useStyles();

  if (trip) {
  console.log('triplink tripX', trip)
  } else {
    return 
  }

  console.log('triplink trip.circle.id', trip.circle.id)
  const circle = circles.find(c => c.id == trip.circle.id);
  console.log('triplink circle A', circle)

  // Circle, Trip Date, Link to Shopping List, Trip Hidden?, Remove Trip?
  return (
    <tr style={textAlignLeft}>
      <td>
        <Link to={`/circles/${circle.id}`}>
          {circle.identifier}
        </Link>
      </td>
      { showCircleZipCodes
        ? <td>
            {circle.postal_code}
          </td>
        : null
      }
      <td>
        <HeadingBuilder
          containerType  = 'container-hug-bottom-left'
          headingText    = { toMmmDdYyyy({dateString: trip.day, weekday: 'short'}) }
          tippyAlertText = { toHmmA({timeString: trip.hour}) }
          tippyText      = { toHmmA({timeString: trip.hour}) }
        />
      </td>
      <td><Link to={`/trips/${trip.id}`}>{ trip.label }</Link></td>
      <td>
        {trip.is_hidden ? 'Hidden' : ''}
        {/* The trip filter should prevent non-admins from seeing hidden trips. */}
      </td>
      <td>
        { showRemoveTripColumn && if_shopper_is_circle_admin
          ? <HeadingBuilder
              headingText    = {t('global.label.remove')}
              // Remove
              linkUrl        = {`/trips/${trip.id}/delete`}
              tippyAlertText = {t('trip.removeTrip.tippyContentButton')}
              tippyText      = {t('trip.removeTrip.tippyContentButton')}
              // Want to remove Trip from the Circle? Click here.
            />
          : null
        }
      </td>
    </tr>
  )
}

export default TripLink