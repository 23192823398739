// Don't apply 'HeadingBuilder' for this component's 'Tippy contents' (table data)
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
//import { useContext } from 'react';
//import { ShopperContext } from '../context/shopper';
import { useStyles } from "../hooks/useStyles";
import { useLookups } from "../hooks/useSkuUtils";
import CircleTable from '../components/CircleTable';
import { useTranslation } from "react-i18next";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

export const InvitationLink = ( {invitation} ) => {
  const { t } = useTranslation();
  const { tableOrderItem } = useStyles();

  const [showCircleTable, setShowCircleTable] = useState(false);
  const handleClick = event => {
    setShowCircleTable(current => !current);
  };

  const { lookup_flavor_label } = useLookups();

  if (invitation) {
    console.log('Invitationlink InvitationX', invitation)
  } else {
    return 
  }

  const invitationStartsAsAccepted = 
    // invitation.status === 'Accepted'
    invitation.status === t('invitation.status.accepted');

  const invitationStartingTippy = 
    invitationStartsAsAccepted
      ? t('invitation.status.acceptedDontClick')
      : t('invitation.status.clickToRespond')

  const icircle = {...invitation.circle};
  return (
        <tr style={tableOrderItem}>
          <td>
            { showCircleTable
              ? <CircleTable
                  circle = {icircle}
                  shopperNames = {invitation.shopper_names}
                  handleClick = {handleClick}
                  showDetails = {false}
                />
              : <button onClick={handleClick}>{invitation.circle.identifier}</button>
            }
          </td>
          <td>
            {icircle.postal_code}
          </td>
          <td>
            { lookup_flavor_label({flavor: invitation.flavor}) }
          </td>
          <Tippy content= {invitationStartingTippy} >
            <td>
              { invitationStartsAsAccepted
                ? invitation.status
                : <Link to={`/circles/${icircle.id}/invitations/${invitation.id}`}>
                    {invitation.status}
                   {/* {t('invitation.status.edit_label')} */}
                  </Link>
              }
            </td>
          </Tippy>
        </tr>
  )
}

export default InvitationLink