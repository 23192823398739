import React from 'react';
import { useTranslation } from "react-i18next";
import HeadingBuilder from './HeadingBuilder';

export const ClipboardButton = ({
  textToCopy, buttonType
}) => {

  const { t } = useTranslation();

  return (
    // Caller designates Tippy text to show via 'buttonType'
    <HeadingBuilder
      buttonAction   = {() =>  navigator.clipboard.writeText(
        textToCopy
      )}
      headingText    = {t(`clipboard.${buttonType}.label`)}
      // Copy to Clipboard

      tippyAlertText = {t(`clipboard.${buttonType}.tippyContent`)}
      tippyText      = {t(`clipboard.${buttonType}.tippyContent`)}
    />
  )
}

export default ClipboardButton;