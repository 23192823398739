import React, { useContext } from 'react';
import { NumericFormat } from 'react-number-format';
import { ShopperContext } from '../context/shopper';
import HeadingBuilder from '../components/HeadingBuilder';
import { ErrorList } from './ErrorList';
import { useForm } from "../hooks/useForm";
import { useStyles } from "../hooks/useStyles";
import { useLookups, usePhrasing } from "../hooks/useSkuUtils";
import { useParams } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { BackButton } from './BackButton';
import { useTranslation } from "react-i18next";
//import styled from 'styled-components';
import styles from '../css/trTdFirstChild.module.css';

export const CircleEditForm = () => {
    const { signedIn, shopper, circles, editCircle } = useContext(ShopperContext);
    const { errorsDoExist, dismissErrorsList, setErrorsDoExistToFalse } = useContext(ShopperContext);
    const { setJustGotClearToFalse, stripeIsActive } = useContext(ShopperContext);
    console.log('cef circles: ', circles)

    const { tableShow, tableData } = useStyles();
    const { lookup_tz_info } = useLookups();

    const {id} = useParams();
    console.log('cef id: ', id)

    const { t } = useTranslation();

    const { circleTaxTippyText, circleFoodTaxTippyText } = usePhrasing();
    const circleTaxText     = circleTaxTippyText();
    const circleFoodTaxText = circleFoodTaxTippyText();

    const circleObj = circles.find(c => c.id == id);
    console.log('cef circleObj: ', circleObj)

    if (circleObj == null) {
      console.log('cef circleObj2: ', circleObj)
      // User likely used browser to refresh the page, lost access to state.
      // This causes 'circleObj' to be 'undefined'.
      // Let's go back to the 'circles' page.
      window.location.replace('/circles/');
      // not sure why, but code below continues executing.
      // I'm thinking it would be better to suspend such processing but I
      // don't know how. Using 'navigate' here to go to /circles doesn't work.
      // As such, lack of 'circleObj' causes errors to arise below, but
      // at some point the effect of 'replace' kicks in and the shopper
      // is viewing '/circles/' cleanly. Seems like state is ok, but
      // I have not tested thoroughly.
    }

    const initialState = {
      id:         circleObj.id,
      identifier: circleObj.identifier,
      label:      circleObj.label,
     postal_code: circleObj.postal_code,
      time_zone:  circleObj.time_zone || "Eastern Time (US & Canada)",
      tax_rate:   circleObj.tax_rate || 0,
      tax_rate_food:
                  circleObj.tax_rate_food || 0,
      // detail: '',
      // img_url: '',
      // city: '',
      // county: '',
      // state: '',
      // country: '',
    };
    console.log("cef: initialState", initialState);

    const { formData, handleChange, reset, editFormValues } = useForm(initialState);

    if (signedIn) {
        const handleSubmit = (e) => {
            e.preventDefault();

            // clear out any error messages, success statuses
            dismissErrorsList();
            setErrorsDoExistToFalse();
            setJustGotClearToFalse();
        
            console.log('formData for editCircle: ', formData);
            editCircle(formData);
        
            // If there is an error we want to preserve formData and show the message.
        }

        // Circle admins will have power to edit. For now, they should
        // not access the circle's Plan and/or change it. We limit this
        // to the Circle's leader.
        const if_shopper_is_circle_leader =  shopper.id === circleObj.leader_id;

        const postalCodeTippy = t('circle.postal_code.tippyContent')
                        + " " + t('circle.postal_code.tippyContent99999');

        return (
          <article>
            <HeadingBuilder
              Size           = 'h3'
              headingText    = {t('circleEditForm.formPart.headlineLabel')}
              value          = {circleObj.identifier}
              // Update Circle: oipuy
              tippyAlertText = {t('circleEditForm.formPart.headlineTippyContent')}
              tippyText      = {t('circleEditForm.formPart.headlineTippyContent')}
            />
            <form onSubmit={handleSubmit}>
<Table responsive striped bordered hover size="sm" style={tableShow}>
  <thead >
  </thead>
  <tbody className={styles.trTdFC} style={tableData} >
    <tr>
      <td>
        <HeadingBuilder
          containerType  = 'container-input-label'
          headingText    = {t('circle.identifier.label')}
          // Shopping Circle ID
          labelHtmlFor   = "identifier_cef"
          tippyAlertText = {t('circle.identifier.tippyContent')}
          tippyText      = {t('circle.identifier.tippyContent')}
        />
      </td>
      <td>
        <input
          key="20"
          type="text"
          style={{ textTransform: "lowercase" }}
          id="identifier_cef"
          name="identifier"
          // autoComplete="username"
          onChange={handleChange}
          value={formData.identifier}
        />
        &nbsp;*
      </td>
    </tr>
    <tr>
      <td>
        <HeadingBuilder
          containerType  = 'container-input-label'
          headingText    = {t('circle.label.label')}
          // Shopping Circle Descriptive Name
          labelHtmlFor   = "label_cef"
          tippyAlertText = {t('circle.label.tippyContent')}
          tippyText      = {t('circle.label.tippyContent')}
        />
      </td>
      <td>
        <textarea
          key="30"
          type="text"
          id="label_cef"
          name="label"
          onChange={handleChange}
          value={formData.label}
        />
        &nbsp;*
      </td>
    </tr>
    <tr>
      <td>
        <HeadingBuilder
          containerType  = 'container-input-label'
          headingText    = {t('circle.postal_code.label')}
          // Zip Code
          labelHtmlFor   = "postal_code_cef"
          tippyAlertText = {postalCodeTippy}
          tippyText      = {postalCodeTippy}
        />
      </td>
      <td>
        <input
          key="40"
          type="text"
          id="postal_code_cef"
          name="postal_code"
          onChange={handleChange}
          value={formData.postal_code}
        />
        &nbsp;*
      </td>
    </tr>
    <tr>
      <td>
        <HeadingBuilder
          containerType  = 'container-input-label'
          headingText    = {t('circle.time_zone.label')}
          // Time Zone
          labelHtmlFor   = "time_zone_cef"
          tippyAlertText = {t('circle.time_zone.tippyContent')}
          tippyText      = {t('circle.time_zone.tippyContent')}
          // Where will your circle operate?
        />
      </td>
      <td>
        <select
          key="50"
          type="text"
          id="time_zone_cef"
          name="time_zone"
          onChange={handleChange}
          value={formData.time_zone}
        >
          {/* <option value="">Choose one:/option> */}
          <option value='' disabled>{t('global.chooseOne')}</option>
          <option value={lookup_tz_info({tz_term: "guam"})}
            >{t('circle.tz_name.guam')}</option>
          <option value={lookup_tz_info({tz_term: "midway_island"})}
            >{t('circle.tz_name.midway_island')}</option>
          <option value={lookup_tz_info({tz_term: "american_samoa"})}
            >{t('circle.tz_name.american_samoa')}</option>
          <option value={lookup_tz_info({tz_term: "hawaii"})}
            >{t('circle.tz_name.hawaii')}</option>
          <option value={lookup_tz_info({tz_term: "alaska"})}
            >{t('circle.tz_name.alaska')}</option>
          <option value={lookup_tz_info({tz_term: "pacific_time_us_n_canada"})}
            >{t('circle.tz_name.pacific_time_us_n_canada')}</option>
          <option value={lookup_tz_info({tz_term: "arizona"})}
            >{t('circle.tz_name.arizona')}</option>
          <option value={lookup_tz_info({tz_term: "mountain_time_us_n_canada"})}
            >{t('circle.tz_name.mountain_time_us_n_canada')}</option>
          <option value={lookup_tz_info({tz_term: "central_time_us_n_canada"})}
            >{t('circle.tz_name.central_time_us_n_canada')}</option>
          <option value={lookup_tz_info({tz_term: "indiana_east"})}
            >{t('circle.tz_name.indiana_east')}</option>
          <option value={lookup_tz_info({tz_term: "eastern_time_us_n_canada"})}
            >{t('circle.tz_name.eastern_time_us_n_canada')}</option>
          <option value={lookup_tz_info({tz_term: "puerto_rico"})}
            >{t('circle.tz_name.puerto_rico')}</option>
          <option value={lookup_tz_info({tz_term: "fiji"})}
            >{t('circle.tz_name.fiji')}</option>
          <option value={lookup_tz_info({tz_term: "wellington"})}
            >{t('circle.tz_name.wellington')}</option>
          <option value={lookup_tz_info({tz_term: "new_caledonia"})}
            >{t('circle.tz_name.new_caledonia')}</option>
          <option value={lookup_tz_info({tz_term: "sydney"})}
            >{t('circle.tz_name.sydney')}</option>
          <option value={lookup_tz_info({tz_term: "melbourne"})}
            >{t('circle.tz_name.melbourne')}</option>
          <option value={lookup_tz_info({tz_term: "darwin"})}
            >{t('circle.tz_name.darwin')}</option>
          <option value={lookup_tz_info({tz_term: "tokyo"})}
            >{t('circle.tz_name.tokyo')}</option>
          <option value={lookup_tz_info({tz_term: "seoul"})}
            >{t('circle.tz_name.seoul')}</option>
          <option value={lookup_tz_info({tz_term: "perth"})}
            >{t('circle.tz_name.perth')}</option>
          <option value={lookup_tz_info({tz_term: "singapore"})}
            >{t('circle.tz_name.singapore')}</option>
          <option value={lookup_tz_info({tz_term: "bangkok"})}
            >{t('circle.tz_name.bangkok')}</option>
          <option value={lookup_tz_info({tz_term: "rangoon"})}
            >{t('circle.tz_name.rangoon')}</option>
          <option value={lookup_tz_info({tz_term: "dhaka"})}
            >{t('circle.tz_name.dhaka')}</option>
          <option value={lookup_tz_info({tz_term: "kathmandu"})}
            >{t('circle.tz_name.kathmandu')}</option>
          <option value={lookup_tz_info({tz_term: "kolcata"})}
            >{t('circle.tz_name.kolcata')}</option>
          <option value={lookup_tz_info({tz_term: "mumbai"})}
            >{t('circle.tz_name.mumbai')}</option>
          <option value={lookup_tz_info({tz_term: "islamabad"})}
            >{t('circle.tz_name.islamabad')}</option>
          <option value={lookup_tz_info({tz_term: "karachi"})}
            >{t('circle.tz_name.karachi')}</option>
          <option value={lookup_tz_info({tz_term: "kabul"})}
            >{t('circle.tz_name.kabul')}</option>
          <option value={lookup_tz_info({tz_term: "baku"})}
            >{t('circle.tz_name.baku')}</option>
          <option value={lookup_tz_info({tz_term: "tehran"})}
            >{t('circle.tz_name.tehran')}</option>
          <option value={lookup_tz_info({tz_term: "nairobi"})}
            >{t('circle.tz_name.nairobi')}</option>
          <option value={lookup_tz_info({tz_term: "cairo"})}
            >{t('circle.tz_name.cairo')}</option>
          <option value={lookup_tz_info({tz_term: "jerusalem"})}
            >{t('circle.tz_name.jerusalem')}</option>
          <option value={lookup_tz_info({tz_term: "kyiv"})}
            >{t('circle.tz_name.kyiv')}</option>
          <option value={lookup_tz_info({tz_term: "riga"})}
            >{t('circle.tz_name.riga')}</option>
          <option value={lookup_tz_info({tz_term: "berlin"})}
            >{t('circle.tz_name.berlin')}</option>
          <option value={lookup_tz_info({tz_term: "madrid"})}
            >{t('circle.tz_name.madrid')}</option>
          <option value={lookup_tz_info({tz_term: "west_central_africa"})}
            >{t('circle.tz_name.west_central_africa')}</option>
          <option value={lookup_tz_info({tz_term: "london"})}
            >{t('circle.tz_name.london')}</option>
          <option value={lookup_tz_info({tz_term: "monrovia"})}
            >{t('circle.tz_name.monrovia')}</option>
          <option value={lookup_tz_info({tz_term: "azores"})}
            >{t('circle.tz_name.azores')}</option>
          <option value={lookup_tz_info({tz_term: "barasilia"})}
            >{t('circle.tz_name.barasilia')}</option>
          <option value={lookup_tz_info({tz_term: "buenos_aires"})}
            >{t('circle.tz_name.buenos_aires')}</option>
          <option value={lookup_tz_info({tz_term: "montevideo"})}
            >{t('circle.tz_name.montevideo')}</option>
          <option value={lookup_tz_info({tz_term: "newfoundland"})}
            >{t('circle.tz_name.newfoundland')}</option>
          <option value={lookup_tz_info({tz_term: "georgetown"})}
            >{t('circle.tz_name.georgetown')}</option>
          <option value={lookup_tz_info({tz_term: "santiago"})}
            >{t('circle.tz_name.santiago')}</option>
          <option value={lookup_tz_info({tz_term: "caracas"})}
            >{t('circle.tz_name.caracas')}</option>
          <option value={lookup_tz_info({tz_term: "lima"})}
            >{t('circle.tz_name.lima')}</option>
          <option value={lookup_tz_info({tz_term: "bogota"})}
            >{t('circle.tz_name.bogota')}</option>
          <option value={lookup_tz_info({tz_term: "mexico_city"})}
            >{t('circle.tz_name.mexico_city')}</option>
          <option value={lookup_tz_info({tz_term: "saskatchewan"})}
            >{t('circle.tz_name.saskatchewan')}</option>
          <option value={lookup_tz_info({tz_term: "mazatlan"})}
            >{t('circle.tz_name.mazatlan')}</option>
          <option value={lookup_tz_info({tz_term: "tijuana"})}
            >{t('circle.tz_name.tijuana')}</option>
        </select>
        &nbsp;*
      </td>
    </tr>
  { stripeIsActive && if_shopper_is_circle_leader
  ? <tr>
      <td>
        <HeadingBuilder
          containerType  = 'container-input-label'
          headingText    = {t('circle.plan.label')}
          // Plan
          labelHtmlFor   = "plan_cef"
          tippyAlertText = {t('circle.plan.tippyContent')}
          tippyText      = {t('circle.plan.tippyContent')}
        />
      </td>
      <td>
        Stripe link to click to change/manage plan
      </td>
    </tr>
  : null
  }
    <tr>
      <td>
        <HeadingBuilder
          containerType  = 'container-input-label'
          headingText    = {t('circle.tax_rate.label')}
          // Sales Tax Rate
          labelHtmlFor   = "tax_rate_cef"
          tippyAlertText = {circleTaxText}
          tippyText      = {circleTaxText}
        />
      </td>
      <td>
        <NumericFormat displayType="input"
          key="60"
          type="text"
          id="tax_rate_cef"
          name="tax_rate"
          onChange={handleChange}
          value={formData.tax_rate}
          decimalScale={3}
        />
        &nbsp;%
      </td>
    </tr>
    <tr>
      <td>
        <HeadingBuilder
          containerType  = 'container-input-label'
          headingText    = {t('circle.tax_rate_food.label')}
          // Food Sales Tax Rate
          labelHtmlFor   = "tax_rate_food_cef"
          tippyAlertText = {circleFoodTaxText}
          tippyText      = {circleFoodTaxText}
        />
      </td>
      <td>
        <NumericFormat displayType="input"
          key="70"
          type="text"
          id="tax_rate_food_cef"
          name="tax_rate_food"
          onChange={handleChange}
          value={formData.tax_rate_food}
          decimalScale={3}
        />
        &nbsp;%
      </td>
    </tr>
  </tbody>
</Table>

      {/* // detail: '',
      // img_url: '',
      // city: '',
      // county: '',
      // state: '',
      // country: '', */}

        {/* TODO: ?Will translating Submit be handled automatically by html?
            https://www.w3.org/International/wiki/Locale-based_forms */}
          <input key="170" type='submit' value='Submit' />
        </form>
        { errorsDoExist && <ErrorList /> }
        <BackButton />
      </article>
     )
    } else {
      return (
        <HeadingBuilder
          Size = 'h3'
          headingText = {t('global.notSignedInMessage')}
          // Not Authorized - Please Sign in or Sign up
        />
      )
    }
}

export default CircleEditForm;