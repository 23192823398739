import React from 'react'
import { Link } from 'react-router-dom'
import { usePhrasing } from "../hooks/useSkuUtils";

export const SkuEditLink = ( {sku, linkTextPer} ) => {
  const { typicalWeightString } = usePhrasing();

  return (
    <Link to={`/skus/${sku.id}?showEdit=1`}>
      {
        linkTextPer === 'WeightyItem'
          ? typicalWeightString({sku})
          : sku.label
      }
    </Link>
  )
}

export default SkuEditLink