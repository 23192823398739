import React, { useContext } from 'react';
import { ShopperContext } from '../context/shopper'
import { useUtils } from "../hooks/useUtils";
import { showRoundedAbsAmt, findAbsAmtAsPct } from '../utils';
import { OutlayAmt } from '../components/OutlayAmt';
import AdminChangeOtherOrder from './AdminChangeOtherOrder'

//import { useTranslation } from "react-i18next";

export const OthersOrders = ({
    if_shopper_is_circle_admin,
    order,
    skuAmtForOthers,
    trip,
    sku,
    skuPrice
   }) => {
  console.log("ooA sqfo", skuAmtForOthers)
  console.log("ooB sku", sku)
  console.log("ooC skuPrice", skuPrice)
  // FIX ME: need to use signedIn or shopper here?
  const { orderItems, showOutlayWithTax, showOthersZeroOrders } = useContext(ShopperContext);
  console.log("ooD orderItems", orderItems)
  console.log("ooE showOthersZeroOrders", showOthersZeroOrders)
  const { shoppers_order_for_this_sku_this_trip } = useUtils();
  //const { t } = useTranslation();

  const isDiscrete =  sku.type == "DiscreteItem" ? true : false;
  //const isGranular =  sku.type == "GranularItem" ? true : false;
  const isWeighty  =  sku.type == "WeightyItem" ? true : false;

  if (isWeighty && (!sku.typical_weight || typeof sku.typical_weight == 'undefined')) {
    console.log("ooNG weightySku but no typical weight")
    //console.log("ooNG weightySku but no typical weight", isWeighty && (!sku.typical_weight || typeof sku.typical_weight == 'undefined')
  }

  function s_by_updated_at( a, b )
  {
    if ( a[0].updated_at < b[0].updated_at ){
      return -1;
    }
    if ( a[0].updated_at > b[0].updated_at ){
      return 1;
    }
    return 0;
  }

  // skuAmtForOthers looks like: 6,0;10,0;5,0;17,0;19,0; (shopperId, absAmt;...)
  // unpack/split by ';' and then by ','
  const shopperDelim = ';';
  const amtDelim = ',';
  // TODO: Curious, how to include variable in a regex that has an anchor?
  //const regex = new RegExp(`${shopperDelim}$`);
  //const skuAmtForOthersB = skuAmtForOthers.replace(regex, '')
  //console.log("ooE sqfo", skuAmtForOthersB)

  const others = skuAmtForOthers.split(shopperDelim);
  // (split here by ';' and then below by ',')
  console.log("ooF others", others);

  // remove empty elements ('') that result from ';' at end of skuAmtForOthers
  // or if skuAmtForOthers was empty
  // or if skuAmtForOthers was 0
  //   (unless this is for an admin who wants to see items of abs_amt of 0)
  const othersRows =
    others.filter( oth =>
      oth != ''
        && (
          oth.slice(-2) != ',0'
            ||
          (showOthersZeroOrders && if_shopper_is_circle_admin)  // admin check is superfluous
        )
      )

          .map((oth) => {
      const [ othOrderShopperId, othOrderAmt ] = oth.split(amtDelim);
      // (split above by ';' and then here by ',')

      const othShopperOrderItem = shoppers_order_for_this_sku_this_trip({
        oItems: orderItems,
        shopper_id: othOrderShopperId,
        sku_id: sku.id,
        trip_id: order.trip_id
      })
      console.log("ooNa orderItem", othShopperOrderItem);

      // NOTE: I'm guessing othShopperOrderItem.abs_amount == othOrderAmt
      // which brings into question: why even build skuAmtForOthers
      // with its delims and split requirements?
      // Just search for "other" shoppers in the circle and the
      // abs_amount of their orders for same sku and trip.
      return [ othShopperOrderItem, othOrderAmt ];
          })
          .sort(s_by_updated_at)
          .map((othOIandAmt) => {
      const [ othShopperOrderItem, othOrderAmt ] = othOIandAmt;
      console.log("ooNb orderItem", sku.id, othShopperOrderItem);
      const othAbsAmt = othOrderAmt;
      console.log("ooNc othAbsAmt", sku.id, othAbsAmt);
      const othAbsAmtAsPct = Math.trunc(
                                findAbsAmtAsPct({topNum: othAbsAmt, sku}) );
      console.log("ooJ othAbsAmtAsPct", sku.id, othAbsAmtAsPct);
      console.log("ooJb order o", order);

      const unitQty =
        (isDiscrete || isWeighty)
          ? Math.round(othAbsAmt * 100) / 100
          : '--';
      console.log("ooK unitQty", sku.id, unitQty);

      const adjustedAbsAmtAsPct =
        (isWeighty && sku.typical_weight)
          ? othAbsAmtAsPct / sku.typical_weight
          : othAbsAmtAsPct;

    return (
    <tr key={'oo' + othShopperOrderItem.id}>
      <td className="ellipsis">
        {/* ID */}
        {sku.identifier}
      </td>

      <td> </td>
      <td> </td>

      <td>
        {/* Store */}
      </td>

      <td>
        {/* Who */}
        <b>{ othShopperOrderItem.shopper.shortname }</b>
      </td>

      <td>
        {/* "Unit Count" || Sku has this many units */}
      </td>

      <td>
        {/* Price */}
      </td>

      {
        (if_shopper_is_circle_admin)
          ? <AdminChangeOtherOrder
              order={othShopperOrderItem}
              skuPrice={skuPrice}
              trip={trip}
              sku={sku}
            />
          :
            <>
              <td>
                {/* "UnitQty" - add sku.units (e.g. 'lb') if sku isWeighty */}
                {unitQty} { isWeighty && sku.units }
              </td>
        
              <td>
                {/* "Unit Cost" */}
              </td>
        
              <td>
                {/* "AbsAmt" */}
                {showRoundedAbsAmt({ adjustedAbsAmtAsPct }) + "%"}
              </td>
        
              <td className = "oneline-container">
                <OutlayAmt
                  trip        = {trip}
                  sku         = {sku}
                  sku_price   = {skuPrice}
                  order       = {othShopperOrderItem}
                  decimal_amt = {findAbsAmtAsPct({topNum: othAbsAmt, sku}) /100}
                  showOutlayWithTax = {showOutlayWithTax}
                />
              </td>
        
              <td>
                {/* "O" button: */}
              </td>
            </>
      }

      <td>
        {/* adjustedCircleAmt */}
      </td>

      <td>
        {/* circleOutlay */}
      </td>

    </tr>
        )
      })

  console.log("ooH othersRows", othersRows);
  return  othersRows.length > 0  ?  othersRows  :  null;
      };

export default OthersOrders;