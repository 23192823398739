import React, { useEffect, useRef, useContext } from 'react';
import { ShopperContext } from '../context/shopper'
import { useStyles } from "../hooks/useStyles";
//import { useTranslation } from "react-i18next";
//import Tippy from '@tippyjs/react';
//import 'tippy.js/dist/tippy.css';

// Present a list of errors with a Dismiss button
export const ErrorList = () => {
  const { errorsList, dismissErrorsList } = useContext(ShopperContext);
  const { setErrorsDoExistToFalse } = useContext(ShopperContext);
  //const { signedIn } = useContext(ShopperContext);
  //const { t } = useTranslation();

  const elementRef = useRef();
  const { tableShow } = useStyles();
  useEffect(() => {
    console.log("elRef.current", elementRef.current);
    elementRef.current.focus();
  }, []);

  const handleClick = ( () => {
    dismissErrorsList();
    setErrorsDoExistToFalse();
  })
 
  return (
    <div style={tableShow} ref={elementRef}>
      {errorsList}
      <button
        className={`
          dismissMessageButton
        `}

        onClick={() => handleClick()}>
        <span>Dismiss Error Message</span>
      </button>
    </div>
  )
}
