import React, {useContext} from 'react';
import { ShopperContext } from '../context/shopper';

// For the given sku, show who has ordered and how much.
export const OrderBreakoutRow = ( {
  sku,
  tripShoppers,
  tripOrderItems,
} ) => {
  // console.log('obr sku', sku)
  // console.log('obr ts top', tripShoppers);
  // console.log('obr toi top', tripOrderItems);
  const { circleShoppers } = useContext(ShopperContext);

  function s_oi_by_abs_amount_desc( a, b )
  {
    if ( Number(b.abs_amount) < Number(a.abs_amount)){
      return -1;
    }
    if ( Number(b.abs_amount) > Number(a.abs_amount)){
      return 1;
    }
    return 0;
  }

  // The amt will show numbers with decimal place per 
  // const { chooseDecimalScaleQty } = usePricing();
  // const decimalScale = chooseDecimalScaleQty({sku});

  // function:
  const amtToShow = (sku, abs_amt) => {
    return sku.type === 'DiscreteItem'
      ? Math.round(abs_amt * 100) / 100
      : sku.type === 'GranularItem'
        ? Math.round(abs_amt*100).toString() + '%'
        : abs_amt
  }

  // Find OrderItems for this sku.
  // We have orderItems of trip via 'tripOrderItems'.
  // A previous check of tmpShopAmt... and tmpCircAmt... showed: abs_amt > 0.
  const skuOrderAmtsByShopper =
    tripOrderItems
    .filter( oi => oi.sku_id === sku.id  &&  oi.abs_amount > 0 )
    .sort( s_oi_by_abs_amount_desc )
    .map( oi => {
      // convert to a list of "shopper.shortname: amt" items:

      const oi_shopper = tripShoppers.find(s =>  oi.shopper_id === s.id)
                            ||
                    circleShoppers.find(cs =>  oi.shopper_id === cs.shopper.id);
    // Maybe oi.shopper_id left the circle, so their id won't be in tripShoppers.
    // (This might be vestigial behavior in dev since leaving a circle should
    // remove the shopper's orderItems, hence no such oi's for trip. TODO: check)
    // Instead, look in circleShoppers for the shopper info.

    // If 'tripShoppers' supplied shopper info, then use 'oi_shopper.shortname'.
    // Else, 'oi_shopper' is a circleShoppers object, which has a 'shopper' obj
    // holding the 'shortname'.
      const shortname =
        oi_shopper
          ? oi_shopper.shortname || oi_shopper.shopper.shortname
          : '*__*';

      return `${shortname}: ${amtToShow(sku, oi.abs_amount)}`;
    })
    .join(",\u00A0\u00A0");
    // basically, Unicode chars for '&nbsp;' - for more space between names
    // TODO: Maybe give option to show the list as comma- or newline-separated
  //console.log('soabs', sku.id, 'a-', skuOrderAmtsByShopper, '-b');

  return (
    <tr>
      <td>
        <small>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{skuOrderAmtsByShopper}</small>
      </td>
    </tr>
  )
}

export default OrderBreakoutRow