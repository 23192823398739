import React, { useContext } from 'react';
import { ShopperContext } from '../context/shopper'
import { brandStr } from '../utils';
import PriceLink from '../components/PriceLink'
import HeadingBuilder from '../components/HeadingBuilder'
import Table from 'react-bootstrap/Table';
import { useTranslation } from "react-i18next";

export const Prices = ({sku}) => {
  const { prices, signedIn } = useContext(ShopperContext);
  console.log('starting prices for sku: ', prices);

  const { t } = useTranslation();

  const skuBrand = sku.brand || '__';

  if (signedIn) {
      function s_by_price_date( a, b )
      {
        if ( a.price_date < b.price_date ){
            return -1;
        }
        if ( a.price_date > b.price_date ){
          return 1;
        }
        return 0;
      }
      console.log('SkuPrices for sku: ', sku);
      const pricesList =
        prices.filter(p => p.sku.id == sku.id)
              .sort( s_by_price_date )
              .map(p => <PriceLink
                  key={p.id}
                  sku = {sku}
                  price = {p}
                />
              );
      console.log('Skus.js pricesList: ', pricesList);

      return (
          <article>
              <HeadingBuilder
                Size = "h4"
                headingText = {t('price.formPart.headlineLabel')}
                value = {brandStr({brand: skuBrand}).concat('', sku.label)}
                  // Price(s) for: Kirkland - Butter, Salted
              />
              {t('price.formPart.subHeadlineLabel')} {sku.identifier}
                {/* Sku 9661938488 */}
              <br/>
              <br/>
              <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>{t('price.colHeader.asOf')}</th>
                  <th>{t('price.colHeader.price')}</th>
                  <th>{t('price.colHeader.discount')}</th>
                  <th>{t('price.colHeader.total')}</th>
                  <th>{t('price.colHeader.discStarts')}</th>
                  <th>{t('price.colHeader.discEnds')}</th>
                  <th>{t('price.colHeader.link')}</th>
                </tr>
              </thead>
              <tbody>
                {pricesList}
              </tbody>
              </Table>
              {/* <br/> */}
              <HeadingBuilder
                tippyAlertText = {t('price.addPriceButton.tippyContent')}
                tippyText      = {t('price.addPriceButton.tippyContent')}
                linkUrl        = {`/skus/${sku.id}/prices/`}
                headingText    = {t('price.addPriceButton.label')}
                               // Add Price
              />
          </article>
      )
  } else {
    return (
      <HeadingBuilder
        Size = 'h3'
        headingText = {t('global.notSignedInMessage')}
        // Not Authorized - Please Sign in or Sign up
      />
    )
  }
}

export default Prices;
