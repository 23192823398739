import React from 'react';
import { formatMoney } from '../utils';
//import { ShopperContext } from '../context/shopper';

export const TripCostLink = ( {shortname, outlay, outlayWithTax, currency} ) => {

  return (
    <tr>
      <td>
        {shortname}
      </td>

      <td>
        {currency}{formatMoney({amount: outlay})}
      </td>

      <td>
        {
          outlayWithTax
            ? <span>{currency}{formatMoney({amount: outlayWithTax})}</span>
            : <span>--</span>
        }
      </td>
    </tr>
  )
}

export default TripCostLink