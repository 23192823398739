import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ShopperContext } from '../context/shopper';
import { useForm } from "../hooks/useForm";
import { HeadingBuilder } from "./HeadingBuilder";
import { useTranslation } from "react-i18next";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

const Signin = () => {
  const [errorsList, setErrorsList] = useState([]);
  const {signin} = useContext(ShopperContext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const initialState = {
    identifier: '',
    password: '',
  };
  const { formData, handleChange, reset, editFormValues } = useForm(initialState);

  function mkHtmlListOfErrorMessagesSignin() {
    // invoke with a list of error strings, e.g.: mkHtmlListOfErrorMessagesSignin(...data.errors)
    // TODO: Logic is same as in 'shopper.js' - dry this?
    const args = [...arguments]
    return [...new Set(args)].map(err => <li className="stdError" key={err} >{err}</li>)
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const configObj = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ...formData })
    }

    fetch('/api/signin', configObj)
    .then(res => res.json())
    .then(shopper => {
        //console.log("Signin.js fetch /api/signin", shopper)
        if (!shopper.errors) {
            // TODO: test me for errors handled via array
            signin(shopper)
            navigate('/')  // TODO: where should we go? How to go "Home"?

        } else {
            reset()
            const errorLis = mkHtmlListOfErrorMessagesSignin(...shopper.errors);
            setErrorsList(errorLis)
        }
    })
  }

  return (
    <>
    <div className="container-fluid mt-5 ">
    <div className='signInBkgrnd' id="duotone_sign_in"></div>

    <div className="container mt-6 p-5">
      <h1 className="fs-1 white fw-light">SIGN IN</h1>
      <p className='fs-3'>{ t('signup.tagline.line1') }<br />
                          { t('signup.tagline.line2') }
        {/* And tell EVERYBODY\n to join you in the savings! */}
      </p>
      </div>
      <div className="container">
        <div className="row row-cols-1 row-cols-lg-5 g-2 g-lg-3 d-flex justify-content-center">
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label>
                <HeadingBuilder
                  containerType  = "form-label fw-bold"
                  headingText    = {t('global.label.username')} 
                  // Username
                  labelHtmlFor   = "identifier_sign_in"
                  tippyAlertText = {t('signin.username.tippyContent')}
                  tippyText      = {t('signin.username.tippyContent')}
                  forceShowAlertText = "1"
                />
              </label>
              <input
                autoFocus
                className="form-control"
                type="text"
                style={{ textTransform: "lowercase" }}
                id="identifier_sign_in"
                name="identifier"
                autoComplete="username"
                onChange={handleChange}
                value={formData.identifier}
              /> <br/>
              <label className="form-label fw-bold" htmlFor="password_sign_in">Password </label>
              <input
                className="form-control"
                type="password"
                id="password_sign_in"
                name="password"
                autoComplete="current-password"
                onChange={handleChange}
                value={formData.password}
              /> <br/>
              <input
                type='submit'
                className="btn btn-primary"
                value='Submit'
                disabled={
                  formData.identifier.trim().length === 0
                    ||
                  formData.password.trim().length === 0
                }
              />
            </div>
          </form>
        </div>
        <div className="container">
          <div className="row row-cols-1 d-flex justify-content-center">
            <ul>
              {errorsList}
          </ul>
        </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default Signin;
