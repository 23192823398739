import React from 'react';
import { useTranslation } from "react-i18next";

export const TermsPrivacyUrls = () => {

  const { t } = useTranslation();

  return (
    <>
      <a href="https://app.termly.io/document/terms-of-service/488e8514-b18a-4be5-b7fd-46e727630929" target="_blank" rel="noopener noreferrer">
        { t('shopper.agreeTermsPrivacyA.label20') }
        {/* Terms */}
      </a>
      ,&nbsp;
      <a href="https://app.termly.io/document/privacy-policy/4e8e5ad5-0064-4541-a069-a09821e60578" target="_blank" rel="noopener noreferrer">
        { t('shopper.agreeTermsPrivacyA.label40') }
        {/* PrivacyPolicy */}
      </a>
    </>
  )
}

export default TermsPrivacyUrls;