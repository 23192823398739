import React, { useContext } from 'react';
import { ShopperContext } from '../context/shopper';
import * as Constants from '../constants';
import { IoMdHelpCircleOutline } from "react-icons/io";

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { useTranslation } from "react-i18next";

export const TippyTipIcon = ({
  // Show (?) alert icon.
  alertMessage = "",
  showTippyAlert = false,
  forceShowAlertTextBoolean = false,
  toggleTippyAlert = (function () { }),
}) => {

  const { shopper } = useContext(ShopperContext);
  const { t } = useTranslation();

  const showHelpAlertIcon =
    shopper == null
      ? forceShowAlertTextBoolean
      : shopper.show_tippy_help_alert_icons || forceShowAlertTextBoolean;

  return (
    <>
      {
        (showHelpAlertIcon  &&  alertMessage.length > 0)
          ? <>
              <Tippy content =
                {showTippyAlert ? t('global.clickToHide') : alertMessage}
              >
                <button 
                  type="button"
                  style={{
                    border: "none",
                    margin: 5,
                    padding: 0,
                    background: showTippyAlert ? Constants.O_BOX_RED : "none"
                  }}
                  onClick={toggleTippyAlert}
                >
                  {/* (?) icon */}
                  <span><IoMdHelpCircleOutline/></span>
                </button>
              </Tippy>
              &nbsp;
            </>
          : null
      }
    </>
  )
}

export default TippyTipIcon;