import React from 'react';
import TextToSpeech from './TextToSpeech';
import * as Constants from '../constants';

export const ShowAlert = ({
  showAlert  = false,
  alertMsg   = "",
  alertAudio = "",
}) => {

  const alertAudioText =
    [ alertAudio || alertMsg ]
      .map(text => text.replace(/\/\//g, '!'))
        // the bang (!) induces pause effect. "!.!" longer pause, but too long!
      .map(text => text.replace(/ft2/g, 'square feet'))
      .map(text => text.replace(/\boz\b/ig, 'ounces'))
      .map(text => text.replace(/\blb\b/g, 'pounds'))
      .map(text => text.replace(/\bAsOf\b/g, 'As Of'))
      .map(text => text.replace(/e[.]g[.][,]?/g, 'for example,'))
        // adding a ',' after 'for example' might get effect of audio pause
      .map(text => text.replace(/i[.]e[.]/g, 'that is,'))
      .map(text => text.replace(/[ ]?[(][?i][)]/g, ''))
        // delete instances of '(?)' and '(i)' - images for Alert and Info icon
  
  return (
    <small>
      {
        showAlert
        ? <div className="container mt-2 px-3">
            { alertMsg }
            { alertAudioText[0].length > Constants.SHOW_AUDIO_UPON_MIN_CHARS  // 50
              ? <>
                  <TextToSpeech text={alertAudioText[0]} />
                  <br/>
                </>
              : null
            }
          </div>
        : null
      }
    </small>
  )
}

export default ShowAlert;