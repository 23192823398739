import React, { useContext, useMemo, useState, useEffect } from 'react';
import { ShopperContext } from '../context/shopper';
import { ErrorList } from './ErrorList';
import { HeadingBuilder } from './HeadingBuilder';
import { useForm } from "../hooks/useForm";
import { useStyles } from "../hooks/useStyles";
import { useLookups } from "../hooks/useSkuUtils";
import { useParams } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { BackButton } from './BackButton';
import { useTranslation } from "react-i18next";
import styles from '../css/trTdFirstChild.module.css';

export const InvitationEditForm = () => {
    const { signedIn, shopper, invitations, editInvitation, addCircleShopper, circleShoppers, trips, addOrderItem, fetchCircles, fetchTrips, fetchSkus } = useContext(ShopperContext);
    const { errorsDoExist, dismissErrorsList, setErrorsDoExistToFalse } = useContext(ShopperContext);
    const { justGotClear, setJustGotClearToFalse } = useContext(ShopperContext);
    // 'Submit' triggers 'editInvitation' which then does setJustGotClear(true)
    console.log('ief invitations: ', invitations)

    const { tableShow, tableData } = useStyles();
    const { lookup_status,
            lookup_flavor_action,
            lookup_flavor_response_notes
          } = useLookups();

    const {circle_id, id} = useParams();
    console.log('ief id: ', circle_id, id)

    const { t } = useTranslation();

    const invitation = invitations.find(i => i.id == id);
    console.log('ief invitation: ', invitation);

    if (invitation == null) {
      console.log('ief invitation2: ', invitation)
      // User likely used browser to refresh the page, lost access to state.
      // This causes 'invitation' to be 'undefined'.
      alert('InvitationEditForm ief invitation2 is undefined')  // DELETE ME at some point
      // Let's go back to the 'home' page.
      window.location.replace('/');
    }

    // TODO: Probably want to halt flow if circle_id and/or shopper_id seem off
    if (invitation.circle_id == circle_id) {
      // cool cool
    }
    else {
      console.log("ok? circle_id, invitation's circle_id", circle_id, invitation.circle_id)
      alert('invitation mismatch circle_id')
    }

    if (invitation.shopper_id == shopper.id) {
      // cool cool
    }
    else {
      console.log("ok? shopper_id, invitation's shopper_id", shopper.id, invitation.shopper_id)
      alert('invitation mismatch shopper_id')
    }

    const initialState =  useMemo(
      () => (function () {
     return {
      id: invitation.id,
      circle_id: circle_id,
      status: invitation.status,
      shopper_id: invitation.shopper_id,
      flavor: invitation.flavor,
      invitee: shopper.identifier,
     }
      })(), [invitation, circle_id, shopper]
    );
    console.log("ief: initialState", initialState);

    const { formData, handleChange, reset, editFormValues } = useForm(initialState);
    // console.log('ief formDataA', formData);

    // Generate a signal of 'true' after choosing a new invitation status
    // and no record of clicking 'Submit' yet.
    const [statusIsNew, setStatusIsNew] = useState(false);
    useEffect(() => {
      setStatusIsNew(
        (initialState.status !== formData.status) && ! justGotClear
      )
    }, [initialState, formData, justGotClear]);
    // React helper message advised using 'useMemo' for building 'initialState'.

    if (signedIn) {
        const handleSubmit = (e) => {
            e.preventDefault();

            // clear out any error messages, success statuses
            dismissErrorsList();
            setErrorsDoExistToFalse();
            setJustGotClearToFalse();

      // it seems Rails/Invitation expects 'status' to be presented as a string,
      // and then enum will convert that to a digit for DB entry.
      // We need to convert 'status' from numeric to string form.
      // ('flavor' is already in string form.)
      //
      // console.log('ief formDataB', formData);
      // console.log('ief ls fds', lookup_status({status: formData.status}) );
      const newFormData = { ...formData, status: lookup_status({status: formData.status}) }
      // console.log('ief formDataB for editInvitation: ', newFormData);

      const maybeAddCircleShopper = (r) => {
        console.log("r1", r);
        console.log('newFormData for maybeAddCircleShopper: ', newFormData);
        if (
          newFormData.status === 'Accepted'
            &&
          newFormData.flavor === 'circle_wants_shopper'
        ) {

          // prepare object to submit as new CircleShopper
          const formDataCircleShopper = {
            identifier: shopper.identifier,
            shopper_is_admin: false,
            circle_id: circle_id,
            cs_shopper_id: invitation.shopper_id,
          };
          console.log('object for invitee adding CircleShopper', formDataCircleShopper);

          addCircleShopper(formDataCircleShopper);
        }
        console.log("errorsDoExist1", errorsDoExist);
        return('sacs Success!');  // TODO: really?
      }

      const showSuccessOrFailure = (r) => {
        console.log("r3", r);
        console.log("any circleShoppers2", circleShoppers);
        if (errorsDoExist) {
          console.log("errorsDoExistC", errorsDoExist);
          // keep showing the form and its error message
        } else {
          console.log("errorsDoExistD", errorsDoExist);
        }
        console.log('done')
        return('ssof Success!');  // TODO: really?
      }

      async function editInvitationPromise(circle_id) {
        try {
          const result  = await editInvitation(newFormData);
          const result2 = await maybeAddCircleShopper(result);
          const result3 = await showSuccessOrFailure(result2);

          return result3;
        } catch (error) {
          console.error(error);
        }
      }

      console.log("errorsDoExistA", errorsDoExist);
      editInvitationPromise(circle_id);
    }

    const editInvitationHeadingText =
      t('invitation.formPart.headlineUpdateStartingLabel')
      // 'Update status of invitation '
        + lookup_flavor_action({flavor: invitation.flavor});

    const editInvitationChoicesTippy =
      lookup_flavor_response_notes({flavor: invitation.flavor});

        return (
          <article>
            <HeadingBuilder
              Size        = 'h3'
              headingText = {editInvitationHeadingText}
              value       = {invitation.circle.identifier}
              // Update status of invitation to (Join Circle|Become Leader) oipuy
            />
              <form onSubmit={handleSubmit}>
<Table responsive striped bordered hover size="sm" style={tableShow}>
  <thead >
  </thead>
  <tbody className={styles.trTdFC} style={tableData} >
    <tr>
      <td>
        <HeadingBuilder
          containerType  = "container-input-label"
          headingText    = {t('invitation.status.label')}
          // Invitation Status
          labelHtmlFor   = "status"
          tippyAlertText = {t('invitation.status.tippyContent')}
          tippyText      = {t('invitation.status.tippyContent')}
        />
      </td>
      <td className='oneline-container-left'>
        <select
          key="55"
          type="text"
          id="status"
          name="status"
          onChange={handleChange}
          value={formData.status}
        >
          {/* <option value="">Choose one:/option> */}
          <option value="">{t('invitation.status.optionValue')}</option>
          {/* <option selected value="0">Other</option> // wish this worked... */}
          {/* <option value="0">Make no response</option> */}
          <option value="0">{t('invitation.status.optionValue0')}</option>
          {/* <option value="1">Accept</option> */}
          <option value="1">{t('invitation.status.optionValue1')}</option>
          {/* <option value="2">Reject</option> */}
          <option value="2">{t('invitation.status.optionValue2')}</option>
          {/* "optionValue3": "Label as Improper", */}
          <option value="3">{t('invitation.status.optionValue3')}</option>
        </select>
        &nbsp;
        <HeadingBuilder
          headingText    = '--'
          tippyText      = {editInvitationChoicesTippy}
          tippyAlertText = {editInvitationChoicesTippy}
        />
      </td>
    </tr>
  </tbody>
</Table>

        {/* TODO: ?Will translating Submit be handled automatically by html?
            https://www.w3.org/International/wiki/Locale-based_forms */}
          <input key="170" type='submit' value='Submit' />
        </form>
        <HeadingBuilder
          buttonAction      = { null }
          headingText       = {'.'}
          forceShowBWarning = {statusIsNew}
          setShowBWarning   = {setStatusIsNew}
          bWarningMsg       = {t('invitation.status.warningForSubmit')}
          // Reminder: Click 'Submit' to change the Invitation's status.
        />
        { errorsDoExist && <ErrorList /> }
        <BackButton />
      </article>
     )
    } else {
      return (
        <HeadingBuilder
          Size = 'h3'
          headingText = {t('global.notSignedInMessage')}
          // Not Authorized - Please Sign in or Sign up
        />
      )
    }
}

export default InvitationEditForm;