// Don't apply 'HeadingBuilder' for this component's 'Tippy contents' (styled button)
import React, { useContext } from 'react';
import { ShopperContext } from '../context/shopper';
import * as Constants from '../constants';
import { VscDeviceCameraVideo } from "react-icons/vsc";
import { useTranslation } from "react-i18next";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

export const VideoTipIcon = ({
  // Show video icon whose background turns red when toggled to 'true'.
  // The 'VideoPlayer' parameters allow the parent to control display
  // of the react-player, outside of the VideoTipIcon component.
  showVideoPlayer = false,
  showVideoPlayerSetter = (function () { })
}) => {

  const { shopper } = useContext(ShopperContext);
  const { t } = useTranslation();

  const toggleShowVideoPlayer = () => {
    showVideoPlayerSetter(! showVideoPlayer);
  }

  return (
    <>
      {
        shopper.show_help_video_icons
          ? <>
              &nbsp;&nbsp;
              <Tippy content= {t('global.videoTippyContent')} >
                {/* Click to show/hide video tip */}
                <button 
                  type="button"
                  style={{
                    border: "none",
                    margin: 5,
                    padding: 0,
                    background: showVideoPlayer ? Constants.O_BOX_RED : "none"
                  }} 
                  onClick={toggleShowVideoPlayer}
                >
                  {/* 'Video tip' icon */}
                  <span><VscDeviceCameraVideo size={'1.5em'} /></span>
                </button>
              </Tippy>
            </>
          : null
      }
    </>
  )
}

export default VideoTipIcon;