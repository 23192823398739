import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter as Router} from 'react-router-dom';
import './index.css';
import './assets/main.css';
import './assets/main_plus.css';
import 'bootstrap/dist/css/bootstrap.css';
import { GlobalStyles } from './global-styles';
import App from './App';
//import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

// from https://www.youtube.com/watch?v=Rtz2FoSaBHc
// for preventing running 'console.log' statements in production
console.log('peRAMNE', process.env.REACT_APP_MY_NODE_ENV)
// if (process.env.REACT_APP_MY_NODE_ENV !== "development") {
//   console.log   = function() {};
//   console.debug = function() {};
//   console.info  = function() {};
//   console.warn  = function() {};
//   console.error = function() {};
// }

root.render(
  <React.StrictMode>
    <Router>
      <App />
      <GlobalStyles />
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
