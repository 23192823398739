
export const tax_multiplier = ({
    tax_type,
    trip,
  }) => {
    const find_tax_rate = ({trip_rate, circle_rate}) => {
      return (
        (trip_rate == 0)
          ? 0  // if trip_rate is set to 0, then 0 it is. Otherwise:
          : (trip_rate || circle_rate || 0)
      ) / 100;
    }
  
    switch (tax_type) {
      case 0:  // 'No Tax'
        return 1;
      case 1:  // 'Regular'
        return 1 + find_tax_rate({
          trip_rate: trip.tax_rate,
          circle_rate: trip.circle.tax_rate
          // Note: If admin changes circle.tax_rate,
          // trip.circle.tax_rate might require refresh to show the change in state
          // Similar for circle.tax_rate_food below.
        });
      case 2:  // 'Food'
        return 1 + find_tax_rate({
          trip_rate: trip.tax_rate_food,
          circle_rate: trip.circle.tax_rate_food
        });
      default:
        return 1;  // Or we could surprise them with 1_000!
    }
  };

export const get_tax_type_from_char = ({ char }) => {
  switch (char) {
    case 'N':  // 'No Tax'
      return 0;
    case 'R':  // 'Regular'
      return 1;
    case 'F':  // 'Food'
      return 2;
    default:
      return 0;
  }
}

export const findAbsAmtAsPct = ({
    topNum, sku, type, unit_count
  }) => {
    const isUnitTypeOk =  sku.type === 'DiscreteItem'
                       || sku.type === 'WeightyItem';

    return 100*topNum / ((isUnitTypeOk && sku.unit_count)  ? sku.unit_count : 1)
  };

// Yes, this is super-basic, but it means we show the same percentage value for
// both AbsAmt (for Weighty and Discrete skus) as we do for total Circle Amt.
// This ensures that both will show the percentages via Math.round (i.e. that
// neither is using Math.trunc).
export const showRoundedAbsAmt = ({
    adjustedAbsAmtAsPct
  }) => {
    return Math.round(adjustedAbsAmtAsPct).toString()
  };

export const showOIUpdatedAt = ({
    updated_at
  }) => {
    return updated_at.substring(5,16).replace(/[T]/, '_ _')
  };

export const formatMoney = ({
    amount
  }) => {
    return parseFloat(amount).toFixed(2)
  };


export const brandStr = ({
    brand
  }) => {
    // NOTE: There must be a better way to set default value for object attr param than this:
    const theBrand =  brand == null ? '' : brand;

    return theBrand.length > 0  ?  theBrand + ' - '  :  '';
  };

// TODO: Is there a better test? At least this code is in one place, not three...
export const doesArrayExist = (
    array
  ) => {
    console.log("'array' is what?", typeof(array));
    if (!Array.isArray(array) || !array.length) {
      return false
    }

    return true
  };

export const getTimestringNow = () => {
    const date = new Date();

    // get "Local" ISO format per https://stackoverflow.com/a/49332027/9224467
    function toISOLocal(d) {
      var z  = n =>  ('0' + n).slice(-2);
      var zz = n => ('00' + n).slice(-3);
      var off = d.getTimezoneOffset();
      var sign = off > 0? '-' : '+';
      off = Math.abs(off);
    
      return d.getFullYear() + '-'
             + z(d.getMonth()+1) + '-' +
             z(d.getDate()) + 'T' +
             z(d.getHours()) + ':'  + 
             z(d.getMinutes()) + ':' +
             z(d.getSeconds()) + '.' +
             zz(d.getMilliseconds()) +
             sign + z(off/60|0) + ':' + z(off%60); 
    }

    // https://quickref.me/extract-year-month-day-hour-minute-second-and-millisecond-from-a-date
    // could generate UTC timestaps but shoppers probably want local time.
    //const extract = (date) => date.toISOString().split(/[^0-9]/).slice(0, -1);
    const extract = (date) => toISOLocal(date).split(/[^0-9]/).slice(0, -1);
    //Parameters date is a Date object.
    //Parameters extract is an array of [year, month, day, hour, minute, second, millisecond].
    //console.log('what is extract', extract(date));
    // -> (7) ['2023', '02', '28', '21', '47', '12', '824']

    const timeNow = extract(date);
    // Could use the components of toISOLocal to make the timestamp directly.
    // Using toISOLocal keeps it in its original state, sets up using ISO format.

    // Generate yyyymmddhhmmss date string
    const formattedDate = //year + month + day + hour + min + sec;
      timeNow[0] +
      timeNow[1] +
      timeNow[2] +
      timeNow[3] +
      timeNow[4] +
      timeNow[5];

    return formattedDate;  // 20230228174217
  }