import React, { useContext } from 'react';
import { ShopperContext } from '../context/shopper';
import HeadingBuilder from '../components/HeadingBuilder';
import CircleList from '../components/CircleList';
import { useUtils } from "../hooks/useUtils";
import { useParams, useNavigate } from 'react-router-dom';
import { ErrorList } from './ErrorList';
import { BackButton } from './BackButton';
import { useTranslation } from "react-i18next";

export const ShopperDeleteForm = () => {
    const { signedIn, shopper, circles, deleteShopper, signoutShopperPlus,
      errorsDoExist
    } = useContext(ShopperContext);
    console.log('sdf shopper: ', shopper)

    const navigate = useNavigate();

    const { shopper_is_leader_of_these_circles } = useUtils();
    const circlesShopperLeads =
      shopper_is_leader_of_these_circles({circles: circles, shopper_id: shopper.id});

    const {id} = useParams();
    console.log('sdf id: ', id)

    const { t } = useTranslation();
    
    async function removeShopper (e) {
      // TODO: is event (e) useful for anything?
  
      // Delete a shopper.
      // Backend enforces that the only one that can delete a shopper is the shopper.
  
      console.log("dsh before di", Date.now() );
      const rc = await deleteShopper(shopper.id);
      // If 'deleteShopper' throws an error, the intentional lack of a catch
      // causes termination at that point. It also terminates this 'removeShopper'
      // function and so avoids doing the 'signoutShopperPlus' function below.

      console.log("dsh rc", rc);
      console.log("dsh after dt", Date.now() );

      signoutShopperPlus( (x => {
          x = x || '/';
          return navigate(x);
        })
      );
    }

    // If length is 0 and we make the 'Singular' message, no big deal, won't use.
    const handleCirclesMessage =
      t('shopperDeleteForm.handleCirclesFirst.baseMessage') + (
        circlesShopperLeads.length > 1
          ? t('shopperDeleteForm.handleCirclesFirst.circlesPlural')
          : t('shopperDeleteForm.handleCirclesFirst.circlesSingular')
      );

    const deleteButtonHeading =
      <>{t('shopperDeleteForm.removeShopper.buttonLabel')} '{shopper.identifier}'</>;

    if (signedIn) {
      return (
        <article>
          {/* TODO? <h3>Invite another Shopper to lead Circle "{circleObj.identifier}"</h3> */}

          <HeadingBuilder
            Size = 'h3'
            headingText = {t('shopper.deleteAccountButton.label')+':'}
            // Delete My Account: uiui
            value = {shopper.identifier}
          />

          <HeadingBuilder
            Size = 'h1'
            headingText =  {t('global.areYouSure')}
            // Are You Sure?
            tippyAlertText = {t('shopperDeleteForm.areYouSure.tippyContent')}
            tippyText =      {t('shopperDeleteForm.areYouSure.tippyContent')}
          />

          { circlesShopperLeads.length > 0
            ? <>
                <br/>
                <p>
                  {handleCirclesMessage}
                </p>
                <CircleList circles = {circlesShopperLeads} leaderBased = {true} />
              </>
            : <>
                <HeadingBuilder
                  headingText    = {deleteButtonHeading}
                  // Delete Shopper 'uiui'
                  buttonAction   = {removeShopper}
      
                  tippyAlertText = {t('shopperDeleteForm.areYouSure.tippyContent')}
                  tippyText      = {t('shopperDeleteForm.areYouSure.tippyContent')}
                />
              </>
          }
          { errorsDoExist && <ErrorList /> }
          <br/>
          <BackButton />
        </article>
      )
    } else {
      return (
        <HeadingBuilder
          Size = 'h3'
          headingText = {t('global.notSignedInMessage')}
          // Not Authorized - Please Sign in or Sign up
        />
      )
    }
}

export default ShopperDeleteForm;