import React, { useContext } from 'react';
import HeadingBuilder from './HeadingBuilder';
import CircleLink from '../components/CircleLink';
import { useUtils } from "../hooks/useUtils";
import { usePhrasing } from "../hooks/useSkuUtils";
import { ShopperContext } from '../context/shopper'

export const CircleRow = ( {circle} ) => {
  const { circleShoppers, shopper } = useContext(ShopperContext);
  const { is_shopper_a_circle_admin } = useUtils();
  const { getTippyPostalCode } = usePhrasing();

  const if_shopper_is_circle_admin = is_shopper_a_circle_admin({
    cShoppers: circleShoppers, circle_id: circle.id, shopper_id: shopper.id
  });

  const tippyPostalCode = getTippyPostalCode({circle})

  return (
    <tr>
      <td>
        <CircleLink circle = {circle} />
        { if_shopper_is_circle_admin
          ? ' *'
          : ''
        }
      </td>
      <td>
        <HeadingBuilder
          headingText    = {circle.postal_code}
          // the Circle's postal_code
          tippyAlertText = {tippyPostalCode}
          tippyText      = {tippyPostalCode}
          // postal_code of '99999' triggers tippy text
        />
      </td>
    </tr>
  )
}

export default CircleRow