// Don't apply 'HeadingBuilder' for this component's 'Tippy contents' (anchor/href)
import React, { useContext, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ShopperContext } from '../context/shopper';
import { useForm } from "../hooks/useForm";
import { useStyles } from "../hooks/useStyles";
import * as Constants from '../constants';
import { ClipboardButton } from "./ClipboardButton";
import Table from 'react-bootstrap/Table';
import { ErrorList } from './ErrorList';
import HeadingBuilder from './HeadingBuilder';
import styles from '../css/trTdFirstChild.module.css';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { useTranslation } from "react-i18next";

    
const FeedbackForm = () => {
  const { signedIn, shopper, addFeedback } = useContext(ShopperContext);
  const { errorsDoExist, dismissErrorsList, setErrorsDoExistToFalse } = useContext(ShopperContext);
  const { setJustGotClearToFalse } = useContext(ShopperContext);
  const { showFeedbackForm, setShowFeedbackForm } = useContext(ShopperContext);

  const { tableShow, tableData } = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  console.log('loc object', location)
  console.log('feedback signedIn', signedIn)

  const navigate = useNavigate();

  // Once the form is opened, the shopper can still navigate to a differnt
  // url before submitting feedback. Maybe we should collect a history of
  // such navigation, but we'll start by tracking the last active url
  // before shopper submits comment. The shopper submits a value for the
  // url programmatically via our continuous updating of the url value
  // assigned in initialState. We can't just stash a default value in for
  // initialState.url. Instead we update a state variable 'locatn' with each
  // navigation and set a useEffect to watch for such changes and trigger a
  // 'handleChange' to update the value properly on the form.
  const [locatn, setLoc] = useState(null);
  useEffect(() => {
      setLoc( location.pathname )
  }, [location]);

  const sender =
    signedIn
      ? shopper.firstname + ' ' + shopper.lastname + ' -- ' + shopper.email
      : '';

  const initialState = {
    url: location.pathname,
    // By itself, this initially assigns a pathname for the url.
    // It fails to update if shopper navigates to a different url.
    // Thus the 'locatn' state variable.

    sender:  signedIn ? sender : '',
    topic: '',
    comment: '',
    responded: false,
  };
  //const { formData, handleChange, reset, editFormValues } = useForm(initialState);
  const { formData, handleChange, reset } = useForm(initialState);
  
  // If 'locatn' changes, update the value we are saving in state for 'url'.
  useEffect(() => {
    handleChange({target:
      {name: 'url',
      value: locatn}
    })
  }, [ locatn ]);
  // do not include 'handleChange' in dependency array: Triggers infinite loop!

 if (signedIn) {
  const setShowFeedbackFormToFalse = () => {
    setShowFeedbackForm(false);
  }

  const resetFeedbackForm = () => {
    reset();
  }

  const clickEditShopperLink = () => {
    setShowFeedbackForm(false);
    navigate('/shoppers/' + shopper.id + '/edit');
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    dismissErrorsList();
    setErrorsDoExistToFalse();
    setJustGotClearToFalse();

    // "Thanks for your input!"
    alert( t('feedback.alert.thank_you') );

    console.log('formData for addFeedback: ', formData);
    async function addFeedbackPromise(formDat) {
      try {
        await addFeedback(formDat);

        setShowFeedbackForm(false);
        reset();
      } catch (error) {
        console.error(error);
      }
    }

    // console.log("errorsDoExistM", errorsDoExist);
    addFeedbackPromise(formData);

  }

  const feedbackMessage =
    t('feedback.feedbackMessage.tippyContent') + Constants.FEEDBACK_MAX_CHARS;

  return (
  <article>
    {showFeedbackForm
      ? <>
        {/* Thank you for your feedback! */}
        <h3><b>{t('feedback.formPart.headline')}</b></h3>

        <Tippy content= {t('feedback.loom.tippyContent')} >
          <a href="https://www.loom.com" target="_blank" rel="noopener noreferrer">
            {t('feedback.loom.label')}
          </a>
        </Tippy>

        <form onSubmit={handleSubmit}>
<Table responsive striped bordered hover size="sm" style={tableShow}>
  <thead >
  </thead>
  <tbody className={styles.trTdFC} style={tableData} >
    <tr>
      <td>
        {/* Username */}
        { t('global.label.username') }
      </td>
      <td>
        {shopper.identifier}
      </td>
    </tr>
    <tr>
      <td>
        {/* Sender */}
        {t('feedback.sender.label')}
      </td>
      <td>
        <HeadingBuilder
          containerType  = "container-left"
          buttonAction   = {clickEditShopperLink}
          headingText    = { sender }
          // uu ii -- ui@example.com
          tippyAlertText = {t('feedback.sender.tippyContent')}
          tippyText      = {t('feedback.sender.tippyContent')}
        />
      </td>
    </tr>
    <tr>
      <td>
        <HeadingBuilder
          containerType  = "container-input-label"
          headingText    = {t('shopper.topic.label')}
          // Topic
          labelHtmlFor   = "topic"
          tippyAlertText = {t('shopper.topic.tippyContent')}
          tippyText      = {t('shopper.topic.tippyContent')}
        />
      </td>
      <td>
        <input
          key="20"
          type="text"
          id="topic"
          name="topic"
          onChange={handleChange}
          value={formData.topic}
          maxLength={60}
          size={30}
        />
      </td>
    </tr>
    <tr>
      <td>
        <HeadingBuilder
          containerType  = "container-input-label"
          headingText    = {t('feedback.feedbackMessage.label')}
          // Message
          labelHtmlFor   = "comment"
          tippyAlertText = {feedbackMessage}
          tippyText      = {feedbackMessage}
        />
      </td>
      <td>
        <textarea
          key="30"
          id="comment"
          name="comment"
          onChange={handleChange}
          value={formData.comment}
          rows={4}
          cols={30}
          maxLength={Constants.FEEDBACK_MAX_CHARS}  // 1000
          autoFocus
        />
      </td>
    </tr>
  </tbody>
</Table>
          <input
            key="100"
            type='submit'
            value='Submit'
            disabled={ formData.comment.trim().length === 0 }
          />
        </form>
        { errorsDoExist && <ErrorList /> }
        <br/>
        <HeadingBuilder
          buttonAction   = { setShowFeedbackFormToFalse }
          headingText    = {t('feedback.feedbackMessage.labelCloseFeedback')}
          // Hide/Pause
          tippyAlertText = {t('feedback.feedbackMessage.tippyContentCloseFeedback')}
          tippyText      = {t('feedback.feedbackMessage.tippyContentCloseFeedback')}
        />
        &nbsp; &nbsp; &nbsp; &nbsp;
        <HeadingBuilder
          buttonAction   = { resetFeedbackForm }
          headingText    = {t('feedback.feedbackMessage.labelResetFeedback')}
          // Erase/Reset
          tippyAlertText = {t('feedback.feedbackMessage.tippyContentResetFeedback')}
          tippyText      = {t('feedback.feedbackMessage.tippyContentResetFeedback')}
        />
        <br/>
        <ClipboardButton
          buttonType='feedback'
          textToCopy={`Topic: ${formData.topic}\nMessage: ${formData.comment}`}
        />
        <hr/>
      </>
      : null
    }
  </article>
  )
 } else {
    return (
      <>
        { showFeedbackForm
          ? <HeadingBuilder
              Size = 'h3'
              headingText = {t('global.notSignedInMessage')}
              // Not Authorized - Please Sign in or Sign up
            />
          : null
        }
      </>
    )
 }
}

export default FeedbackForm;