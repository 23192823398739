import React from 'react';
import { usePhrasing } from "../hooks/useSkuUtils";
import { HeadingBuilder } from "./HeadingBuilder";
import { NumericFormat } from 'react-number-format';
import { tax_multiplier, showOIUpdatedAt } from '../utils';

export const OutlayAmt = ({ trip,
  sku,
  sku_price,
  order,
  showOutlayWithTax,
  decimal_amt,  // should come in as a decimal amt (0.75), not double-digit pct ("75")
}) => {

  const { get_tax_type_suffix } = usePhrasing();

  return (
   <>
    <NumericFormat displayType="text"
      //prefix={order.sku.currency}
      prefix={"$"}

      suffix={get_tax_type_suffix({
        taxType: (
          showOutlayWithTax
            ? (sku.tax_type || 0)  // tax_type of 0 indicates 'No Tax'
            : 'no_show'
        )
      })}

      value= {
        (
          Math.round(
            sku_price
            * (showOutlayWithTax
                ? tax_multiplier({
                    tax_type: (sku.tax_type || 0),
                    trip,
                  })
                : 1
              )
            * parseFloat(decimal_amt) * 100
          ) / 100
        ).toFixed(2).toString() 
      }
    />
    <HeadingBuilder
      tippyInfoText = { showOIUpdatedAt({updated_at: order.updated_at}) }
      // 10-29_ _20:11
    />
   </>
  );
}

export default OutlayAmt;