import React from 'react';
import { Link } from 'react-router-dom'

export const CircleLink = ( {circle} ) => {

  return (
    <Link to={`/circles/${circle.id}`}>
      {circle.identifier}
    </Link>
  )
}

export default CircleLink