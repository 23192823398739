import React from 'react';
import { useStyles } from "../hooks/useStyles";
import { usePhrasing } from "../hooks/useSkuUtils";
import { HeadingBuilder } from "./HeadingBuilder";
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next";
//import Tippy from '@tippyjs/react';
//import 'tippy.js/dist/tippy.css';
export const MoreInfoRePrice = ({
  sku,
  handleMoreInfoClick
}) => {

  const { tableShow, textAlignLeft } = useStyles();
  const { t } = useTranslation();

  const {
    getAudioText, headingPlusPlayAudio,
  } = usePhrasing();

  return (
      <article style={tableShow} >
       <HeadingBuilder
         buttonAction   = {handleMoreInfoClick}
         headingText    = {t('global.moreInfo.hideButtonLabel')}
         // Hide 'More Info'
         tippyAlertText = {t('global.moreInfo.hideButtonTippyContent')}
         tippyText      = {t('global.moreInfo.hideButtonTippyContent')}
         // Click this button to stop showing this message.
       />
       <span style={textAlignLeft}>
        <br/>
        <p>
          {t('price.moreInfo.text010')}
          {/*
          Changing a Price record may affect the price that is used in ShopWeAll for purchases of a previous (or future!) trip.
          The "Price 'AsOf' Date" plays a key role.
          */}
          {
            headingPlusPlayAudio({
              heading: ' ',
              origText: getAudioText({ originalText: t('price.moreInfo.text010') })
            })
          }
        </p>
        <p>
          {t('price.moreInfo.text020')}
          {/*
          Price increases and decreases are a thing of life.
          In order to plan for a trip, and to settle up for the trip once you have gone shopping, we need accurate pricing (as of the time of a trip) for the items on your list.
          Depending on your interest in having accurate pricing history for previous shopping trips, you could:
          */}
          {
            headingPlusPlayAudio({
              heading: ' ',
              origText: getAudioText({ originalText: t('price.moreInfo.text020') })
            })
          }
        </p>
        {/* <ul style={textAlignLeft}> */}
        <ul>
          <li>
            {t('global.label.asterisk') + t('price.moreInfo.text030')}
            {/*
            Ignore past and future price differences and plan to update the same/single Price record for the Sku on behalf of the "current" trip.
            */}
          </li>
          <li>
            {t('global.label.asterisk') + t('price.moreInfo.text040')}
            {/*
            On an ongoing basis, download Shopping Trip reports (CSV files) to provide a record of price changes over time.
            */}
          </li>
          <li>
            {t('global.label.asterisk')}
            {/*
            *
            */}
            <Link to={`/skus/${sku.id}/prices/`}>
              {t('price.moreInfo.textForLink')}
              {/* Create/add a new Price record  */}
            </Link>
            {t('price.moreInfo.text060')}
            {/*
            for the Sku to document/use a new price or discount.
            This will add another Price data point in ShopWeAll for the Sku "AsOf" a certain date.
            */}

          {
            headingPlusPlayAudio({
              heading: ' ',
              origText: getAudioText({originalText: 
                  t('price.moreInfo.text030')
                + ' // '
                + t('price.moreInfo.text040')
                + ' // '
                + t('price.moreInfo.textForLink')
                + t('price.moreInfo.text060')
              })
            })
          }
          </li>
        </ul>
        <p>
          {t('price.moreInfo.text070')}
          {/*
          When you plan a shopping trip, ShopWeAll determines what price to use for a given Sku by finding the most recent Price record for that Sku whose effective date is before the trip's shopping date.
          (The most recent Price date without going over.)
          For future trips, maybe you know about an upcoming price change, and you create a new Price record which will take effect on a certain date.
          That Price record won't be used for a current trip because its "effective date" is still in the future.
          */}
          {
            headingPlusPlayAudio({
              heading: ' ',
              origText: getAudioText({ originalText: t('price.moreInfo.text070') })
            })
          }
        </p>
        <p>
          {t('price.moreInfo.text080')}
          {/*
          Looking back, this Sku may have experienced a series of price changes over time.
          If you created new Price records for each change in price, you will have a history of those changes.
          Not only that, but trips from the past that are still accessible to you (i.e. the ones you haven't deleted) will be presented using pricing for the Sku that was effective at the time of the trip.
          If you simply update the price for a Price record whose "Price 'AsOf' Date" was months or years ago, that new price will be used for your current purchase, but it will also become the price associated with purchases that happened long ago after that same "AsOf" date.
          */}
          {
            headingPlusPlayAudio({
              heading: ' ',
              origText: getAudioText({ originalText: t('price.moreInfo.text080') })
            })
          }
        </p>
        <p>
          {t('price.moreInfo.text090')}
          {t('ordersList.button_text.downloadReport')}
          {t('price.moreInfo.text100')}
          {/*
          If history doesn't matter to you, then no big deal.
          Or if you saved a copy of the trip report back when the price was its former price (i.e. you clicked the 'Download details to File' button when you settled up the trip), then your report will have locked in a record of what the pricing was on that date.
          Maybe that suits your needs?
          */}
          {
            headingPlusPlayAudio({
              heading: ' ',
              origText: getAudioText({originalText: 
                  t('price.moreInfo.text090')
                + t('ordersList.button_text.downloadReport')
                + t('price.moreInfo.text100')
              })
            })
          }
        </p>
        <p>
          {t('price.moreInfo.text110')}
          {/*
          Also, if you are creating "new" trips by re-using past trips, which will have the effect of dragging along the Skus and Prices used in that last trip, then it's handy to simply reprice any skus and count on downloading CSV files to preserve any desired history.
          */}
          {
            headingPlusPlayAudio({
              heading: ' ',
              origText: getAudioText({ originalText: t('price.moreInfo.text110') })
            })
          }
        </p>
       </span>
       <HeadingBuilder
         buttonAction   = {handleMoreInfoClick}
         headingText    = {t('global.moreInfo.hideButtonLabel')}
         // Hide 'More Info'
         tippyAlertText = {t('global.moreInfo.hideButtonTippyContent')}
         tippyText      = {t('global.moreInfo.hideButtonTippyContent')}
         // Click this button to stop showing this message.
       />
      </article>
  )
}

export default MoreInfoRePrice;