import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  html, body {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Nunito Sans', sans-serif;
    position: relative;
    /* top: 300px;  // effect? Seems to push navbar down by 300px - for what? */
    /* background: #ff9c00;  // an apricot-like color */
}`;
