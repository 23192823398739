import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
// import { ShopperContext } from '../context/shopper';
import * as Constants from '../constants'
import { useLookups, usePricing, usePhrasing, useFormats } from "../hooks/useSkuUtils";
import { useStyles } from "../hooks/useStyles";
import { HeadingBuilder } from "./HeadingBuilder";
       
import { NumericFormat } from 'react-number-format';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { useTranslation } from "react-i18next";

import styled from 'styled-components';
const Button = styled.button`
  /* Adapt the colors based on primary prop */
  background: ${props => props.primary ? Constants.O_BOX_GREEN : Constants.O_BOX_PINK};
  border: none;
  &.hover {
    background: #e7e7e7; color: black;
    transition: 0.3s;
  }
  /* color: ${props => props.primary ? "white" : "palevioletred"};

  font-size: 1em;
  margin: 1em;
  padding: 0.25em 1em;
  border: 2px solid palevioletred;
  border-radius: 3px; */
`;

export const SkuFoundRow = ({ circle_id,
                            otherSku,
                            showIdentifier,
                            showPostalCode,
                            showUnitCost,
                            showSkuDetailsColumn
                           }) => {
  console.log("sfr otherSkuA", circle_id, otherSku);

  // const { shopper, prices, orderItems, showOutlayWithTax, changeOrderItem, showOthersOrders } = useContext(ShopperContext);

  //console.log("pricesX", prices)
  const { t } = useTranslation();
  const { getUnitCost } = usePricing();
  const { tableOrderItem } = useStyles();
  const { weightAndUnitsOfSku, noDotZero, getOColTippyContent } = usePhrasing();
    
  const { lookup_category } = useLookups();
  const { checkDecimalInput } = useFormats();

  const isDiscrete =  otherSku.type == "DiscreteItem" ? true : false;
  const isGranular =  otherSku.type == "GranularItem" ? true : false;
  const isWeighty  =  otherSku.type == "WeightyItem" ? true : false;

  const [plusButtonIsGreen, setPlusButtonIsGreen] = useState(true);

  const o_colTippyText      = getOColTippyContent({ isForTextNotAudio: true });
  const o_colAudioTippyText = getOColTippyContent({ isForTextNotAudio: false });

  const otherPriceFloat = 
    parseFloat(otherSku.shared_price)
      -  (parseFloat(otherSku.shared_discount_amount) || 0);

  const otherPrice = '$' + otherPriceFloat.toFixed(2).toString();
  // $7.00

  const otherPriceTippyText =
    t('sku.price_date.searchLabel') + ' ' + otherSku.shared_price_date;
    // Price AsOf: 2024-05-21

  const otherPriceTippyInfoText =
    otherSku.shared_discount_amount > 0
      ? t('price.colHeader.normalPrice') + ": $" +
          parseFloat(otherSku.shared_price).toFixed(2).toString() + ' // ' +
          // "Normal price: $8.00 // "
        t('price.colHeader.discount') + ": $" +
          parseFloat(otherSku.shared_discount_amount).toFixed(2).toString() + ' // ' +
          // "Discount: $1.00 // "
        t('price.colHeader.discEnds') + ": " + otherSku.shared_discount_end_date
          // "Disc ends: 2024-05-30"
      : '';

  // checkbox advice per https://www.robinwieruch.de/react-checkbox/
  // button advice per https://www.robinwieruch.de/react-button/
  // https://upmostly.com/tutorials/pass-a-parameter-through-onclick-in-react
  return (
    <tr style={tableOrderItem}>

      {/* "O" button: */}
      <td>
        <HeadingBuilder
          tippyAlertText = {o_colTippyText}
          tippyAlertAudio= {o_colAudioTippyText}
          tippyText      = {o_colTippyText}
          blueLinkUrl    = {`/circles/${circle_id}/skus/${otherSku.uuuid}`}
          headingText    = {Constants.O_COL_SYM}
          // Click the O sign to choose that row's search results as the starting material for adding a new Sku to your Circle. Your Sku will need a Price and 'As Of' date for the Price.
        />
      </td>
      {/* Barcode */}
      <td>
        { showIdentifier
          ?
            otherSku.identifier.length > Constants.MAX_SKU_IDENTIFIER_CHARS_BEFORE_TIPPY
              ? < HeadingBuilder
                  containerType  = 'ellipsis fw-bold'
                  headingText    = {otherSku.identifier}
                  tippyAlertText = {otherSku.identifier}
                  tippyText      = {otherSku.identifier}
                />
              : <b>{otherSku.identifier}</b>
              
          : < HeadingBuilder
              headingText    = {t('ordersList.id_col.dataLabel')}
              // id
              tippyAlertText = {otherSku.identifier}
              tippyText      = {otherSku.identifier}
              forceShowAlertText = '1'
            />
        }
      </td>
      {/* Zip */}
      <td>
        { showPostalCode
          ?
            < HeadingBuilder
              // containerType  = 'ellipsis fw-bold'
              headingText    = {'-' + otherSku.shared_circle_postal_code}
              // -99999
              tippyInfoText  = {otherSku.shared_circle_identifier}
            />
              
          : < HeadingBuilder
              headingText    = {t('global.label.pc')}
              // z
              tippyAlertText = {otherSku.shared_circle_postal_code}
              tippyText      = {otherSku.shared_circle_postal_code}
              tippyInfoText  = {otherSku.shared_circle_identifier}
              forceShowAlertText = '1'
            />
        }
      </td>
      <td>{otherSku.brand}</td>
      <td>
        <HeadingBuilder
          containerType  = 'container-left'
          headingText    = {
            otherSku.label +
            weightAndUnitsOfSku({
              sku: otherSku,
              showWeightAsSkuEditLink: false,
              showUnitCount: true,
              showQtyUnits: false
            })
          }
          tippyText      = { lookup_category({category: otherSku.category}) }
          tippyInfoText  = { lookup_category({category: otherSku.category}) }
          // e.g.: Milk, 1%, organic (qty 3) (!)
          // where 'tippyText' shows as 'Dairy'
          // After the label we'll show unit counts for discrete items and weight info for non-discrete items
        />
      </td>
      <td>{otherSku.store_label}</td>
      <td>
        {noDotZero(
          {num:  otherSku.quantity === 0  ? '' : otherSku.quantity }
                    )} {otherSku.units}
      </td>
      <td>
        <HeadingBuilder
          containerType  = 'container-left'
          headingText    = {otherPrice}
          // $7.00
          tippyAlertText = {otherPriceTippyText}
          tippyText      = {otherPriceTippyText}
          tippyInfoText  = {otherPriceTippyInfoText}
        />
        &nbsp;
      </td>
      {
        showUnitCost
          ? <td>
              ${ 
                getUnitCost ({
                  sku: otherSku,
                  sku_price: otherPriceFloat
                })
              }
              /{otherSku.units.replace(/[(]s[)]$/, '')}
            </td>
          : null
      }
      <td>
        { showSkuDetailsColumn
          ? otherSku.detail
          : null
        }
      </td>
    </tr>
  )
}

export default SkuFoundRow;
