import React, { useContext } from 'react';
import { ShopperContext } from '../context/shopper';
import { useUtils } from "../hooks/useUtils";
import { useStyles } from "../hooks/useStyles";
import HeadingBuilder from './HeadingBuilder';
import CircleRow from './CircleRow'
import Table from 'react-bootstrap/Table';
import { useTranslation } from "react-i18next";

const CircleList = ( {circles, leaderBased = false} ) => {
  console.log('CL.js circles: ', circles);
  const { shopper, circleShoppers } = useContext(ShopperContext);
  const {
    s_by_identifier_postal_code,
    is_shopper_admin_of_some_circle
  } = useUtils();

  const { tableShowNarrow, textAlignLeft } = useStyles();
  const { t } = useTranslation();

  console.log('Circcircles', circles);
  const circlesList = circles
    .sort(s_by_identifier_postal_code)
    .map(circle => {
      console.log('c_before', circle);
      const c = {...circle};
      console.log('c_after', c);
      return <CircleRow key={c.id} circle = { c } />
  })
  console.log('CL.js CircleList: ', circlesList);

  const adminOrLeaderAsteriskMsg =
    leaderBased
      ? t('circle.leaderAsterisk.tippyContent')
      : t('circle.admin_asterisk.tippyContent');

  return (
   <>
    <Table responsive striped bordered hover size="sm" style={tableShowNarrow}>
      <thead >
        <tr style={textAlignLeft}>
          <th>
            {t('circle.identifier.label')}
          </th>
          <th>
            <HeadingBuilder
              Size           = 'fw-bold'
              headingText    = {t('circle.postal_code.label')}
              // Zip Code
              tippyAlertText = {t('circle.postal_code.quickTippyContent')}
              tippyText      = {t('circle.postal_code.quickTippyContent')}
              // Zip Code indicates where this Shopping Circle is active.
            />
          </th>
        </tr>
      </thead>
      <tbody style={textAlignLeft} >
        {circlesList}
      </tbody>
    </Table>

    {
      is_shopper_admin_of_some_circle({
        cShoppers: circleShoppers, shopper_id: shopper.id
      })
        ? <HeadingBuilder
            Size = 'h6'
            // headingText = {t('circle.admin_asterisk.tippyContent')}
            headingText = {adminOrLeaderAsteriskMsg}
            // The * indicates 'admin' authority for this circle
            // or
            // The * indicates 'leader' authority for this circle
          />
        : null
    }
   </>
  )
}

export default CircleList;
