import React from 'react';
// import React, { useContext } from 'react';
// import { ShopperContext } from '../context/shopper';
import { useUtils } from "../hooks/useUtils";
import { useLookups } from "../hooks/useSkuUtils";
import HeadingBuilder from './HeadingBuilder';
import { useTranslation } from "react-i18next";

export const InviteeLink = ( {
    showRemoveInvitationColumn,
    if_shopper_is_circle_admin,
    if_shopper_is_circle_leader,
    invite_id,
    circle_id,
    invitee,
    flavor,
    created_at
  } ) => {

  // const { } = useContext(ShopperContext);
  const { t } = useTranslation();
  const { toMmmDdYyyy } = useUtils();
  const { lookup_flavor_label } = useLookups();

  console.log('invitee: ', invitee)
  console.log('flavor: ', flavor)
  console.log('invite_id: ', invite_id)
  console.log('created_at: ', created_at)

  return (
    <tr>
      <td>
        {invitee}
      </td>
      <td>
        { toMmmDdYyyy({dateString: created_at}) }
      </td>
      <td>
        {
          lookup_flavor_label({flavor: flavor})
          // 'Join Our Circle' or 'Become Leader'
        }
      </td>
      <td>
        {
          // circle admins can cancel a 'join our circle' invitation.
          // circle leaders can cancel a 'become leader' invitation.
          (
           showRemoveInvitationColumn && (
            (flavor === 'circle_wants_shopper'     && if_shopper_is_circle_admin)
              ||
            (flavor === 'leader_wants_new_leader'  && if_shopper_is_circle_leader)
           )
          )
            ? <HeadingBuilder
                headingText    = {t('global.label.cancel')}
                // Cancel
                linkUrl        = {`/circles/${circle_id}/invitations/${invite_id}/delete`}
                tippyAlertText = {t('invitation.cancelInvitation.tippyContent')}
                tippyText      = {t('invitation.cancelInvitation.tippyContent')}
                // Click to cancel the Invitation
              />
            : null
        }
      </td>
    </tr>
  );
}

export default InviteeLink;