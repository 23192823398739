import React, { useContext, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { ShopperContext } from '../context/shopper';
import { useUtils } from "../hooks/useUtils";
import { HeadingBuilder } from "../components/HeadingBuilder";
import ShoppingList from "./ShoppingList";
import SkuMenu from "./SkuMenu";
import * as Constants from '../constants';

const Trip = () => {
  const { signedIn, shopper, circleShoppers,
          trips,
          showShoppingList, setShowShoppingListToTrue,
          setLastTripSeenId,
        } = useContext(ShopperContext);
  console.log('Trip.js A trips-orig: ', trips)
  // Getting this error message after successfully changing a sku's
  // typical_weight. We go back to the "Your Shopping List" table at
  // localhost:4000/trips/28 but the summary/short description is not
  // showing the updated typical_weight. If I click the browser's
  // 'reload' button, the web app screen goes blank and we see this:
  // Trip.js:68 Uncaught TypeError: Cannot read properties of undefined (reading 'id')
  //   at Trip (Trip.js:68:1)
  // because there are no 'trips' for this 'find' to process:
  // const trip = trips.find((trip) => ... )
  //
  // I insert a command to do
  // if (trips.length === 0) {
  //   navigate(`/trips/`) }
  //
  // and it navigates to the /trips page (with updated state values ready
  // to show when we do /trips/28, but these errors also show in console:

  // react-dom.development.js:86 Warning: Cannot update a component (`BrowserRouter`) while rendering a different component (`Trip`). To locate the bad setState() call inside `Trip`, follow the stack trace as described in https://reactjs.org/link/setstate-in-render
  // at Trip (http://localhost:4000/static/js/bundle.js:9420:56)

  // FIX ME: is there a way to reset 'trips' if 'trips' is empty?
  // That is, we are losing all context/state.
  // Doing a refresh of the tab after going back to /trips rebuilds context,
  // but can we trigger that refresh preemptively if context's 'trips' is empty?

  // An attempt at resetting 'trips':
  //const { signedIn, trips, callFetchTrips } = useContext(ShopperContext);
  //const { signedIn, callFetchTrips } = useContext(ShopperContext);
  //let { trips } = useContext(ShopperContext);
  // if (trips.length === 0) {
  //   callFetchTrips();
  //   trips = useContext(ShopperContext);
  //   console.log('Trip.js B trips-orig: ', trips)
  // }

  const {id} = useParams();
  console.log('Trip.js id: ', id)
  const { t } = useTranslation();

  // Update state and localStorage with the id of this latest trip viewed.
  useEffect(() => {
    setShowShoppingListToTrue();
    setLastTripSeenId(id);

    // Clobber the LAST_TRIP_SEEN value in localStorage for this shopper with
    // the id. The key is a composite key formed by a basename and shopperId.
    const localStorageKey = Constants.LAST_TRIP_SEEN_FOR + shopper.id;
    window.localStorage.setItem(localStorageKey, JSON.stringify(id));
  }, [setShowShoppingListToTrue, setLastTripSeenId, id, shopper]);

  const { toMmmDdYyyy, toHmmA, is_shopper_a_circle_admin } = useUtils();

  const navigate = useNavigate();
  if (trips.length === 0) {
    //navigate(`/trips/${id}`)
    // Ick: feels like this could lead to infinite loop.
    // If 'trips' has no elements now, seems possible there won't be elements
    // if we navigate again to `/trips/${id}`, which could run Trip again,
    // and again...
    // So we can at least allow the /trips/28 page to refresh /trips automatically:
    navigate(`/trips/`)
    // This is progress. At least something comprehensible happens upon pressing
    // a browser reload icon.

    // 220522: TODO, handle this: Getting this warning:
    // You should call navigate() in a React.useEffect(), not when your component is first rendered. 
    // at Trip (http://localhost:4000/static/js/bundle.js:18864:56)
    // at Routes (http://localhost:4000/static/js/bundle.js:80179:5)
    // at div
    // at div
    // at ShopperProvider (http://localhost:4000/main.c02cf9ca36a7852bf7df.hot-update.js:89:5)
    // at Suspense ...
  }

  if (signedIn) {

  // load "trip" with the trip that matches the id param from url
  // Used cloning before (?) for circles to make clean (?) copy of circle.
  const trip = trips.find((trip) => {
    console.log('find trip: ', trip)
    console.log('find trip.id: ', trip.id)
    console.log('find trip boolean: ', trip.id == id)
    return trip.id == id  // TODO: get warning for '==', but '===' fails - quiesce?
  }) || {} ;

  console.log('find trip2: ', trip)

  const if_shopper_is_circle_admin = is_shopper_a_circle_admin({
    cShoppers: circleShoppers, circle_id: trip.circle.id, shopper_id: shopper.id
  });
  console.log('in trip, if_shopper_is_circle_admin: ', if_shopper_is_circle_admin)

  // present that trip's order_info; circle admin sees button allowing _______
  return (
    <>
      <article>
      
      <b>{t('trip.formPart.shoppingTripPrompt')}</b>
      {/* Shopping Trip: */}
        &nbsp;{ toMmmDdYyyy({dateString: trip.day, weekday: 'short'}) },
        &nbsp;{ toHmmA({timeString: trip.hour}) },
        &nbsp;{ trip.label }
      <br />
      <HeadingBuilder
        tippyAlertText = {t('trip.circlePrompt.tippyContent')}
        tippyText      = {t('trip.circlePrompt.tippyContent')}
        linkUrl        = {`/circles/${trip.circle.id}`}
        headingText    = {t('trip.circlePrompt.label')}
        value          = {trip.circle.identifier}
                       // Circle: am_circle
      />
      <br />
      { if_shopper_is_circle_admin &&
        <>
          <HeadingBuilder
            tippyAlertText = {t('trip.editTripSettingsButton.tippyContent')}
            tippyText      = {t('trip.editTripSettingsButton.tippyContent')}
            linkUrl        = {`/trips/${trip.id}/edit`}
            headingText    = {t('trip.editTripSettingsButton.label')}
                           // Edit Trip Settings (requires admin)
          />
          { trip.is_hidden &&
            <HeadingBuilder
              headingText = {t('trip.editTripSettingsButton.tripHiddenHeading')}
              // Note: Trip is Hidden. To unhide, click 'Edit Trip Settings'.
            />
          }
        </>
      }
      {if_shopper_is_circle_admin && <br/> }
      {if_shopper_is_circle_admin &&
          <HeadingBuilder
            tippyAlertText = {t('trip.deleteTripButton.tippyContent')}
            tippyText      = {t('trip.deleteTripButton.tippyContent')}
            linkUrl        = {`/trips/${trip.id}/delete`}
            headingText    = {t('trip.deleteTripButton.label')}
                           // Delete Trip (requires 'admin')
          />
      }
      {if_shopper_is_circle_admin && <br/> }
      </article>
      <hr />
      { showShoppingList
        ? <ShoppingList trip={trip}/>
        : <SkuMenu      trip={trip}/>
      }
        {/* clicking a '+' adds a zero-amt order to order_items for this sku, shopper, trip */}
    </>
  )

  } else {
    return (
      <HeadingBuilder
        Size = 'h3'
        headingText = {t('global.notSignedInMessage')}
        // Not Authorized - Please Sign in or Sign up
      />
    )
  }

}
export default Trip