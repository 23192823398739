//import cloneDeep from 'lodash/cloneDeep';
// import _ from 'lodash';

import React, { useContext } from 'react'
import { useStyles } from "../hooks/useStyles";
import { ShopperContext } from '../context/shopper';
import { useTranslation } from "react-i18next";
import styles from '../css/trTdFirstChild.module.css';
import Table from 'react-bootstrap/Table';
import * as Constants from '../constants'
import HeadingBuilder from './HeadingBuilder';

const CircleTable = ( {handleClick, circle, shopperNames, showDetails} ) => {
  const { signedIn } = useContext(ShopperContext);

  const { tableShow, tableData } = useStyles();
  const { t } = useTranslation();

  const nameString = shopperNames  ?  shopperNames.join(', ')  :  Constants.DONTSHOW;

  if (signedIn) {
    console.log('find circle2: ', circle)

// admin_count: null
// billee_id: 6
// city: null
// country: "US"
// county: null
// detail: null
// id: 9
// identifier: "x3"
// img_url: null
// label: "x4"
// leader_id: 6
// plan: {id: 1, identifier: 'free00', label: 'Free plan - testing, 220819', detail: null, max_shoppers: null, …}

    return (
     <>
      <Table responsive striped bordered hover size="sm" style={tableShow}>
        <thead>
        </thead>
        <tbody className={styles.trTdFC} style={tableData} >
          <tr>
            <td>
              {/* Shopping Circle ID */}
              {t('circle.identifier.label')}
            </td>
            <td>
              {circle.identifier}
            </td>
          </tr>
          <tr>
            <td>
              {/* Description */}
              {t('circle.label.label')}
            </td>
            <td>
              {circle.label}
            </td>
          </tr>
          { nameString == Constants.DONTSHOW
            ? null
            : <tr>
              <td>
                <HeadingBuilder
                  tippyAlertText = {t('circle.name_string.tippyContent')}
                  tippyText      = {t('circle.name_string.tippyContent')}
                  headingText    = {t('circle.name_string.label')}
                                 // Circle Members
                />
              </td>
              <td>
                {nameString}
              </td>
            </tr>
          }
          { showDetails
            ? <tr>
                <td>
                  {/* Sales Tax Rate */}
                  {t('circle.tax_rate.label')}
                </td>
                <td>
                  {circle.tax_rate ? <span>{`${circle.tax_rate}`}</span> : <span>0</span>}%
                </td>
              </tr>
            : null
          }
          { showDetails
            ? <tr>
                <td>
                  {/* Food Sales Tax Rate */}
                  {t('circle.tax_rate_food.label')}
                </td>
                <td>
                  {circle.tax_rate_food ? <span>{`${circle.tax_rate_food}`}</span> : <span>0</span>}%
                </td>
              </tr>
            : null
          }
        </tbody>
      </Table>
      
      <button onClick={handleClick}>{t('circle.minimizeDetailsButton.label')}</button>
        {/* Minimize 'Circle' details */}
     </>
    )
  } else {
    return (
      <HeadingBuilder
        Size = 'h3'
        headingText = {t('global.notSignedInMessage')}
        // Not Authorized - Please Sign in or Sign up
      />
    )
  }
}
export default CircleTable