import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ShopperContext } from '../context/shopper';
import { useForm } from "../hooks/useForm";
import { useStyles } from "../hooks/useStyles";
import { useLookups } from "../hooks/useSkuUtils";
import Table from 'react-bootstrap/Table';
import HeadingBuilder from './HeadingBuilder';
import { ErrorList } from './ErrorList';
import { BackButton } from './BackButton';
import styles from '../css/trTdFirstChild.module.css';
import { useTranslation } from "react-i18next";

const InvitationForm = ({
  inviteType = 0,
  setJustMadeInvite,
  passInvitee,
  circle_id, circle_identifier, lightUpAddInvitationButton}) => {

  const { signedIn, addInvitation } = useContext(ShopperContext);
  const { errorsDoExist, dismissErrorsList, setErrorsDoExistToFalse } = useContext(ShopperContext);
  const { setJustGotClearToFalse } = useContext(ShopperContext);

  const { tableShow, tableData } = useStyles();
  const { lookup_flavor_action,
          lookup_flavor_headline_tippy,
          lookup_flavor_invitation_tippy
        } = useLookups();

  const navigate = useNavigate();
  const { t } = useTranslation();

  const initialState = {
    invitee: '',
    flavor: inviteType,
    status: 0,
    circle_id: circle_id,
  };
  //const { formData, handleChange, reset, editFormValues } = useForm(initialState);
  const { formData, handleChange } = useForm(initialState);

 if (signedIn) {
  const handleSubmit = (e) => {
    e.preventDefault();

    dismissErrorsList();
    setErrorsDoExistToFalse();
    setJustGotClearToFalse();

    // modeled on Brian Jenney's "The Javascript I Wish I Learned As a Junior Developer"
    // The promise handles this problem:
    // Screen removes form immediately, shows plain CircleShoppers page if an
    // error such as duplicate entry. This error (dupe entry) still hanging
    // around. It presents if click Alter button. Need to show error on the
    // basic page, with ability to dismiss.
    const myPromise = () => {
      return new Promise((resolve, reject) => {
        // This positions
        // 'addInvitation' to run as a promise, with chainable aftereffects.
        // A promise will contain 'addInvitation' so that its eventual value
        // for state variable errorsDoExist will be available, allow doing
        // lightUpAddInvitationButton() conditionally.

        console.log("errorsDoExistZ", errorsDoExist);
        const result = addInvitation(formData);
        console.log("new Promise result", result);
        console.log("errorsDoExist0", errorsDoExist);
        resolve('Success!');
      })
    }

    console.log("errorsDoExistA", errorsDoExist);
    myPromise()
      .then(() => {
        console.log("errorsDoExistB", errorsDoExist);
        if (errorsDoExist) {
          console.log("errorsDoExistC", errorsDoExist);
          // keep showing the form and its error message
        } else {
          console.log("errorsDoExistD", errorsDoExist);
          lightUpAddInvitationButton();
          setJustMadeInvite(true);
          passInvitee(formData.invitee);
          // With the 'Outstanding Invitations' showing on this same page,
          // it's important that that area start showing the new invitation
          // immediately.
          // The addInvitation method in shopper.js now calls fetchCircles
          // which rebuilds the circle's open_invites state. This shows
          // the new invitation.
        }
        console.log('done edo')

        // Return to the /circles/:id page after issuing an invitation.
        // That's where any error messages will show up.
        // Also, it skips past the 'Delete Circle' view if this was an
        // invitation for becoming the new leader. (Avoid accidental clicks.)
        navigate('/circles/' + circle_id);
      })
      .catch((error) => console.error(error));
      // the 'catch' is per https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Using_promises
      // Always end the promise chain with a catch handler to avoid any unhandled rejections!
      // FIX ME: But what does this 'catch' expose us to? Is this ok?
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise says:
      // A .catch() is really just a .then() without a slot for a callback
      // function for the case when the promise is fulfilled.
  }


  const headingText = t('invitation.flavor.actionInviteShopper')
                    + lookup_flavor_action({flavor: inviteType});
  const headingTippyText = lookup_flavor_headline_tippy({flavor: inviteType});
  const usernameTippyText = t('invitation.addShopperIdentifier.tippyContent')
                          + lookup_flavor_invitation_tippy({flavor: inviteType});
  const validUsernameTippyText =
    inviteType == 0
      ? t('invitation.addShopperIdentifier.tippyContentOnlyForCurrent')
      : '';
  
  return (
      <article>
        <br/>
        <HeadingBuilder
          Size = 'h5'
          headingText = {headingText}
          //Invite Shopper to (join|become Leader of) Circle
          tippyAlertText = {headingTippyText}
          tippyText      = {headingTippyText}
          value = {circle_identifier}
        />
        {/* <br/> */}
        <form onSubmit={handleSubmit}>
<Table responsive striped bordered hover size="sm" style={tableShow}>
  <thead >
  </thead>
  <tbody className={styles.trTdFC} style={tableData} >
    <tr>
      <td>
        <HeadingBuilder
          containerType='container-input-label'
          headingText = {t('invitation.addShopperIdentifier.label')}
          // Shopper's Username
          labelHtmlFor = "invitee"
          tippyAlertText = {usernameTippyText}
          tippyText      = {usernameTippyText}
          tippyInfoText  = {validUsernameTippyText}
        />
      </td>
      <td>
        <input
          key="30"
          type="text"
          id="invitee"
          name="invitee"
          // autoComplete="username"
          onChange={handleChange}
          value={formData.invitee}
          autoFocus
        />
        &nbsp;*
      </td>
    </tr>
  </tbody>
</Table>
          { errorsDoExist && <ErrorList /> }
          <input
            key="100"
            type='submit'
            value='Submit'
            disabled={ formData.invitee.trim().length === 0 }
          />
        </form>
        <BackButton />
      </article>
  )
 } else {
    return (
      <HeadingBuilder
        Size = 'h3'
        headingText = {t('global.notSignedInMessage')}
        // Not Authorized - Please Sign in or Sign up
      />
    )
 }
}

export default InvitationForm;