import React from 'react';
import { useTranslation } from "react-i18next";
import { HeadingBuilder } from "./HeadingBuilder";

const SkuMenuFilters = ({
  handleCategoryChange,
  handleBrandChange,
  handleSummaryChange,
  initializeFilters
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <HeadingBuilder
        containerType  = 'h6'
        Size           = 'strong'
        headingText    = {t('sku.category.filterBy')}
        // Filter by:
        tippyAlertText = {t('sku.category.filterByTippyContent')}
        tippyText      = {t('sku.category.filterByTippyContent')}
      />
      {/* <br/> */}
      {t('sku.category.label')}&nbsp;
      {/* Category */}
        <select name="filter" onChange={handleCategoryChange}>
          <option value="">{t('sku.category.showAll')}</option>
          {/* Show all */}
          <option value="0" >{ t('sku.category.optionValue0')  }</option>  {/* // 'Other' */}
          <option value="1" >{ t('sku.category.optionValue1')  }</option>  {/* 'Fruits' */}
          <option value="2" >{ t('sku.category.optionValue2')  }</option>  {/* "Vegetables", */}
          <option value="3" >{ t('sku.category.optionValue3')  }</option>  {/* "Dairy", */}
          <option value="4" >{ t('sku.category.optionValue4')  }</option>  {/* "Bread, Bakery, Dessert", */}
          <option value="5" >{ t('sku.category.optionValue5')  }</option>  {/* "Baking", */}
          <option value="6" >{ t('sku.category.optionValue6')  }</option>  {/* "Meat, Fish", */}
          <option value="7" >{ t('sku.category.optionValue7')  }</option>  {/* "Meat Alternatives", */}
          <option value="8" >{ t('sku.category.optionValue8')  }</option>  {/* "Deli", */}
          <option value="9" >{ t('sku.category.optionValue9')  }</option>  {/* "Cans, Jars", */}
          <option value="10">{ t('sku.category.optionValue10') }</option>  {/* "Pasta, Rice, Cereal", */}
          <option value="11">{ t('sku.category.optionValue11') }</option>  {/* "Sauces, Condiments", */}
          <option value="12">{ t('sku.category.optionValue12') }</option>  {/* "Herbs, Spices", */}
          <option value="13">{ t('sku.category.optionValue13') }</option>  {/* "Frozen Foods", */}
          <option value="14">{ t('sku.category.optionValue14') }</option>  {/* "Snacks", */}
          <option value="15">{ t('sku.category.optionValue15') }</option>  {/* "Drinks", */}
          <option value="16">{ t('sku.category.optionValue16') }</option>  {/* "Household, Cleaning", */}
          <option value="17">{ t('sku.category.optionValue17') }</option>  {/* "Personal Care, Beauty", */}
          <option value="18">{ t('sku.category.optionValue18') }</option>  {/* "Health, Vitamins", */}
          <option value="19">{ t('sku.category.optionValue19') }</option>  {/* "Baby products", */}
          <option value="20">{ t('sku.category.optionValue20') }</option>  {/* "Pet Care", */}
          <option value="21">{ t('sku.category.optionValue21') }</option>  {/* "Office Supplies", */}
          <option value="22">{ t('sku.category.optionValue22') }</option>  {/* "Clothing", */}
          <option value="23">{ t('sku.category.optionValue23') }</option>  {/* "Automotive", */}
          <option value="24">{ t('sku.category.optionValue24') }</option>  {/* "Electronics", */}
          <option value="25">{ t('sku.category.optionValue25') }</option>  {/* "Patio, Lawn, Garden" */}
        </select>   
      <br/>
      
      {t('global.label.brand')}&nbsp;
      {/* Brand */}
        <input onChange={handleBrandChange} placeholder={t('sku.category.enterBrand')}/>
        {/* Enter Brand */}
      <br/>

      {t('global.label.summary')}&nbsp;
      {/* Summary */}
        <input onChange={handleSummaryChange} placeholder={t('sku.category.enterSummary')}/>
        {/* Enter Summary */}
      <br/>

      <button onClick={initializeFilters}>{t('sku.category.buttonText')}</button>
      {/* Hide &amp; Reset Filters */}
    </div>
  )
}

export default SkuMenuFilters;