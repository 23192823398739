// Don't apply 'HeadingBuilder' for this component's 'Tippy contents' (table data)
import React, { useState, useEffect, useContext } from 'react';
import Table from 'react-bootstrap/Table';
import { ShopperContext } from '../context/shopper';
import HeadingBuilder from '../components/HeadingBuilder';
import * as Constants from '../constants'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components';

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { useTranslation } from "react-i18next";

// TODO: slapdash copy of Button from OrderItem.js - can tidy this
const Button = styled.button`
  /* Adapt the colors based on primary prop */
  background: ${Constants.O_BOX_GREEN};
  border: none;
  &.hover {
    background: #e7e7e7; color: black;
    transition: 0.3s;
  }
  /* color: ${props => props.primary ? "white" : "palevioletred"};

  font-size: 1em;
  margin: 1em;
  padding: 0.25em 1em;
  border: 2px solid palevioletred;
  border-radius: 3px; */
`;

function string_has_chars(str) {
  if (str) {
    return true
  }
  return false
}

// FIX ME: clean up this file now that problem with 'string_has_chars' has been identified/solved
export const CircleShopperRow = ( {
    cs,
    // if_shopper_is_circle_admin,
    // admin_id,
    cShopper,  // has attribute info associated with cs.shopper_id, arrives as JSON
    circle_id,
    circle_identifier,
    circle_leader_id,
    if_shopper_is_circle_leader,
    showRemoveShopperColumn,
    invitation
  } ) => {


  const { shopper, editCircleShopper, deleteCircleShopper,
    fetchCircleOrders,
    deleteInvitation, orderItems, deleteOrderItems } = useContext(ShopperContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  console.log('if_shopper_is_circle_leader: ', if_shopper_is_circle_leader)
  console.log('cs_is_admin: ', cs.shopper_is_admin)
  const admin_status = cs.shopper_is_admin ? 'yes' : 'no';
  console.log('admin_status: ', admin_status)

  const cs_is_leader =  cs.shopper_id == circle_leader_id;
  const leader_status =
    cs_is_leader ? t('circleShopper.leader.label') : null;
    // leader

  const admin_leader_status =  leader_status ? leader_status : admin_status;

  const cs_is_shopper =  cs.shopper_id === shopper.id;

  const removeShopperButtonLabel =
    cs_is_shopper
      ? t('circleShopper.leaveCircle.buttonLabel')
      : t('global.label.remove');

  const removeShopperButtonTippyText =
    cs_is_shopper
      ? t('circleShopper.leaveCircle.tippyContent1') +
        t('global.removeWarning') +
        t('global.clickHere')
      : t('circleShopper.removeShopper.qRemoveShopperFromCircleTippyText') +
        t('global.removeWarning') +
        t('circleShopper.removeShopper.buttonTippyContent') +
        t('circleShopper.removeShopper.tippyContent2');

  const yesRemoveShopperButtonLabel =
    cs_is_shopper
      ? t('circleShopper.leaveCircle.yesButtonLabel')
      : t('circleShopper.removeShopper.yesButtonLabel');

  const yesRemoveShopperButtonTippyText =
    cs_is_shopper
      ? t('circleShopper.leaveCircle.yesTippyContent1') +
        t('circleShopper.leaveCircle.tippyContent2')
      : t('circleShopper.removeShopper.yesTippyContent1') +
        t('circleShopper.removeShopper.tippyContent2');

  // console.log('cs record: ', cs)
  console.log('cShopper: ', cShopper)
  const cShopper_obj = JSON.parse(cShopper);
  console.log('cShopper_obj: ', cShopper_obj);

  console.log('cs shopper id: ', cs.shopper_id)
  const identifier = cShopper_obj.identifier;
  console.log('identifier: ', identifier);
  const firstname = cShopper_obj.firstname;
  console.log('firstname: ', firstname);
  const lastname = cShopper_obj.lastname;
  console.log('lastname: ', lastname);
  const shortname = cShopper_obj.shortname;
  console.log('shortname: ', shortname)

  const rmShopperValue =
    cs_is_shopper  ? circle_identifier : shortname;

  const fname = string_has_chars(firstname) ? firstname : '';
  console.log('fname: ', fname)
  const lname = string_has_chars(lastname) ? lastname : '';
  console.log('lname: ', lname)
  const shopper_name = [fname, lname].filter(string_has_chars).join(" ");
  console.log('cs_shopper_nameA: ', "'" + shopper_name + "'")
  const new_name = string_has_chars(shopper_name) ? shopper_name : '';

  console.log('circle_id: ', circle_id)
  console.log('invitation: ', invitation)
  console.log('yes/no: ', showRemoveShopperColumn,
    shopper.id, cs.shopper_id, if_shopper_is_circle_leader, circle_leader_id);

  const [showAreYouSureButtons, setShowAreYouSureButtons] = useState(false);
  const toggleShowAreYouSureButtons = () => {
      setShowAreYouSureButtons(() => !showAreYouSureButtons);
  };

  // When shopper toggles 'showRemoveShopperColumn' off, set the
  // 'showAreYouSureButtons' to off
  useEffect(() => {
    if (! showRemoveShopperColumn) {
      setShowAreYouSureButtons(false);
    }
  }, [showRemoveShopperColumn]);

  const toggleAdminClick = (e) => {
    // is event (e) really necessary since we only toggle shopper_is_admin?
    //console.log("csl cs e", e.target.value);
    console.log("original cs in CSL:", cs);
    const csObj = {
      // stuff same as before:
      id: cs.id,
      circle_id: cs.circle_id,
      shopper_id: cs.shopper_id,
      // and now do the toggle:
      shopper_is_admin: !(cs.shopper_is_admin)
    };
    console.log("new csObj", csObj);
    editCircleShopper(csObj)
  }

  async function removeShoppersOIsFromCircle({circleWithOrders}) {
    console.log("rsois fci", {circleWithOrders});
    // Must have fresh circle data in state so that circle.order_item_ids info
    // is up to date. There's an id for each of the shopper's OIs, and any
    // recently made 0-amt OIs aren't known to the circle and must be added in.

    console.log("rsois oi", {orderItems});
    const circle = circleWithOrders
    console.log("rsois circle", circle);

    // Delete (find) those orderItems of the Circle whose shopper.id matches id
    // of shopper we are removing. This is remover-agnostic, i.e. whether it's
    // the leader removing a Shopper or a Shopper removing themself, we want
    // to find the exiting Shopper's (the circleShopper's) orderItem ids.
    // Note: The list of orderItems is generated based on Trips where the
    // Shopper was active, i.e. had an orderItem. Thus it's possible that
    // orderItems in the Circle do not appear in the orderItems within Trips
    // where the Shopper was active, and so we have to avoid considering
    // (filter out) those non-active-Trip orderItems.
    const idsToDelete = orderItems.filter( oi =>
        circle.order_item_ids.includes(oi.id)  &&  oi.shopper.id == cs.shopper_id
      )
      .map(o => o.id)      // extract orderItem's id
      .sort()
      .reverse()           // sort in descending order
    console.log("iTD", {idsToDelete});

    // We want to delete orderItems in batches, in reverse id order, asynchronously.
    // we will call: fetch(`/api/circles/${circle_id}/order_items/${orderItem_ids}`
    // where orderItem_ids must be a comma-delimited string of orderItem ids to
    // delete. At some point such a string could be too long for a valid URL, so
    // we'll only delete X ids at a time.
    // (Especially with ids of type uuid rather than int.)
    const stringsOfIdsToDelete = [];
    while (idsToDelete.length > 0) {
      const idBatch = idsToDelete.splice(0, Constants.OI_DELETE_BATCH_SIZE);

      // so we need to convert an idBatch array into a string.
      // We'll push that string (delimited by commas by default)
      // onto stringsOfIdsToDelete.
      stringsOfIdsToDelete.push(idBatch.join());
    }
    console.log("soitd", {stringsOfIdsToDelete});

    // per https://dev.to/shadid12/how-to-use-async-await-inside-loops-in-javascript-4dlg
    const batchDeletePromise = async (circle_id_in, nextIDs) => {
      console.log("rsfc bdp nextIDs", nextIDs);
      try {
        //const result = await deleteOrderItems(circle_id_in, nextIDs)
        //console.log('rsfc return from deleteOrderItems()', result)
        await deleteOrderItems(circle_id_in, nextIDs)
      } catch (e) {
        console.log("rsfc bdp error", {e});
        console.error(e.message);
      }
    }

  return stringsOfIdsToDelete.reduce( (accumulatorPromise, nextIDs) => {
      console.log("rsfc before accPromise", Date.now() );
      console.log('rsfc return from batchDeletePromise/deleteOrderItems()', accumulatorPromise)
      // ^ The 'accumulatorPromise' shows 'undefined' for successive batchDeletes
      // since 'reduce' speedily queues up all the promises for execution and
      // then they finish, serially, as they finish?
      // return accumulatorPromise.then(() => {
      //   return batchDeletePromise(circle_id, nextIDs)
      // });
      return accumulatorPromise.then(() => batchDeletePromise(circle_id, nextIDs));

    // seems like 'Promise.resolve()' is an empty promise to initialize 'reduce'
    // that we can 'then' from (and ensure our callback actually processes the
    // first batch of IDs in the list.)
    }, Promise.resolve())
    
    .then(e => {
      console.log("soitd Promises Resolved!", e);

      console.log("delete circleShopper", cs.id);
      console.log("rsfc before rcs", Date.now() );

      // async function expression used as an IIFE
      (async function () {
        await deleteCircleShopper(cs.id);

        // Removing the circleShopper seems to trigger re-render of the list of
        // circleShoppers in this circle. We are on a circle detail page like
        // /circles/21 that concludes with a list of circleShoppers in the circle.
        // This, in turn (or as part of?), re-renders the Circle detail page itself.
        //
        // This is a problem if the circleShopper removed themself from the circle
        // because now they are viewing a circle they no longer belong to.
        // No state data, get out of here!
        // (Not a problem for a leader who removed a circleShopper because the
        // leader is still a member of the circle. Leader still appears in the
        // list, has data about this circle to work with.)
        // But even as a leader deleting the circleShopper, we need to re-build
        // several bits of state to catch up with the removal of the various
        // records the circleShopper had for this circle.
        // We'll do this the lazy way, with 'navigate' and refresh the screen.
        // But first, deleteCircleShopper() must finish, and thus the IIFE.
        navigate('/circles/', {replace: true});
        navigate(0);
      })();

      return 'okk';  // This survives the navigate and returns!
    }).catch(error => {
      console.log('soitd Error message: ', error);
      return 'problem';
    });
  };

  function removeShopperFromCircle (e) {
    // TODO: is event (e) useful for anything? We already have 'invite_id'...
    const promise = Promise.resolve();

    promise
    .then( value => {
      // Delete an invitation to the circle for shopper.
      // Backend only allows shopper to delete the invitation if shopper is an
      // admin or if the invitation is for the shopper him/her-self and shopper
      // is in the circle. (Already accepted the invitation.)
  
      console.log("rsfc invitation", {invitation})
      const invitationIsFalse = Object.is(invitation,null) ||
                                  invitation === undefined ||
                                  !invitation.hasOwnProperty('id');
      console.log("rsfc before di", Date.now() );
      return deleteInvitation(invitationIsFalse ? null : invitation.id);
    })
    .then( value => {
      console.log("rsfc di return:", value);
  
      console.log("rm OIs from circle:", circle_id);
      // Delete orderItems by cs.shopper_id for trips of the circle.
      // TODO: ^ until this is happening via business logic on backend, we are
      // counting on someone who is removing themself to not sabotage the
      // orderItems removal process.
      // (Maybe it IS happening via a cascade delete?)

      return (async function () {
        const circleWithOrders = await fetchCircleOrders(circle_id);
        // Makes sure circles.order_item_ids matches database.
        // Using IIFE to be sure it happens before rsoifc:

        return removeShoppersOIsFromCircle({circleWithOrders: circleWithOrders});
      })();
    })
    .catch((error) => console.error('rmCSfmCircle', error))
  }
  
  // re state: https://stackoverflow.com/questions/64782949/how-to-pass-params-into-link-using-react-router-v6
  // same: https://stackoverflow.com/posts/70342010/revisions
  // TODO: each line should Link to shopper's bio, ability to edit profile
  return (
    <tr>
      <td>
        <Link to={`/shoppers/${cs.shopper_id}`}>
          {shortname}
        </Link>
      </td>
      <td>
        {new_name}
      </td>
      <td>
        {
          // If the person running this code is leader of the circle,
          // and the CircleShopper we are processing is not the leader of the circle,
          // then we can use our admin powers to toggle the admin status of the CircleShopper.
          (if_shopper_is_circle_leader  &&  ! cs_is_leader)
            ? <div className="oneline-container-left">
                <HeadingBuilder
                  tippyAlertText = {t('circleShopper.admin_status_button.tippyContent')}
                  // Click to toggle admin status
                />
                <Tippy content= {t('circleShopper.admin_status_button.tippyContent')} >
                  <Button type="button" primary={true} value={cs.id} onClick={toggleAdminClick}>{admin_leader_status}</Button>
                </Tippy>
              </div>
            : <span>{admin_leader_status}</span>
        }
      </td>
      <td>
        {/* Show 'Remove' if shopper is leader - allows removing another shopper. */}
        {/* Show 'Leave Circle' to allow a shopper to remove themself. */}
        {/* But, this is not how a leader removes themself. */}
        {/* They either recruit a new leader (and then delete self) */}
        {/* or they delete the circle. */}
        { (showRemoveShopperColumn &&
            ! showAreYouSureButtons &&
            (cs_is_shopper || if_shopper_is_circle_leader) &&
            ! cs_is_leader
          )
            ? <HeadingBuilder
                containerType  = "container-left"
                buttonAction   = {toggleShowAreYouSureButtons}
                headingText    = {removeShopperButtonLabel}
                value          = {rmShopperValue}
                // Leave Circle | Remove
                tippyAlertText = {removeShopperButtonTippyText}
                tippyText      = {removeShopperButtonTippyText}
              />
            : (showRemoveShopperColumn &&
                showAreYouSureButtons &&
                (cs_is_shopper || if_shopper_is_circle_leader) &&
                ! cs_is_leader
              )
              ? 
                <div className='container-left'>
                <Table responsive>
                  <thead>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <HeadingBuilder
                          containerType  = 'oneline-container-right'
                          buttonAction   = {removeShopperFromCircle}
                          headingText    = {yesRemoveShopperButtonLabel}
                          // Yes, (Leave | Remove)
                          tippyAlertText = {yesRemoveShopperButtonTippyText}
                          tippyText      = {yesRemoveShopperButtonTippyText}
                        />
                      </td>
                      <td>
                        <HeadingBuilder
                          containerType  = 'container-left'
                          headingText    = {t('global.label.noCancel')}
                          // No, cancel
                          buttonAction   = {toggleShowAreYouSureButtons}
                          tippyText      = "No, don't remove this Shopper from the Circle."
                          // Omit 'tippyAlertText' to save room horizontally
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
                </div>
              : null
        }
      </td>
    </tr>
  );
}
export default CircleShopperRow;
