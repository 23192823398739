import React from 'react';
//import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { showRoundedAbsAmt, findAbsAmtAsPct, formatMoney } from '../utils';
//import { ShopperContext } from '../context/shopper';
import { usePhrasing } from "../hooks/useSkuUtils";
import { useTranslation } from "react-i18next";

export const GoShoppingRow = ( {
  sku,
  skuPrice,
  skuAmtForShopper,
  skuAmtForCircle
} ) => {
  const { t } = useTranslation();

  const amtToGet =
    showRoundedAbsAmt({
      adjustedAbsAmtAsPct:
        findAbsAmtAsPct({topNum: skuAmtForShopper + skuAmtForCircle, sku})
    });

  const formatAmtToGet =
    sku.type === "WeightyItem"
      ? (amtToGet / 100) + " " + sku.units
      : formatMoney({amount: amtToGet/100});

  const maybeAddUnits =
    sku.type === "WeightyItem"
      ? "/" + sku.units
      : " " + t('sku.qty_units.each');

  const { weightAndUnitsOfSku } = usePhrasing();

  // Make one-column cell that concatenates the relevant sku values for a
  // shopping list. Allows values to appear contiguously on shopper's cell
  // phone while shopping.
  // Minimal info so that shopping list is lightweight on mobile.
  return (
    <tr>
      <td>
        {formatAmtToGet} -&nbsp;
        {sku.brand},&nbsp;

        <Link to={`/skus/${sku.id}?showEdit=1`}>
          {sku.label}
        </Link>,

        ${skuPrice.toFixed(2).toString()}{maybeAddUnits}
        {
          weightAndUnitsOfSku({
            sku,
            showWeightAsSkuEditLink: false,
            showUnitCount: false,
            showQtyUnits: false
          })
        }
        {
          sku.type === 'DiscreteItem'
            ? `, ${t('sku.qty_units.textHas')} ${sku.unit_count}`
            // `, has 4`
            : null
        }
      </td>
    </tr>
  )
}

export default GoShoppingRow