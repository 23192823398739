// copied from https://edvins.io/react-text-to-speech by Edvins Antonovs, 230405
import React, { useState, useEffect, useContext } from "react";
import { ShopperContext } from '../context/shopper';
import AudioPlayButtons from "../components/AudioPlayButtons";

const TextToSpeech = ({ text }) => {
  const [isPaused, setIsPaused] = useState(false);
  const [utterance, setUtterance] = useState(null);

  const [playWasClicked, setPlayWasClicked] = useState(false);

  const {
    latestPausedText,
    resetLatestPausedText,
  } = useContext(ShopperContext);

  useEffect(() => {
    const synth = window.speechSynthesis;
    const u = new SpeechSynthesisUtterance(text);
    // console.log('tts u', u);

    setUtterance(u);

    return () => {
      synth.cancel();
    };
  }, [text]);

  const handlePlay = (e) => {
    // prevent the click from being picked up as an instruction to Submit:
    e.preventDefault();

    const synth = window.speechSynthesis;
    // console.log('handlePlay')
    // console.log('tts isPaused', isPaused)
    // console.log('tts text', text)
    // console.log('tts synth', synth);
    // // console.log('tts utterance', typeof utterance);
    // console.log('tts utterance', utterance);
    // console.log('tts utt.text', utterance.text);
    // console.log('tts playWasClicked', playWasClicked);

    // if this paragraph's Play button has not been clicked until now, then
    // emit 'cancel' to clear out any other object's audio, proceed to play.
    if (! playWasClicked
      ||  latestPausedText !== utterance.text
      // Or,if some text's playback was paused (meaning that it's in some global
      // register (?) waiting to resume playing when someone clicks Play), then
      // 'cancel' the other audio and start playback from beginning of this text
    ) {
      synth.cancel();
    }

    if (isPaused || !playWasClicked) {
      // if Play button was in 'paused' state then let's 'resume'.
      // if Play button had not been clicked until just now, invoking 'resume'
      // insures getting focus here.
      synth.resume();
    }

    synth.speak(utterance);

    setIsPaused(false);
    setPlayWasClicked(true);
    // The first time that Play is clicked, setting this to 'true' matters.
    // All times thereafter, the reset to 'true' is basically a no-op.
  };

  const handlePause = (e) => {
    e.preventDefault();
    const synth = window.speechSynthesis;
    resetLatestPausedText(utterance.text);
    // A state variable in context tracks which text was the latest to be
    // paused, so update that variable now.
    // Later, when shopper clicks Play, we'll check that we are processing the
    // same text as was paused here. If we paused some other track, the Play
    // action will 'cancel' it and play the correct audio from the beginning.

    synth.pause();

    setIsPaused(true);
  };

  const handleStop = () => {
    const synth = window.speechSynthesis;

    synth.cancel();

    setPlayWasClicked(false);
    setIsPaused(false);
  };

  return (
    <>
      <>&nbsp;</>
      <AudioPlayButtons
        handlePlay     = {handlePlay}
        handlePause    = {handlePause}
        handleStop     = {handleStop}
        isPaused       = {isPaused}
        playWasClicked = {playWasClicked}
      />
    </>
  );
};

export default TextToSpeech;
