import React from 'react';
import * as Constants from '../constants';
import { IoWarningOutline } from "react-icons/io5";
// The warning icon /!\ only shows when directed by the caller, via param
// 'forceShowBWarning' of 'true'. It presents as 'red/clicked' because we
// start out showing the warning text below the button, as though the icon had
// previously existed in an unpressed state. Clicking the red (background) icon
// dismisses the warning.

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { useTranslation } from "react-i18next";

// Show warning icon and Warning Text
export const ButtonWarning = ({
  bWarningMsg        = "",
  // This message will show below the button. Like an alert, but better control.
  forceShowBWarning  = false,
  // To show the warning, caller will invoke with this attr set to 'true'.
  setShowBWarning    = (function () { }),
  // We use this to set 'forceShowBWarning' to 'false' in caller's environment.
  // This will quiesce proactive showing of the warning. (Ha!)

  iconShowBWarning   = false,
  // HeadingBuilder invokes with this set to 'true' so that warning icon is red.
  iconToggleBWarning = (function () { }),
  // This will toggle the warning icon from showing as red to not showing at all.
}) => {

  // console.log('fm bWarningMsg:', bWarningMsg)
  // console.log('fm iconShowBWarning:', iconShowBWarning)
  // console.log('fm forceShowBWarning:', forceShowBWarning)

  const { t } = useTranslation();
  const hideIconAndMessage = () => {
    // clicking the warning icon toggles its state
    iconToggleBWarning();

    // upon clicking the red warning icon, do a reset of 'button was clicked':
    setShowBWarning(false);
  }

  return (
    <>
      {
        (forceShowBWarning  &&  bWarningMsg.length > 0)
          ? <>
        {/* Don't apply 'HeadingBuilder' for this 'Tippy contents' (for now) */}
              <Tippy content =
                {iconShowBWarning ? t('global.clickToHide') : bWarningMsg}
              >
                <button 
                  type="button"
                  style={{
                    border: "none",
                    margin: 5,
                    padding: 0,
                    background: iconShowBWarning ? Constants.O_BOX_RED : "none"
                  }} 
                  onClick={hideIconAndMessage}
                >
                  <span><IoWarningOutline /></span>
                  {/* warning /!\ icon */}
                </button>
              </Tippy>
              &nbsp;
            </>
          : null
      }
    </>
  )
}

export default ButtonWarning;