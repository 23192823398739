import React, { useContext } from 'react';
import { ShopperContext } from '../context/shopper'
import { useStyles } from "../hooks/useStyles";
import InvitationLink from '../components/InvitationLink'
import HeadingBuilder from '../components/HeadingBuilder'
import Table from 'react-bootstrap/Table';
// import Tippy from '@tippyjs/react';
// import 'tippy.js/dist/tippy.css';
import { useTranslation } from "react-i18next";

const Invitations = ({handleInvitationsClick}) => {
  const { shopper, invitations, signedIn }
    = useContext(ShopperContext);
  const { t } = useTranslation();
  const { tableShow, textAlignLeft } = useStyles();

  if (signedIn) {

      function s_by_circle( a, b )
      {
        if ( a.circle.identifier.toLowerCase() < b.circle.identifier.toLowerCase()){
          return -1;
        }
        if ( a.circle.identifier.toLowerCase() > b.circle.identifier.toLowerCase()){
          return 1;
        }
        return 0;
      }
    
      function invitations_exist(array) {
        if (array.length > 0) {
          return true
        }
        return false
      }

      console.log('InvitesA', invitations);
      const invitationsList =
          invitations_exist(invitations)
          ?
            invitations
              .sort( s_by_circle )
              .map(invitation => {
            console.log('i_before', invitation);
            const i = {...invitation};
            console.log('i_after', i);

            return <InvitationLink key={i.id} invitation = { i } />
            })
          : null;
      console.log('Invitations.js invitationsList: ', invitationsList);
      console.log('tl is t/f', invitationsList ? 'there are invitations' : 'no invitations');

      return (
          <article>
              <HeadingBuilder
                Size        = "h3"
                headingText = { t('invitation.formPart.headlineLabel') }
                value       = {shopper.identifier}
                // Invitations for: shopper.identifier
              />
              { invitationsList
                  ? <Table striped bordered hover size="sm"  style={tableShow}>
                      <thead style={textAlignLeft}>
                        <tr>
                          <th>
                            {/* Circle */}
                            { t('invitation.circle.label') }
                          </th>
                          <th>
                            {/* Zip/Postal Code */}
                            { t('circle.postal_code.label') }
                          </th>
                          <th>
                            {/* Type */}
                            { t('invitation.type.label') }
                          </th>
                          <th>
                            {/* Status */}
                            { t('invitation.status.shortLabel') }
                          </th>
                        </tr>
                      </thead>
                      <tbody style={textAlignLeft}>
                        {invitationsList}
                      </tbody>
                    </Table>
                  : <p>
                      { t('invitation.formPart.noInvitationsLabel') }
                      {/* No Invitations defined */}
                    </p>
              }
              <br/>
              <button onClick={handleInvitationsClick}>{
                          t('invitation.formPart.hidePaneButtonLabel') }</button>
                          {/* Hide 'Invitations' Pane */}
          </article>
      )
  } else {
    return (
      <HeadingBuilder
        Size = 'h3'
        headingText = {t('global.notSignedInMessage')}
        // Not Authorized - Please Sign in or Sign up
      />
    )
  }
}


export default Invitations;