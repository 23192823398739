//import React from "react";
//import React, { useEffect } from "react";
import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import { ShopperProvider } from './context/shopper';
import './App.css';
import Home from './containers/Home';
import ShopperDeleteForm from './components/ShopperDeleteForm';
import Circles from './containers/Circles';
import Circle from './containers/Circle';
import CircleEditForm from './components/CircleEditForm';
import CircleDeleteForm from './components/CircleDeleteForm';
import InvitationEditForm from './components/InvitationEditForm';
import InvitationDeleteForm from './components/InvitationDeleteForm';
import SkuEditForm from './components/SkuEditForm';
import TripEditForm from './components/TripEditForm';
import Shopper from './containers/Shopper';
import Trips from './containers/Trips';
import Trip from './containers/Trip';
import Skus from './containers/Skus';
import Sku from './containers/Sku';
import SkuSearch from './components/SkuSearch';
// import FooterContainer from './containers/Footer';

import Navbar from './components/Navbar';
import Signup from './components/Signup';
import Signin from './components/Signin';
// import PlanForm from './components/PlanForm';
import TripForm from './components/TripForm';
import TripDeleteForm from './components/TripDeleteForm';
//import Sku from './components/SkuForm';
import ShopperEditForm from './components/ShopperEditForm';
import SkuChooseType from './components/SkuChooseType';
import PriceForm from './components/PriceForm';
import PriceEditForm from './components/PriceEditForm';
import FeedbackForm from './components/FeedbackForm';

import Loading from './components/Loading';
import i18n from './i18n';
import LocaleContext from './LocaleContext';

function App(props) {
  const [locale, setLocale] = useState(i18n.language);
  //i18n.on('languageChanged', (lng) => setLocale(i18n.language));
  // see https://blog.shahednasser.com/how-to-internationalize-a-react-app/ but why 'lng'? so:
  i18n.on('languageChanged', () => setLocale(i18n.language));

  // re sticky header:
  //https://stackoverflow.com/a/58645785
  //https://github.com/jsx-eslint/eslint-plugin-react/blob/master/docs/rules/style-prop-object.md
        /* <header style={{
            position: "sticky",
            top: 0,
            "z-index": 999
          }}> 
          */

        // I stashed this in index.css:
        // <div id="header" style="background-color:GRAY;
        //   text-align:center;
        //   border-bottom:1px
        //   SOLID BLACK;
        //   color:WHITE;
        //   line-height:50px;
        //   font-size:40px"></div>
  return (
    <div className="App">
    <LocaleContext.Provider value={{locale, setLocale}}>
     <React.Suspense fallback={<Loading />}>

      <ShopperProvider
        // storageKeyShopper='swaShopper'
      >
        <div>
          <div id="header">
            {/* Looks like "sticky-nav" from https://stackoverflow.com/a/51165229 not helping */}
            {/* <Navbar className="sticky-nav"/> */}
            <Navbar />
            <FeedbackForm />
          </div>
          <div id="content">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/signin" element={<Signin />} />
              {/* <Route path="/shoppers" element={<Home />} /> */}
              <Route path="/shoppers/:id" element={<Shopper />} />
              <Route path="/shoppers/:id/edit" element={<ShopperEditForm />} />
              <Route path="/shoppers/:id/delete" element={<ShopperDeleteForm />} />
              <Route path="/circle/skus/:scircle_id" element={<Skus />} />
              <Route path="/circle/trips/:tcircle_id" element={<Trips />} />
              <Route path="/circles" element={<Circles />} />
              <Route path="/circles/:id" element={<Circle />} />
              <Route path="/circles/:id/edit" element={<CircleEditForm />} />
              <Route path="/circles/:id/delete" element={<CircleDeleteForm />} />
              {/* <Route path="/circles/:circle_id/plans" element={<PlanForm />} /> */}
              <Route path="/circles/:circle_id/trips" element={<TripForm />} />
              <Route path="/circles/:circle_id/skus/:chosenUuid" element={<SkuChooseType />} />
              <Route path="/circles/:circle_id/skus" element={<SkuChooseType />} />
              <Route path="/circles/:circle_id/invitations/:invite_id/delete" element={<InvitationDeleteForm />} />
              <Route path="/circles/:circle_id/invitations/:id" element={<InvitationEditForm />} />
              <Route path="/circles/:circle_id/search_skus" element={<SkuSearch />} />
              <Route path="/skus" element={<Skus />} />
              <Route path="/skus/:id" element={<Sku />} />
              <Route path="/skus/:id/edit" element={<SkuEditForm />} />
              <Route path="/skus/:sku_id/prices" element={<PriceForm />} />
              <Route path="/skus/:sku_id/prices/:id" element={<PriceEditForm />} />
              <Route path="/trips" element={<Trips />} />
              <Route path="/trips/:id" element={<Trip />} />
              <Route path="/trips/:id/edit" element={<TripEditForm />} />
              <Route path="/trips/:id/delete" element={<TripDeleteForm />} />
            </Routes>
          </div>
          {/* <div id="footer">
            <FooterContainer />
          </div> */}
        </div>
      </ShopperProvider>
     </React.Suspense>
    </LocaleContext.Provider>
    </div>
  );
}
// per https://reactrouter.com/docs/en/v6/upgrading/v5
// <Route exact> is gone. Instead, routes with descendant routes (defined in
// other components) use a trailing * in their path to indicate they match deeply

export default App;
