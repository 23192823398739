import React from 'react';
import { useTranslation } from "react-i18next";
import HeadingBuilder from '../components/HeadingBuilder';

export const InAHurry = ({
  showInAHurryButton
}) => {

  const { t } = useTranslation();

  return (
    <>
      {showInAHurryButton
        ? <>
            <HeadingBuilder
              headingText = {t('sku.in_a_hurry.label')}
              // Don't know details yet? In a hurry?
              tippyText      = {t('sku.in_a_hurry.tippyContent')}
              tippyInfoText  = {t('sku.in_a_hurry.tippyContent')}
            />
            <br/>
          </>
        : null
      }
    </>
  )
}

export default InAHurry;