import React, { useState, useContext } from 'react';
import { ShopperContext } from '../context/shopper'
import { useUtils } from "../hooks/useUtils";
import { useStyles } from "../hooks/useStyles";
import InviteeLink from '../components/InviteeLink'
import { HeadingBuilder } from '../components/HeadingBuilder';
import Table from 'react-bootstrap/Table';
import { useTranslation } from "react-i18next";

const CircleInvitations = ({ circle_id, circle_identifier, circle_leader_id, open_invites} ) => {
  console.log('c_id_prop', circle_id);
  console.log('c_identifier_prop', circle_identifier);
  console.log('invites_prop', open_invites);

  const { shopper, signedIn, errorsDoExist, circleShoppers } = useContext(ShopperContext);
  console.log('cs_circleShoppers', circleShoppers);  // TODO: this will soon dump too much data...

  const { tableShow, textAlignLeft } = useStyles();
  const { is_shopper_a_circle_admin, s_by_created_at } = useUtils();
  const { t } = useTranslation();

  const [showRemoveInvitationColumn, setShowRemoveInvitationColumn] = useState(false);
  const toggleShowRemoveInvitationColumn = () => {
      setShowRemoveInvitationColumn(() => !showRemoveInvitationColumn);
      console.log("sric", showRemoveInvitationColumn);
  };

  // Is current_shopper an admin for the current circle?
  // Does this need to be checked within "if (signedIn)"?
  const if_shopper_is_circle_admin = is_shopper_a_circle_admin({
    cShoppers: circleShoppers, circle_id: circle_id, shopper_id: shopper.id
  });

  // Is current_shopper the leader of the current circle?
  const if_shopper_is_circle_leader =  shopper.id === circle_leader_id;

  // const if_shopper_is_circle_admin = is_shopper_a_circle_admin({
  const tippyTextForStartShowing =
    t('invitation.cancelInvitation.toggleButtonPermitsTippyText') +
    t('global.clickHereToShowHideColumnTippyText');

  const labelToStartShowing =
    t('invitation.cancelInvitation.toggleButtonHeading');

  const labelToStopShowing =
      t('invitation.cancelInvitation.buttonControlsStopShowingColumnA')
      // Stop Showing '
    + t('invitation.cancelInvitation.columnHeading')
      // Cancel Invitation
    + t('invitation.cancelInvitation.buttonControlsStopShowingColumnC')
      // ' column
      // "Stop Showing 'Cancel Invitation' column"

  const tippyTextForStopShowing =
      t('invitation.cancelInvitation.toggleButtonDisablesTippyTextA')
    + t('invitation.cancelInvitation.columnHeading')
    + t('invitation.cancelInvitation.toggleButtonDisablesTippyTextC');

  const removeInvitationColumnHeading =
    t('invitation.cancelInvitation.columnHeading');

  const tippyTextForStartOrStopShowing =
showRemoveInvitationColumn ? tippyTextForStopShowing : tippyTextForStartShowing;

  if (signedIn) {
      console.log('CircShoppers', circleShoppers);
      console.log('c_id', circle_id);

      const invitationsList =
        open_invites.sort( s_by_created_at )
          .map(i => <InviteeLink
            key={i.id}
            if_shopper_is_circle_admin={if_shopper_is_circle_admin}
            if_shopper_is_circle_leader={if_shopper_is_circle_leader}
            invite_id={i.id}
            circle_id={circle_id}
            invitee={i.invitee}
            flavor={i.flavor}
            created_at={i.created_at}
            showRemoveInvitationColumn={showRemoveInvitationColumn}
          />
        )

      console.log('invitationsList', invitationsList);

      return (
          <article>
              <HeadingBuilder
                Size           = 'h3'
                headingText    = {t('invitation.outstandingInvitesListing.label')}
                // Outstanding Invitations
                tippyAlertText = {t('invitation.outstandingInvitesListing.tippyContent')}
                tippyText      = {t('invitation.outstandingInvitesListing.tippyContent')}
              />
              <HeadingBuilder
                Size        = 'h5'
                headingText = {t('global.label.forCircle')}
                value       = {circle_identifier}
                // for Circle oipuy
              />
              <Table striped bordered hover style={tableShow}>
                {/* <thead> */}
                <thead style={textAlignLeft}>
                  <tr>
                    <th>
                      {t('invitation.outstandingInvitesListing.invitee')}
                      {/* Invitee */}
                    </th>
                    <th>
                      {t('invitation.outstandingInvitesListing.invited')}
                      {/* Invited */}
                    </th>
                    <th>
                      {t('invitation.type.label')}
                      {/* type */}
                    </th>
                    <th>
                      { showRemoveInvitationColumn
                        ? <HeadingBuilder
                            headingText    = {removeInvitationColumnHeading}
                            // Cancel Invitation?
                            tippyAlertText =
                    {t('invitation.cancelInvitation.ColumnHeaderTippyContent')}
                            tippyText      =
                    {t('invitation.cancelInvitation.ColumnHeaderTippyContent')}
                            // Cancel an Invitation
                          />
                        : null
                      }
                    </th>
                  </tr>
                </thead>
                {/* <tbody> */}
                <tbody style={textAlignLeft}>
                  {invitationsList}
                </tbody>
              </Table>
              <br/>
              {/* { errorsDoExist && <ErrorList /> } */}
              {/* TODO: Not showing ErrorList here since it shows in CircleShoppers area. Change? */}
              {
                (if_shopper_is_circle_leader || if_shopper_is_circle_admin)
                ? <>
                    <HeadingBuilder
                      headingText =
                        {showRemoveInvitationColumn
                          ? labelToStopShowing
                            // "Stop Showing 'Cancel Invitation' column"
                          : labelToStartShowing
                            // Cancel an Invitation"
                        }
                      buttonAction   = {toggleShowRemoveInvitationColumn}
          
                      tippyAlertText = {tippyTextForStartOrStopShowing}
                      tippyText      = {tippyTextForStartOrStopShowing}
                    />
                    <br/>
                  </>
                : null
              }
          </article>
      )
  } else {
    return (
      <HeadingBuilder
        Size = 'h3'
        headingText = {t('global.notSignedInMessage')}
        // Not Authorized - Please Sign in or Sign up
      />
    )
  }
}

export default CircleInvitations;