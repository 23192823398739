import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { CSVLink } from "react-csv";
import { ShopperContext } from '../context/shopper'
import HeadingBuilder from '../components/HeadingBuilder';
import ShowAlert from '../components/ShowAlert';
import { useStyles } from "../hooks/useStyles";
import { useUtils } from "../hooks/useUtils";
import { useLookups, usePhrasing } from "../hooks/useSkuUtils";
import SkuLink from '../components/SkuLink'
import { getTimestringNow } from '../utils'; 
import * as Constants from '../constants';
import { Link } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import { useTranslation } from "react-i18next";

const Skus = () => {
  const { shopper, circles, circleShoppers, skus, signedIn,
        } = useContext(ShopperContext);

  const { scircle_id } = useParams();
  console.log( 'sci c', scircle_id);

  const onlyOneCircleId =
    (Array.isArray(circles) && circles.length === 1)  ? circles[0].id : null;
  // console.log('first circle id is |', onlyOneCircleId, '|')

  // If shopper belongs to only one circle,
  // or was directed here by clicking a circle's 'Circle's Skus' button,
  // the Skus page should focus on skus of that circle.
  const onlyWorkWithThisCircleId =  onlyOneCircleId || scircle_id || null;

  const { t } = useTranslation();
  const skusHeading = t('global.label.skus')
    + (scircle_id ? (' ' + t('global.label.forCircle')) : '')
  const scircleIdObj = 
    scircle_id ? circles.find(c => c.id == scircle_id) : null;
  const skusValue =  scircleIdObj ? scircleIdObj.identifier : '';

  const { tableShow, textAlignLeft, tableShowNarrow } = useStyles();
  const { getTippyPostalCode } = usePhrasing();
  const { s_by_identifier, any_identifiers_in_multiple_postal_codes }  = useUtils();
  const { is_shopper_a_circle_admin, is_shopper_admin_of_some_circle } = useUtils();
  const [showSkuDespiteHidden, setShowSkuDespiteHidden] = useState(false)
  const [showCircleZipCodes, setShowCircleZipCodes] = useState(true);

  // While initializing Skus.js, update showCircleZipCodes appropriately based
  // on whether shopper is in two circles that have the same identifier.
  // We can't just seed 'useState' with the 'any_identifiers' value since
  // the early/instant value for 'circles' looks undefined. Seems better luck
  // to use 'useEffect' (with its late lookup?)
  // TODO: Irritatingly, this fails if do refresh of Skus.js though it works
  // ok if arrive here by clicking 'Skus'. Why doesn't 'circles' have a
  // value in place upon refresh for 'any_identifiers' to work with?
  //console.log('setmyst Skus circles', circles);
  useEffect(() => {
    setShowCircleZipCodes(
      any_identifiers_in_multiple_postal_codes({circles}) ? true : false
    );
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);
  // The warning wants to load dep array with [circles,
  // any_identifiers_in_multiple_postal_codes] but that causes useEffect to fire
  // every time we click the 'toggleShowCircleZipCodes' button. Not good
  // since this hardcodes a setting of Show or Hide for the button based
  // strictly on presence of duplicate identifiers. The shopper may still
  // want to toggle the value for personal reasons.

  const { lookup_category, lookup_tax_type } = useLookups();

  const [showRemoveSkuColumn, setShowRemoveSkuColumn] = useState(false);
  const toggleShowRemoveSkuColumn = () => {
      setShowRemoveSkuColumn(() => !showRemoveSkuColumn);
      console.log("srsc", showRemoveSkuColumn);
  };

  const removeSkuButtonTippyText =
    t('sku.toggleButtonToRemoveSku.tippyContentA') +
    t('global.clickHereToShowHideColumnTippyText') +
    t('sku.toggleButtonToRemoveSku.tippyContentC') +
    t('global.qWantToProceedTippyText');

  const circleColumnComment =
      t('sku.circleColumn.postalCodeTippyContent')
    + ' // '
    + t('circle.postal_code.purposeTippyContent');

  const [showDownloadSkusAlert, setShowDownloadSkusAlert] = useState(false);
  const toggleDownloadSkusAlert = () =>  setShowDownloadSkusAlert(! showDownloadSkusAlert);


  if (signedIn) {
      console.log('CircskusA', skus);

      function s_by_circle_zip_store_sku( a, b )
      {
        //console.log("sbitd", a)
        if ( a.circle.identifier.toLowerCase() < b.circle.identifier.toLowerCase()){
          return -1;
        }
        if ( a.circle.identifier.toLowerCase() > b.circle.identifier.toLowerCase()){
          return 1;
        }
        if ( a.circle.postal_code.toLowerCase() < b.circle.postal_code.toLowerCase()){
          return -1;
        }
        if ( a.circle.postal_code.toLowerCase() > b.circle.postal_code.toLowerCase()){
          return 1;
        }
        if ( a.store_label < b.store_label ){
            return -1;
        }
        if ( a.store_label > b.store_label ){
          return 1;
        }
        if ( a.label < b.label ){
            return -1;
        }
        if ( a.label > b.label ){
          return 1;
        }
        return 0;
      }
    
      function skus_exist(array) {
        if (array.length > 0) {
          return true
        }
        return false
      }

      const headers = [
        { label: t('sku.id.csvLabel'),             key: "id" },         // "Sku ID",
        { label: t('circle.id.csvLabel'),          key: "circle_id" },  // "Circle ID" "circle_id"
        { label: t('circle.identifier.csvLabel'),  key: "circle_identifier" }, // "Circle Name" "circle_name"
        { label: t('circle.postal_code.csvLabel'), key: "circle_postal_code" }, // "PCode" "pcode"
        { label: t('sku.identifier.csvLabel'),     key: "identifier" }, // "Sku Identifier" "sku_identifier"
        { label: t('sku.label.label'),             key: "label" },      // "Label" "label"
        { label: t('sku.detail.label'),            key: "detail" },     // "Details" "detail"
        { label: t('sku.brand.label'),             key: "brand" },      // "Brand" "brand"
        { label: t('sku.category.label'),          key: "category" },   // "Category" "category"
        { label: t('sku.store_label.label'),       key: "store_label" },// "Store" "store_label"
        { label: t('sku.type.label'),              key: "type" },       // "Type" "type"
        { label: t('sku.typical_weight.csvLabel'), key: "typical_weight" }, // "Typical Weight" "typical_weight"
        { label: t('sku.quantity.csvLabel'),       key: "quantity" },   // "Quantity" "quantity"
        { label: t('sku.units.csvLabel'),          key: "units" },      // "Units" "units"
        { label: t('sku.unit_count.csvLabel'),     key: "unit_count" }, // "Unit Count" "unit_count"
        { label: t('sku.tax_type.label'),          key: "tax_type" }    // "Tax Status" "tax_type"
      ];

      const skusList =
        skus_exist(skus)
        ?
          skus
            .filter(
              sku => scircle_id  ?  sku.circle.id == scircle_id  :  true
            )
            .filter(
              // If a sku is not hidden, save it! Else, it's hidden, so
              // only save it if the shopper is an admin of sku's circle.
              // 'showSkuDespiteHidden' lets admins control whether to
              // even view 'Hidden' skus.
              sku => !sku.is_hidden  ||  (showSkuDespiteHidden &&
                is_shopper_a_circle_admin({
                  cShoppers: circleShoppers, circle_id: sku.circle_id, shopper_id: shopper.id
                })
              )
            )
            .sort( s_by_circle_zip_store_sku )
        : null;

      const thereAreSkus =   skusList &&  skusList.length > 0;
      console.log('tl is t/f', thereAreSkus ? 'there are skus' : 'no skus');

      const skusListForCsv =
        thereAreSkus
          ? skusList
            .map(s => {
              return {
                circle_id: s.circle_id.toString(),
                circle_identifier: s.circle.identifier,
                circle_postal_code: s.circle.postal_code,
                id: s.id.toString(),
                identifier: s.identifier,
                label: s.label,
                detail: s.detail,
                brand: s.brand,
                category: s.category && lookup_category({category: s.category}),
                store_label: s.store_label,
                type: s.type,
                typical_weight: s.typical_weight && s.typical_weight.toString(),
                quantity: s.quantity && s.quantity.toString(),
                units: s.units,
                unit_count: s.unit_count && s.unit_count.toString(),
                tax_type: s.tax_type && lookup_tax_type({tax_type: s.tax_type})
              }
            })
          : null;
      console.log('Skus.js skusListForCsv: ', skusListForCsv);

      const textForSkusToCsvLink =
        scircle_id
          ? t('sku.button_text.labelSkusOfCircle')
            // Download Circle's Skus to CSV File
          : t('sku.button_text.label')
            // Download Skus to CSV File

      const tippyForSkusToCsvLink =
        scircle_id
          ? t('sku.button_text.downloadReportOfCircle')
            // Download a listing of the Circle's Skus to a spreadsheet (CSV file.)
          : t('sku.button_text.downloadReport')
            // Download a listing of all of the Skus of your Circles to a spreadsheet (CSV file.)

      const skusListOfSkuLinks =
        thereAreSkus
          ? skusList
            .map(sku => {
            console.log('s_before', sku);
            const s = {...sku};
            console.log('s_after', s);

            return <SkuLink key={s.id}
              sku = { s }
              if_shopper_is_circle_admin = {
                is_shopper_a_circle_admin({
                  cShoppers: circleShoppers, circle_id: sku.circle_id, shopper_id: shopper.id
                })
              }
              showCircleZipCodes  = {showCircleZipCodes}
              showRemoveSkuColumn = {showRemoveSkuColumn}
            />
            })
          : null;

      const mkListOfCircleAddSkuLinks = ({
            circs = circles,
        }) => {
            console.log('Circs', circs);
            const links_to_circles_for_skus =
              circs
                .sort(s_by_identifier)
                .map( (circle) => 
                 // Not sure about use of 'key'? Is it needed? To the good: no error messages!
                 <tr key={circle.id}>
                  <td>
                    <Link to={`/circles/${circle.id}/skus`}>
                      {circle.identifier}
                    </Link>
                  </td>
                  <td>
                    <HeadingBuilder
                      Size = "h6"
                      headingText = {circle.postal_code}
                      // the Circle's postal_code
                      tippyAlertText = {getTippyPostalCode({circle})}
                      tippyText = {getTippyPostalCode({circle})}
                      // postal_code of '99999' triggers tippy text
                    />
                  </td>
                 </tr>
                );
            return links_to_circles_for_skus;
      }

      const listOfCircleAddSkuLinks = mkListOfCircleAddSkuLinks({});
      console.log('listOfCircleAddSkuLinks', listOfCircleAddSkuLinks);
      // Ack! A valid array that is empty returns true.
      // Guard against empty array with boolean length check.
      const thereAreCircleAddSkuLinks =  listOfCircleAddSkuLinks.length > 0;

      const createOrSearchForSkuButton = ({skusList, focusOnThisCircleId}) => {
        // 'focusOnThisCircleId' likely has value of: onlyWorkWithThisCircleId
        const returnVal = [];
        // At one point, an error message complained about lack of a key.
        // Not getting that error now. Maybe because returnVal.length == 1 ?

        if (focusOnThisCircleId) {
          // the 'Create/Search for a Sku for Circle' button should pre-choose
          // a circle for which to 'Add Sku for Circle', not dish off to
          // a list of circles to choose from for 'create/search for a sku'
          const circleObj = circles.find(c => c.id == focusOnThisCircleId);
          returnVal.push(
           <React.Fragment key='10'>
            <HeadingBuilder
              Size           = 'h5'
              headingText    = {t('sku.circleAddSku.label')}
              value          = {'- '  + circleObj.identifier}
              // Create (or Search for) a Sku for a Circle - oipuy
              linkUrl        = {`/circles/${focusOnThisCircleId}/skus`}
              tippyAlertText = {t('sku.circleAddSku.directTippyContent')}
              tippyText      = {t('sku.circleAddSku.directTippyContent')}
              // Click this button to bring the 'Create Sku' panel into view.
            />
           </React.Fragment>
          )
          return returnVal;
        }

        // If the shopper has skus, they must be in more than one circle, so
        // must choose which circle.
        // If a bunch of skus are filling the page, button here at the top
        // should redirect to the 'href' below to pick a circle.
        if (skusList &&  skusList.length > Constants.SMALL_NUM_OF_SKUS) {
          returnVal.push(
            <React.Fragment key='20'>
              <HeadingBuilder
                Size           = 'h5'
                hrefTo         = '#CreateASkuForACircle'
                headingText    = {t('sku.circleAddSku.label')}
                // Create (or Search for) a Sku for a Circle
                tippyAlertText = {t('sku.circleAddSku.hrefChooseCircleTippyContent')}
                tippyText      = {t('sku.circleAddSku.hrefChooseCircleTippyContent')}
                // Click this button for the panel to choose a Circle and access ...
              />
              <br/>
            </React.Fragment>
          )
          return returnVal;
        }

        // Else don't return a button.
        return null;
      }
    

      const toggleShowCircleZipCodes = () => {
        setShowCircleZipCodes( () => !showCircleZipCodes );
      };

      const toggleShowingHiddenSkus = () => {
        setShowSkuDespiteHidden( () => !showSkuDespiteHidden );
      };

      // Skus marked 'Hidden' only show if you are admin of the sku's Circle.
      // But admins might also like the silent treatment, i.e. might prefer
      // hiding 'Hidden' skus even though they are the admin. So, let's offer
      // a toggle for admins for showing 'Hidden's.
      // It would be obnoxious for non-admins to see a toggle which has no
      // effect, since they don't see 'Hidden' skus in the first place.
      // But, 'admin' is a circle-specific setting, and on this page, a
      // shopper might be seeing skus for multiple circles, some of which
      // they might not be an admin for.
      // It turns out that screening out 'Hidden' records for non-admin
      // circles doesn't matter since the non-admin shopper (who is admin
      // for a different circle) won't be seeing those anyway!
      // Thus, if we show the toggle to anyone who is admin of any circle,
      // then the broadly-granted power to show/hide will still only matter
      // for skus in circles they administer.
      return (
          <>
            <article>
              <HeadingBuilder
                Size = "h3"
                headingText    = {skusHeading}
                value          = {skusValue}
                // Skus [for Circle oiyup]
                tippyAlertText = {t('sku.formPart.headlineTippyContent')}
                tippyText      = {t('sku.formPart.headlineTippyContent')}
              />

              {createOrSearchForSkuButton({
                skusList,
                focusOnThisCircleId: onlyWorkWithThisCircleId
              })}
      
              { thereAreSkus &&
                (
        // If shopper is here for a single circle, and is an admin of the circle
                  ( scircle_id &&  is_shopper_a_circle_admin({
        cShoppers: circleShoppers, circle_id: scircle_id, shopper_id: shopper.id
                    })
                  ) || (
        // or viewing skus for all circles, and is an admin for one of them
                    !scircle_id &&  is_shopper_admin_of_some_circle({
                      cShoppers: circleShoppers, shopper_id: shopper.id
                    })
                  )
                )
                &&
        // then do:
                <>
                  <HeadingBuilder
                    Size           = 'small'
                    buttonAction   = {toggleShowingHiddenSkus}
                    headingText    = {
                      showSkuDespiteHidden
                        ? t('sku.hideAndRevealHiddenSkus.labelHide')
                        // Hide any Hidden Skus
                        : t('sku.hideAndRevealHiddenSkus.labelReveal')
                        // Reveal any Hidden Skus
                    }
                    tippyText      = {t('sku.hideAndRevealHiddenSkus.tippyContent1')}
                    tippyAlertText = {t('sku.hideAndRevealHiddenSkus.tippyContent1')}
                    tippyInfoText  = {t('sku.hideAndRevealHiddenSkus.tippyContent2')}
                  />
                  <HeadingBuilder
                    Size           = 'small'
                    buttonAction   = {toggleShowRemoveSkuColumn}
                    headingText    = {
                      showRemoveSkuColumn
                        ? t('sku.toggleButtonToRemoveSku.labelToStopShowing')
                        // "Stop showing 'Remove Sku?' column"
                        : t('sku.toggleButtonToRemoveSku.labelToStartShowing')
                        // "Start showing 'Remove Sku?' column"
                    }
                    tippyText      = {removeSkuButtonTippyText}
                    tippyAlertText = {removeSkuButtonTippyText}
                    // ? for: 'Remove Sku?' column button
                  />
                  <br/>
                </>
              }
              { skusListForCsv
    // CSVLink throws error if data=null, so verify skusListForCsv not null
                ? <div className="oneline-container">
                    <HeadingBuilder
                      // ? for: 'Download Skus to CSV' button
                      tippyAlertText = {tippyForSkusToCsvLink}
                      showTippyAlert = {showDownloadSkusAlert}
                      toggleTippyAlert = {toggleDownloadSkusAlert}
                    />
                    <CSVLink
                      data={skusListForCsv}
                      headers={headers}
                      filename={ shopper.shortname
                        + '_skus'
                        + '_' + getTimestringNow().substring(2)
                        + '.csv'}
                        // gfbj_skus_230228172423.csv
                      target='_blank'
                    >
                      <HeadingBuilder
                        Size = "h6"
                        headingText = {textForSkusToCsvLink}
                        // Download Circle's Skus to CSV File
                        // or
                        // Download Skus to CSV File
                        tippyText = {tippyForSkusToCsvLink}
                        // can't list tippyAlertText here because its button,
                        // while of type="button", acts as a trigger for CSVLink
                        // Could check this...
                      />
                    </CSVLink>
                  </div>
                : null
              }
              <ShowAlert showAlert = {showDownloadSkusAlert}
                          alertMsg = {t('sku.button_text.downloadReport')} />
              <br/>

              { skusListOfSkuLinks
                  ? <Table striped bordered hover size="sm" style={tableShow}>
                      <thead style={textAlignLeft}>
                        <tr>
                          <th>
                            <HeadingBuilder
                              containerType  = 'container-hug-bottom-left'
                              headingText    = {t('global.label.circle')}
                              // Circle
                              buttonAction   = {toggleShowCircleZipCodes}
                              tippyText      = {circleColumnComment}
                              tippyAlertText = {circleColumnComment}
                              // What Circle does this Sku belong to? // You can reveal (or hide) the postal code...
                            />
                          </th>
                          { showCircleZipCodes
                              ? <th>
                                  { t('global.label.postalCode') }
                                  {/* Zip/Postal Code */}
                                </th>
                              : null
                          }
                          <th>{ t('global.label.sku') }</th>
                          {/* Sku */}
                          <th>{ t('global.label.link') }</th>
                          {/* Link */}
                          <th>{
                              is_shopper_admin_of_some_circle({
                                cShoppers: circleShoppers, shopper_id: shopper.id
                              }) &&
      showSkuDespiteHidden ? t('sku.hideAndRevealHiddenSkus.columnHeader') : null
                            }
                          </th>
                          <th>
                            { showRemoveSkuColumn
                              ? <HeadingBuilder
                                  headingText = {t('sku.removeSku.label')}
                                  // Remove Sku?
                                  tippyText = {t('sku.removeSku.tippyContent')}
                                />
                              : null
                            }
                          </th>
                        </tr>
                      </thead>
                      <tbody style={textAlignLeft}>
                        {skusListOfSkuLinks}
                      </tbody>
                    </Table>
                  : <p>
                      {t('sku.noSkusDefined')}
                      {/* No Skus defined */}
                    </p>
              }
            </article>
              { thereAreCircleAddSkuLinks
                  ? <article id='CreateASkuForACircle'>
                      <hr />
                      <HeadingBuilder
                        Size = "h4"
                        headingText = {t('sku.circleAddSku.label')}
                        // Create (or Search for) a Sku for a Circle:
                        tippyAlertText = {t('sku.circleAddSku.tippyContent')}
                        tippyText      = {t('sku.circleAddSku.tippyContent')}
                      />
                      <Table responsive striped bordered hover size="sm" style={tableShowNarrow}>
                        <thead >
                        </thead>
                        <tbody style={textAlignLeft} >
                          { listOfCircleAddSkuLinks }
                        </tbody>
                      </Table>
                    </article>
                  : null
              }
          </>
      )
  } else {
    return (
      <HeadingBuilder
        Size = 'h3'
        headingText = {t('global.notSignedInMessage')}
        // Not Authorized - Please Sign in or Sign up
      />
    )
  }
}


export default Skus;