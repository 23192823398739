import React from 'react';
//import React, { useContext } from 'react';
//import { ShopperContext } from '../context/shopper';

export const CategoryHeader = ( {categoryText} ) => {

  return (
    <tr>
      <td>
        <b>{categoryText}</b>
      </td>
    </tr>
  )
}

export default CategoryHeader