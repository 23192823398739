import React, { useContext } from 'react';
import Faq from "react-faq-component";
import { ShopperContext } from '../context/shopper';
import { useStyles } from "../hooks/useStyles";
import { useFaqContent } from "../hooks/useFaqContent";
import { HeadingBuilder } from "./HeadingBuilder";
//import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next";

const styles = {
  // bgColor: 'white',
  bgColor: '#F6E6DB',
  titleTextColor: "#222529",
  rowTitleColor: "#222529",
  // rowContentColor: 'grey',
  // arrowColor: "red",
  rowTitleTextSize: "medium",
  rowContentTextSize: "small",
};

// can play with options here if desired:
const config = {
  // animate: true,
  // arrowIcon: "V",
  // tabFocus: true
};

export const SwaOverview = ({
  handleOverviewClick
}) => {

  const { signedIn } = useContext(ShopperContext);
  const { tableShow, textAlignLeft } = useStyles();
  const { SwaOverviewContent } = useFaqContent();
  const { t } = useTranslation();

 if (signedIn) {

  return (
      <article style={tableShow} >
       <button onClick={handleOverviewClick}>
        {t('faq.overview.hideOverview')}
        {/* Hide 'Overview' */}
       </button>
       <span style={textAlignLeft}>
        <br/>
        <br/>
        {/* TODO: in useFaqContent, the div containing "we can buy these" needs better formatting. Why the extra space below the 'ul'? */}
        <Faq
          data={SwaOverviewContent}
          styles={styles}
          config={config}
        />
       </span>
       <button onClick={handleOverviewClick}>
        {t('faq.overview.hideOverview')}
        {/* Hide 'Overview' */}
       </button>
      </article>
  )
 } else {
    return (
      <HeadingBuilder
        Size = 'h3'
        headingText = {t('global.notSignedInMessage')}
        // Not Authorized - Please Sign in or Sign up
      />
    )
 }
}

export default SwaOverview;