import React from 'react';
import * as Constants from '../constants';
import { AiOutlineInfoCircle } from "react-icons/ai";
// The InfoCircle icon (i) is for secondary/spillover Tippy help. It must show
// regardless of whether the shopper has configured to show Tippy help icons.

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { useTranslation } from "react-i18next";

export const AddlInfoIcon = ({
  // Show info (i) icon.
  alertMessage = "",
  showInfoAlert = false,
  toggleInfoAlert = (function () { }),
}) => {

  const { t } = useTranslation();

  return (
    <>
      {
        alertMessage.length > 0
          ? <>
        {/* Don't apply 'HeadingBuilder' for this 'Tippy contents' (for now) */}
              <Tippy content =
                {showInfoAlert ? t('global.clickToHide') : alertMessage}
              >
                <button 
                  type="button"
                  style={{
                    border: "none",
                    margin: 5,
                    padding: 0,
                    background: showInfoAlert ? Constants.O_BOX_RED : "none"
                  }} 
                  onClick={toggleInfoAlert}
                >
                  <span>{ <AiOutlineInfoCircle/> }</span>
                  {/* info (i) icon */}
                </button>
              </Tippy>
              &nbsp;
            </>
          : null
      }
    </>
  )
}

export default AddlInfoIcon;