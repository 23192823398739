import React, { useContext } from 'react';
import { ShopperContext } from '../context/shopper';
import { useUtils } from "../hooks/useUtils";
import { useLookups } from "../hooks/useSkuUtils";
import { useParams, useNavigate } from 'react-router-dom';
import { BackButton } from './BackButton';
import { HeadingBuilder } from './HeadingBuilder';
import { useTranslation } from "react-i18next";

export const InvitationDeleteForm = () => {
  const {
    signedIn, shopper, circles, circleShoppers,
    deleteInvitation
  } = useContext(ShopperContext);

  const navigate = useNavigate();

  const { lookup_flavor_label }       = useLookups();
  const { is_shopper_a_circle_admin } = useUtils();

  const {circle_id, invite_id} = useParams();
  console.log('idf circle_, invite_id: ', circle_id, invite_id)

  const circle = circles.find(c => c.id == circle_id);

  if (circle == null) {
    console.log('idf circle: ', circle)
    // User likely used browser to refresh the page, lost access to state.
    // This causes 'circle' to be 'undefined'.
    // Let's go back to the 'circles' page.
    window.location.replace('/circles/');
  }
  const circle_invitations = [...circle.open_invites]
  console.log("circle_invitations", circle_invitations)

  const { t } = useTranslation();

  const invitationObj = circle_invitations.find(i => i.id == invite_id);
  console.log('idf invitationObj: ', invitationObj)

  // Maybe the 'circle' check above is sufficient for handling browser refresh?
  if (invitationObj == null) {
    console.log('idf invitationObj2: ', invitationObj)
    // User likely used browser to refresh the page, lost access to state.
    // This causes 'invitationObj' to be 'undefined'.
    // Let's go back to the 'circles' page.
    window.location.replace('/circles/');
  }

  function goBack (e) {
    navigate(-1);
  }

  async function removeInvitation (e) {
    // TODO: is event (e) useful for anything?

    console.log("idf before di", Date.now() );
    const rc = await deleteInvitation(invitationObj.id);
    console.log("idf rc", rc);
    console.log("idf after di", Date.now() );

    // The deleteInvitation method in shopper.js calls fetchCircles
    // which rebuilds the circle's open_invites state. This clears the
    // invitation from state.
    // TODO: Need some error handling here?

    // Now that we have deleted an invitation, e.g. id 51, this url has no invitation
    // to delete:
    // http://localhost:4000/circles/49/invitations/51/delete
    // We would see error messages complaining about not finding that id,
    // but instead of letting that happen, let's navigate to safer ground:
    navigate('/', {replace: true});

    // Refresh to update state, make sure future activity doesn't present
    // the deleted invitation.
    navigate(0);

    // I would have thought this line would cause the browser to start
    // showing '/circles/' but the view remains fixed at '/'.
    // And yet without this line, we see an ugly error upon clicking a Back
    // button. (We have just deleted an invitation so that going to a url for
    // a nonexistent invitation fails.)
    // Helpfully, if shopper clicks Back while now viewing '/', this line (?)
    // causes the '/circles/' view to display.
    navigate('/circles/', {replace: true});
  }

  // Is current_shopper the leader of the current circle?
  const if_shopper_is_circle_leader =  circle.leader_id === shopper.id;

  const if_shopper_is_circle_admin = is_shopper_a_circle_admin({
    cShoppers: circleShoppers, circle_id: invitationObj.circle_id, shopper_id: shopper.id
  });

  const tippyDeleteInvitationQuestion =
      t('invitation.cancelInvitation.putTheQuestion')
    + t('global.clickHere')
    + t('global.removeWarning')

  if (signedIn) {
    return (
      <article>
        <HeadingBuilder
          Size        = 'h3'
          headingText = {t('invitation.formPart.headlineDeleteInvitation')}
          value       = {invitationObj.invitee}
          // Delete Invitation: gj
        />
        { lookup_flavor_label({flavor: invitationObj.flavor}) }
        <br/>
        <br/>
        <HeadingBuilder
          Size        = 'h1'
          headingText = {t('global.areYouSure')}
          // Are You Sure?
        />
        {
          (
  // Show 'Delete' button if shopper is circle admin or leader.
  // Maybe we don't need to verify admin or leader since these were checked as
  // conditions for opening this component. The backend will reject if these
  // are not true, so why verify again here?
            (invitationObj.flavor === 'circle_wants_shopper'     && if_shopper_is_circle_admin)
              ||
            (invitationObj.flavor === 'leader_wants_new_leader'  && if_shopper_is_circle_leader)
          )
            ?
             <>
              <HeadingBuilder
                headingText    = {t('invitation.cancelInvitation.label')}
                // Yes, Cancel Invitation
                buttonAction   = {removeInvitation}
                tippyAlertText = {tippyDeleteInvitationQuestion}
                tippyText      = {tippyDeleteInvitationQuestion}
              />
              <br/>
              <HeadingBuilder
                headingText    = {t('invitation.cancelInvitation.labelDontCancel')}
                // No, Don't Cancel Invitation
                buttonAction   = {goBack}
                tippyAlertText = {t('invitation.cancelInvitation.withdrawTheQuestion')}
                tippyText      = {t('invitation.cancelInvitation.withdrawTheQuestion')}
              />
             </>
            : null
        }
        {/* { errorsDoExist && <ErrorList /> } */}
        <br/>
        <BackButton />
      </article>
    )
  } else {
    return (
      <HeadingBuilder
        Size = 'h3'
        headingText = {t('global.notSignedInMessage')}
        // Not Authorized - Please Sign in or Sign up
      />
    )
  }
}

export default InvitationDeleteForm;