import React from 'react';
import { useStyles } from "../hooks/useStyles";
import { usePhrasing } from "../hooks/useSkuUtils";
import { HeadingBuilder } from "./HeadingBuilder";
import * as Constants from '../constants';
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next";

export const MoreInfoReTrip = ({
  circle_id,
  handleMoreTripInfoClick
}) => {

  const { tableShow, textAlignLeft } = useStyles();
  const { t } = useTranslation();

  const {
    getAudioText, headingPlusPlayAudio,
  } = usePhrasing();

  return (
      <article style={tableShow} >
       <br/>
       <HeadingBuilder
         buttonAction   = {handleMoreTripInfoClick}
         headingText    = {t('global.moreInfo.hideButtonLabel')}
         // Hide 'More Info'
         tippyAlertText = {t('global.moreInfo.hideButtonTippyContent')}
         tippyText      = {t('global.moreInfo.hideButtonTippyContent')}
         // Click this button to stop showing this message.
       />
       <br/>
       <span style={textAlignLeft}>
        <p>
          {t('trip.moreInfo.text010')}
          {/*
          Changing a Trip's date and/or hour is an easy way to say "Same Trip, Different Day."
          */}
          {
            headingPlusPlayAudio({
              heading: ' ',
              origText: getAudioText({ originalText: t('trip.moreInfo.text010') })
            })
          }
        </p>
        <p>
          {t('trip.moreInfo.text013')}
          {/*
          The question is: Are you tweaking the details of a trip that has no relation to any other trip? -OR- Is this a repeat or clone of a past trip whose Shopping List seems like a great foundation for planning a new trip?
          */}
          {
            headingPlusPlayAudio({
              heading: ' ',
              origText: getAudioText({ originalText: t('trip.moreInfo.text013') })
            })
          }
        </p>
        <p>
          {t('trip.moreInfo.text015')}
          {/*
          If you are just changing a Trip you have planned for a while, then no history is at stake and you can safely make the change you intended.
          */}
          {
            headingPlusPlayAudio({
              heading: ' ',
              origText: getAudioText({ originalText: t('trip.moreInfo.text015') })
            })
          }
        </p>
        <p>
          {t('trip.moreInfo.text017')}
          {/*
          But, if you are re-using details from a previous Trip as the basis for a new Trip, read on.
          */}
          {
            headingPlusPlayAudio({
              heading: ' ',
              origText: getAudioText({ originalText: t('trip.moreInfo.text017') })
            })
          }
        </p>
        <p>
          {t('trip.moreInfo.text020')}
          {/* 
          Technically, you could go shopping tomorrow using details from a shopping trip with a date from three weeks ago. ShopWeAll
          */}
          {Constants.TM_SYM}
          {/* 
          U+2122, Unicode symbol for (TM), aka &#8482;
          */}
          {t('trip.moreInfo.text030')}
          {/* 
          doesn't know whether you went shopping on the designated day or some other day.
          It simply helps your Shopping Circle organize a list of items you want to purchase and have a clear idea of who will pay how much and how to divide what you buy.
          */}
          {
            headingPlusPlayAudio({
              heading: ' ',
              origText: getAudioText({originalText: 
                  t('trip.moreInfo.text020')
                + Constants.TM_SYM
                + t('trip.moreInfo.text030')
              })
            })
          }
        </p>
        <p>
          {t('trip.moreInfo.text040')}
          {/*
          So, "Same Trip, Different Day?"
          Currently, ShopWeAll does not offer a way to "clone" a trip, i.e. plan a trip for some other day, starting with the same shopping list and quantities as the original trip.
          */}
          {
            headingPlusPlayAudio({
              heading: ' ',
              origText: getAudioText({ originalText: t('trip.moreInfo.text040') })
            })
          }
        </p>
        <p>
          {t('trip.moreInfo.text050')}
          {/*
          What you CAN do, however, is modify the date of a previous trip (for which you have probably already gone shopping.)
          This will reconfigure the trip for some future date, already having the items shoppers got on the original trip.
          You would give everyone the chance to change their orders so that they get the items and quantities that they really want.
          */}
          {
            headingPlusPlayAudio({
              heading: ' ',
              origText: getAudioText({ originalText: t('trip.moreInfo.text050') })
            })
          }
        </p>
        <p>
          {t('trip.moreInfo.text060')}
          {/*
          Will you lose visibility to details about the previous trip?
          Yes, unless you saved a trip report by clicking the '
          */}
          {t('ordersList.button_text.downloadReport')}
          {/*
          Download details to File
          */}
          {t('trip.moreInfo.text070')}
          {/*
          ' button when you settled up that trip.
          */}
          {
            headingPlusPlayAudio({
              heading: ' ',
              origText: getAudioText({originalText: 
                  t('trip.moreInfo.text060')
                + t('ordersList.button_text.downloadReport')
                + t('trip.moreInfo.text070')
              })
            })
          }
        </p>
        <p>
          {t('trip.moreInfo.text080')}
          {/*
          If you want ShopWeAll to preserve that information as an actual trip in your history, then don't modify the trip's details. Start from scratch and 
          */}
          <Link to={`/circles/${circle_id}/trips`}>
            {t('trip.moreInfo.createNewTripLabel')}
            {/* Create a New Trip */}
          </Link>
          {t('trip.moreInfo.text090')}
          {/*
          , letting shoppers build up the list again of items they want to get.
          */}
          {
            headingPlusPlayAudio({
              heading: ' ',
              origText: getAudioText({originalText: 
                  t('trip.moreInfo.text080')
                + t('trip.moreInfo.createNewTripLabel')
                + t('trip.moreInfo.text090')
              })
            })
          }
        </p>
       </span>
       <HeadingBuilder
         buttonAction   = {handleMoreTripInfoClick}
         headingText    = {t('global.moreInfo.hideButtonLabel')}
         // Hide 'More Info'
         tippyAlertText = {t('global.moreInfo.hideButtonTippyContent')}
         tippyText      = {t('global.moreInfo.hideButtonTippyContent')}
         // Click this button to stop showing this message.
       />
      </article>
  )
}

export default MoreInfoReTrip;