import React, { useContext } from 'react';
import { NumericFormat } from 'react-number-format';
import { ShopperContext } from '../context/shopper';
import * as Constants from '../constants';
import { ErrorList } from './ErrorList';
import { useForm } from "../hooks/useForm";
import { useUtils } from "../hooks/useUtils";
import { useStyles } from "../hooks/useStyles";
import { usePhrasing } from "../hooks/useSkuUtils";
import { useParams } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { BackButton } from './BackButton';
import { HeadingBuilder } from './HeadingBuilder';
import { useTranslation } from "react-i18next";
import styles from '../css/trTdFirstChild.module.css';

const TripForm = () => {
  const { shopper, signedIn, circles, circleShoppers, addTrip } = useContext(ShopperContext);
  const { errorsList } = useContext(ShopperContext);  // TODO: prob. toss this
  const { errorsDoExist, dismissErrorsList, setErrorsDoExistToFalse } = useContext(ShopperContext);
  const { setJustGotClearToFalse } = useContext(ShopperContext);
  const { tableShow, tableData, altBorderTopColor, visibilityCollapse } = useStyles();

  const { t } = useTranslation();
  const {circle_id} = useParams();
  console.log('Trip.jsA circle_id: ', circle_id)
  const circle = circles.find(c => c.id == circle_id);
  console.log('Trip.jsB circle: ', circle)

  if (circle == null) {
    console.log('tf circle2: ', circle)
    // User likely used browser to refresh the page, lost access to state.
    // This causes 'circle' to be 'undefined'.
    // Let's go back to the 'trips' page.
    window.location.replace('/trips/');
  }

  const { tripTaxTippyText, tripFoodTaxTippyText } = usePhrasing();
  const tripTaxOverrideText     = tripTaxTippyText();
  const tripFoodTaxOverrideText = tripFoodTaxTippyText();

  const { is_shopper_a_circle_admin } = useUtils();
  const if_shopper_is_circle_admin = is_shopper_a_circle_admin({
    cShoppers: circleShoppers, circle_id: circle_id, shopper_id: shopper.id
  });

  // TODO: present commented-out attributes for entry in some Profile update
  const initialState = {
    label: 'Stores',
    day: '',
    hour: "14:00",
    is_hidden: 0,
    tax_rate: circle.tax_rate || '',
    tax_rate_food: circle.tax_rate_food || '',
    circle_id: circle_id,
    // duration: '',
    // distance: '',
    // est_bill: '',
    // actual_bill: '',
  };
  const { formData, handleChange, reset, editFormValues } = useForm(initialState);
  const { circle_tax_rate } = useUtils();

 if (signedIn) {
  const handleSubmit = (e) => {
    e.preventDefault();

    // clear out any error messages, success statuses
    dismissErrorsList();
    setErrorsDoExistToFalse();

    // If the button is 'Success'/aqua because we just made a trip,
    // and shopper is still viewing TripForm without doing 'Go back',
    // this clears the button before doing 'addTrip' again.
    setJustGotClearToFalse();

    console.log('formData for addTrip: ', formData);
    addTrip(formData);

    console.log("errorsList tada", errorsList);
    console.log("array errorsList", Array.isArray(errorsList));
    console.log("errorsList length", errorsList.length);
    console.log("errorsList errorsDoExist", errorsDoExist);

    //const errorsListHasNoErrorsb = !errorsDoExist;
      //!Array.isArray(errorsList)  ||  !errorsList.length;
    //console.log("errorsList TF", errorsListHasNoErrorsb);
      //!errorsList.length;

    //if (errorsListHasNoErrors)
    //if (!Array.isArray(errorsList)  ||  !errorsList.length) {
      // WHY IS errorsList EMPTY? per console.log, but there are errors!!!!!
      //navigate(-1);
    //}
    
   // errorsListHasNoErrorsb && navigate(-1);
    //errorsDoExist || navigate(-1);
    //false && navigate(-1);

    // Else if error, preserve formData and show error message(s).
    // Note: the below fails because state for errorsDoExist is not updated timely
    // if (errorsDoExist) {
    //   // don't go anywhere
    // } else {
    //   navigate(-1);
    // }
  }

  return (
      <article>
        <HeadingBuilder
          Size        = 'h3'
          headingText = {t('trip.formPart.tripFormHeadlineLabel')}
          // Schedule a new Trip
        />
        <HeadingBuilder
          Size        = 'strong'
          headingText = {t('trip.circlePrompt.label')}
          value       = {circle.identifier}
          // Circle: uipuy
        />
        <br/>
        <form onSubmit={handleSubmit}>
<Table responsive striped bordered hover size="sm" style={tableShow}>
  <thead >
  </thead>
  <tbody className={styles.trTdFC} style={tableData} >
    <tr>
      <td>
        <HeadingBuilder
          containerType  = 'container-input-label'
          headingText    = {t('trip.label.label')}
          // Shopping Trip Description
          labelHtmlFor   = "label_tf"
          tippyAlertText = {t('trip.label.tippyContent')}
          tippyText      = {t('trip.label.tippyContent')}
        />
      </td>
      <td>
        <input
          key="40"
          type="text"
          id="label_tf"
          name="label"
          maxLength={Constants.TRIP_LABEL_MAX_LENGTH}
          onChange={handleChange}
          value={formData.label}
        />
        &nbsp;*
      </td>
    </tr>
    <tr>
      <td>
        <HeadingBuilder
          containerType  = 'container-input-label'
          headingText    = {t('trip.day.label')}
          // Date of Shopping Trip
          labelHtmlFor   = "day"
          tippyAlertText = {t('trip.day.tippyContent')}
          tippyText      = {t('trip.day.tippyContent')}
        />
      </td>
      <td>
        <input
          key="60"
          type="date"
          id="day"
          name="day"
          // autoComplete="username"
          // TODO: dynamically set min="yyyy-mm-dd" to today's date - or two weeks ago for acctg for past trips?
          onChange={handleChange}
          value={formData.day}
        />
        &nbsp;*
      </td>
    </tr>
    <tr>
      <td>
        <HeadingBuilder
          containerType  = 'container-input-label'
          headingText    = {t('trip.hour.label')}
          // Time of Trip
          labelHtmlFor   = "hour"
          tippyAlertText = {t('trip.hour.tippyContent')}
          tippyText      = {t('trip.hour.tippyContent')}
        />
      </td>
      <td>
        <input
          key="80"
          type="time"
          // presents a "time edit" box: 03:12 PM *
          id="hour"
          name="hour"
          onChange={handleChange}
          value={formData.hour}
        />
        &nbsp;*
      </td>
    </tr>
    <tr style={altBorderTopColor}>
      <td>
        {/* Sales Tax Rate for Circle: 7% */}
        {t('trip.tax_rate.label_setup')}
      </td>
      <td>
        {circle_tax_rate({circle_rate: circle.tax_rate})}
        &nbsp;%
      </td>
    </tr>
    <tr>
      <td>
        <HeadingBuilder
          containerType  = 'container-input-label'
          headingText    = {t('trip.tax_rate.label')}
          // Trip Override Rate
          labelHtmlFor   = "tax_rate_tf"
          tippyAlertText = {tripTaxOverrideText}
          tippyText      = {tripTaxOverrideText}
        />
      </td>
      <td>
        <NumericFormat displayType="input"
          key="90"
          type="text"
          id="tax_rate_tf"
          name="tax_rate"
          onChange={handleChange}
          value={formData.tax_rate}
          decimalScale={3}
        />
        &nbsp;%
      </td>
    </tr>
    <tr style={altBorderTopColor}>
      <td>
        {/* Food Sales Tax Rate for Circle: 2.5% */}
        {t('trip.tax_rate_food.label_setup')}
      </td>
      <td>
        {circle_tax_rate({circle_rate: circle.tax_rate_food})}
        &nbsp;%
      </td>
    </tr>
    <tr>
      <td>
        <HeadingBuilder
          containerType  = 'container-input-label'
          headingText    = {t('trip.tax_rate_food.label')}
          // Trip Override Rate
          labelHtmlFor   = "tax_rate_food_tf"
          tippyAlertText = {tripFoodTaxOverrideText}
          tippyText      = {tripFoodTaxOverrideText}
        />
      </td>
      <td>
        <NumericFormat displayType="input"
          key="100"
          type="text"
          id="tax_rate_food_tf"
          name="tax_rate_food"
          onChange={handleChange}
          value={formData.tax_rate_food}
          decimalScale={3}
        />
        &nbsp;%
      </td>
    </tr>
    <tr
      style={ if_shopper_is_circle_admin ? altBorderTopColor : visibilityCollapse }
    >
      <td>
        <HeadingBuilder
          containerType  = 'container-input-label'
          headingText    = {t('trip.is_hidden.label')}
          // Hidden
          labelHtmlFor   = "is_hidden_tf"
          tippyAlertText = {t('trip.is_hidden.tippyContent')}
          tippyText      = {t('trip.is_hidden.tippyContent')}
          // Do you want to reduce visibility of this Trip? e.g. not show it as one of the Circle's trips? etc.
        />
      </td>
      <td>
        <select
          key="150"
          type="text"
          id="is_hidden_tf"
          name="is_hidden"
          onChange={handleChange}
          value={formData.is_hidden}
        >
          {/* <option value="0">No</option> */}
          <option value="0">{t('trip.is_hidden.optionValue0')}</option>
          {/* <option value="1">Yes</option> */}
          <option value="1">{t('trip.is_hidden.optionValue1')}</option>
        </select>
      </td>
    </tr>
  </tbody>
</Table>
          <input key="170" type='submit' value='Submit' />
        </form>
        { errorsDoExist && <ErrorList /> }

        <BackButton/>
      </article>
  )
 } else {
    return (
      <HeadingBuilder
        Size = 'h3'
        headingText = {t('global.notSignedInMessage')}
        // Not Authorized - Please Sign in or Sign up
      />
    )
 }
}

export default TripForm;