import React from 'react';
//import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
//import { ShopperContext } from '../context/shopper';
import { useUtils } from "../hooks/useUtils";
import { formatMoney } from '../utils';

export const PriceLink = ( {sku, price} ) => {
  const { toMmmDdYyyy } = useUtils();

  return (
    <tr>
      <td>
        { toMmmDdYyyy({dateString: price.price_date}) }
      </td>

      <td>
        {price.currency}{formatMoney({amount: price.price})}
      </td>

      <td>
        {price.discount_amount > 0  ||  'None Entered'}

        {price.discount_amount > 0  &&  price.currency}
        {price.discount_amount > 0  &&  formatMoney({amount: price.discount_amount})}
      </td>

      <td>
        {price.discount_amount > 0  &&  price.currency}
        {price.discount_amount > 0  &&  formatMoney({amount: price.price - price.discount_amount})}
      </td>

      <td>
        { toMmmDdYyyy({dateString: price.discount_start_date}) }
      </td>

      <td>
        { toMmmDdYyyy({dateString: price.discount_end_date}) }
      </td>

      <td>
        <Link to={`/skus/${sku.id}/prices/${price.id}`}>
          Edit
        </Link>
      </td>
    
    </tr>
  )
}

export default PriceLink