import React, { useState, useContext, forwardRef } from 'react';
import { ShopperContext } from '../context/shopper'

import SkuFoundRow from '../components/SkuFoundRow'

//import CategoryHeader from '../components/CategoryHeader'

import { useLookups, usePhrasing } from "../hooks/useSkuUtils";
import { useStyles } from "../hooks/useStyles";
import * as Constants from '../constants';
import { doesArrayExist } from '../utils';
import { HeadingBuilder } from './HeadingBuilder';

import Table from 'react-bootstrap/Table';
import { useTranslation } from "react-i18next";

// Show skus that match search criteria for Shopper to clone for their own Circle.
// Shopper can check a box to open a SkuForm prepopulated with values from the picked Sku.
export const SkusFound = forwardRef(({otherSkus, circle_id, showSearchResults}, searchRef) => {
  console.log("skfd A", circle_id, otherSkus)
  const {
    //shopper, circleShoppers, prices, skus, 
    signedIn
        } = useContext(ShopperContext);
  const { t } = useTranslation();
  const { lookup_category } = useLookups();
  const { getOColTippyContent } = usePhrasing();

  const { textAlignLeft } = useStyles();

  const shoppingListTableId = 'ShoppingListMainTable';

  const [showIdentifier, setShowIdentifier]             = useState(false);
  const [showPostalCode, setShowPostalCode]             = useState(false);
  const [showUnitCost, setShowUnitCost]                 = useState(true);
  const toggleShowUnitCostColumn = () => {
    setShowUnitCost(() => !showUnitCost);
  };

  const [showSkuDetailsColumn, setShowSkuDetailsColumn] = useState(false);
  const toggleShowSkuDetailsColumn = () => {
    setShowSkuDetailsColumn(() => !showSkuDetailsColumn);
    console.log("srsc", showSkuDetailsColumn);
};

  // Show a row of Sku attributes for each Sku

  function s_by_sku_cat_brand_label_identifier( a, b )
  {
    //console.log("sbsli", a)
    if ( a.category && b.category){
    if ( a.category < b.category){
      return -1;
    }
    if ( a.category > b.category){
      return 1;
    }
    }

    if ( a.brand && b.brand ){
    if ( a.brand.toLowerCase() < b.brand.toLowerCase() ){
      return -1;
    }
    if ( a.brand.toLowerCase() > b.brand.toLowerCase() ){
      return 1;
    }
    }

    if ( a.label && b.label ){
    if ( a.label.toLowerCase() < b.label.toLowerCase() ){
      return -1;
    }
    if ( a.label.toLowerCase() > b.label.toLowerCase() ){
      return 1;
    }
    }

    if ( a.identifier && b.identifier){
    if ( a.identifier < b.identifier){
      return -1;
    }
    if ( a.identifier > b.identifier){
      return 1;
    }
    }

    return 0;
  }

  if (signedIn) {
    const skusFoundRows =
      doesArrayExist(otherSkus) && otherSkus.length > 0
        ?
          otherSkus.sort( s_by_sku_cat_brand_label_identifier )
            .map(os => {
              console.log('sfr os', os);

              const oi = <SkuFoundRow
                        showSkuDetailsColumn={showSkuDetailsColumn}
                        circle_id={circle_id}
                        otherSku={os}
                        showIdentifier={showIdentifier}
                        showPostalCode={showPostalCode}
                        showUnitCost={showUnitCost}
                        key={os.uuuid}
                    />

              return [oi];
          })
        : null;
    console.log('SkusFound.js skusFoundRows: ', skusFoundRows);

    const o_colTippyText =
      getOColTippyContent ({ isForTextNotAudio: true, isForSkusFound: true });

    const o_colAudioTippyText =
      getOColTippyContent ({ isForTextNotAudio: false, isForSkusFound: true });

    const copySkuHeadline =
        t('sku.formPart.skusFoundHeadlineLabel010')
      + Constants.O_COL_SYM
      + t('sku.formPart.skusFoundHeadlineLabel020')

    const zip_colTippyText =
        t('sku.searchOtherCirclesForSku.tippyContentZipColHead010')
      + t('global.label.zip')
      + t('sku.searchOtherCirclesForSku.tippyContentZipColHead020')

    const handleIdClick = event => {
      setShowIdentifier(current => !current);
    };

    const handlePostalCodeClick = event => {
      setShowPostalCode(current => !current);
    };

    // not returning <article> since caller is applying that tag,
    // i.e. this stuff will slide into caller's <article>
    return (
      <>
        <hr/>
        { skusFoundRows
          ? <h3 id="YourSkuSearchResults">
              {copySkuHeadline}
              {/* Search Results: Click '〇' to copy a Sku */}
            </h3>
          : null
        }
        <h5>
          {t('sku.formPart.skusFoundSubheadLabel')}
          {/* (Use the filters above to search for Skus to add to the Circle's Maybe-List) */}
        </h5>
        { skusFoundRows
          ? <HeadingBuilder
              Size           = 'small'
              headingText    = 
                {showUnitCost
                  ? t('sku.toggleButtonToShowUnitCost.labelToStopShowing')
                  // "Stop showing 'Unit Cost' column"
                  : t('sku.toggleButtonToShowUnitCost.labelToStartShowing')
                  // "Start showing 'Unit Cost' column"
                }
              buttonAction   = {toggleShowUnitCostColumn}
              tippyAlertText = {t('sku.toggleButtonToShowUnitCost.tippyContent')}
              tippyText      = {t('sku.toggleButtonToShowUnitCost.tippyContent')}
            />
          : null
        }

        { skusFoundRows
        ?
        <HeadingBuilder
          Size           = 'small'
          headingText    = 
            {showSkuDetailsColumn
              ? t('sku.toggleButtonToShowDetails.labelToStopShowing')
              // "Stop showing 'Details' column"
              : t('sku.toggleButtonToShowDetails.labelToStartShowing')
              // "Start showing 'Details' column"
            }
          buttonAction   = {toggleShowSkuDetailsColumn}

          tippyAlertText = {t('sku.toggleButtonToShowDetails.tippyContent')}
          tippyText      = {t('sku.toggleButtonToShowDetails.tippyContent')}
        />
        : null
        }

        { skusFoundRows
          ? <>
              <br/>
              <Table id={shoppingListTableId}
                     style={textAlignLeft}
                     striped bordered hover
                     size="sm" >
              <thead>
                <tr>
                  <th>
                    <HeadingBuilder
                      containerType  = "container-hug-bottom-left"
                      headingText    = {Constants.O_COL_SYM}
                      //  O
                      tippyAlertText = {o_colTippyText}
                      tippyAlertAudio= {o_colAudioTippyText}
                      tippyText      = {o_colTippyText}
                      // Click a circle under the 'O' sign to choose that row's search results as the starting material for adding a new Sku to your Circle.
                    />
                  </th>
                  <th>
                    <HeadingBuilder
                      containerType  = "container-hug-bottom"
                      headingText    = {t('ordersList.id_col.label')}
                      // ID
                      buttonAction   = {handleIdClick}
                      tippyAlertText = {t('ordersList.id_col.tippyContent')}
                      tippyText      = {t('ordersList.id_col.tippyContent')}
                    />
                  </th>
                  <th>
                    <HeadingBuilder
                      containerType  = "container-hug-bottom-left"
                      headingText    = {t('global.label.zip')}
                      // Zip
                      buttonAction   = {handlePostalCodeClick}
                      tippyAlertText = {zip_colTippyText}
                      tippyText      = {zip_colTippyText}
                    />
                  </th>
                  <th>
                    <HeadingBuilder
                      containerType  = "container-hug-bottom-left"
                      headingText    = {t('sku.brand.label')}
                      // Brand
                      tippyAlertText = {t('sku.brand.tippyContent')}
                      tippyText      = {t('sku.brand.tippyContent')}
                    />
                  </th>
                  <th>
                    <HeadingBuilder
                      containerType  = "container-hug-bottom-left"
                      headingText    = {t('sku.label.label')}
                      // Label
                      tippyAlertText = {t('sku.label.tippyContentSearch')}
                      tippyText      = {t('sku.label.tippyContentSearch')}
                    />
                  </th>
                  <th>
                    <HeadingBuilder
                      containerType  = "container-hug-bottom-left"
                      headingText    = {t('ordersList.store_col.label')}
                      // Store
                    />
                  </th>
                  <th>
                    <HeadingBuilder
                      containerType  = "container-hug-bottom-left"
                      headingText    = {t('sku.qty_units.label')}
                      // Qty
                      tippyAlertText = {t('sku.qty_units.tippyContent')}
                      tippyText      = {t('sku.qty_units.tippyContent')}
                    />
                  </th>
                  <th>
                    <HeadingBuilder
                      containerType  = "container-hug-bottom-left"
                      headingText    = {t('ordersList.price_col.label')}
                      // Price
                      tippyAlertText = {t('ordersList.price_col.tippyContentSearch')}
                      tippyText      = {t('ordersList.price_col.tippyContentSearch')}
                    />
                  </th>
                  { showUnitCost
                    ? <th>
                        <HeadingBuilder
                          containerType  = "container-hug-bottom-left"
                          headingText    = {t('ordersList.unit_cost_col.label')}
                          // Unit Count
                          tippyAlertText = {t('ordersList.unit_cost_col.tippyContentSearch')}
                          tippyText      = {t('ordersList.unit_cost_col.tippyContentSearch')}
                        />
                      </th>
                    : null
                  }
                  <th>
                    { showSkuDetailsColumn
                      ? <HeadingBuilder
                          containerType  = "container-hug-bottom-left"
                          headingText    = {t('sku.detail.label')}
                          // Details
                          tippyAlertText = {t('sku.detail.tippyContentSearch')}
                          tippyText      = {t('sku.detail.tippyContentSearch')}
                        />
                      : null
                    }
                  </th>
                </tr>
              </thead>
              <div ref={searchRef} tabIndex="0"/>
              <tbody>
                {skusFoundRows.flat(1)}
              </tbody>
              </Table>
            </>
          : showSearchResults
            ? <p>
                {t('sku.formPart.noSkusFound')}
                {/* No Skus found for this Search */}
              </p>
            : null
        }
      </>
    )
  } else {
    return (
      <HeadingBuilder
        Size = 'h3'
        headingText = {t('global.notSignedInMessage')}
        // Not Authorized - Please Sign in or Sign up
      />
    )
  }
})

export default SkusFound;
