import React, { useState, useContext } from 'react';
import { ShopperContext } from '../context/shopper'
import CircleForm from '../components/CircleForm'
import CircleList from '../components/CircleList'
import HeadingBuilder from '../components/HeadingBuilder';
import { useTranslation } from "react-i18next";

const Circles = () => {
  const { circles, shopper, signedIn,
        } = useContext(ShopperContext);
  const [formFlag, setFormFlag] = useState(false);
  const lightUpAddCircleButton = () => {
      setFormFlag(false);
  }
  const { t } = useTranslation();

  if (signedIn) {
      console.log('Circcircles', circles);

      return (
          <article>
              <HeadingBuilder
                Size = "h2"
                headingText = {t('circle.formPart.headlineLabel')}
                // Circles for: shopper.identifier VID
                value = {shopper.identifier}
                videoUrl = "video/circles-screen.mp4"
              />
              { 
               circles.length == 0
                ? <HeadingBuilder
                    Size = "p"
                    headingText = {t('circle.formPart.noCircles')}
                    // You are not yet a member of any Shopping Circle
                  />
                : <CircleList circles = {circles} />
              }
              <br/>

              {formFlag
                ? <CircleForm lightUpAddCircleButton={lightUpAddCircleButton} />
                : <>
                    <HeadingBuilder
                      buttonAction = {() => setFormFlag(true)}
                      headingText = {t('circle.addCircle.buttonLabel')}
                      // Add Circle

                      tippyAlertText = {t('circle.addCircle.tippyContent1')}
                      tippyText = {t('circle.addCircle.tippyContent1')}
                      tippyInfoText = {t('circle.addCircle.tippyContent2')}
                    />
                  </>
              }
          </article>
      )
  } else {
    return (
      <HeadingBuilder
        Size = 'h3'
        headingText = {t('global.notSignedInMessage')}
        // Not Authorized - Please Sign in or Sign up
      />
    )
  }
}

export default Circles;
